import { Box, createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode } from 'react';

declare module '@mui/material/styles' {
  interface Palette {
    icon: Palette['primary'];
  }

  interface PaletteOptions {
    icon?: PaletteOptions['primary'];
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Inter',
      'Fira Sans',
      'Poppins',
      'Lato',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    button: {
      textTransform: 'none',
    },
  },

  palette: {
    primary: {
      main: '#5F2EEA',
    },
    secondary: {
      main: '#636363',
    },
    background: {
      default: '#FFFFFF',
    },
    error: {
      main: '#ff9800',
    },
    icon: {
      main: '#5E6E99',
    },
  },
});

interface Props {
  readonly children?: ReactNode;
}

export function AppWrapper({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export function Header(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 700,
        fontSize: 28,
        // display: 'flex',
        // alignSelf: 'flex-end',
        // marginTop: 2,
        color: '#484848',
        [theme.breakpoints.down('sm')]: {
          fontSize: 25,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function SubHeader(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 600,
        fontSize: 22,
        color: '#484848',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      })}
    >
      {props.children}
    </Box>
  );
}
