import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Box } from '@mui/material';
import React, { useEffect } from 'react';

import UnauthorizedApolloProvider from '../../UnAuthorizedApolloProvider';
import { CenteredModal, MediumModalPaper } from '../styling/modal';

import { useAuth } from './firebase';
import SignUp from './SignUp'; // Assuming SignUp is your sign-up form component

interface SignUpModalProps {
  open: boolean;
  onClose: () => void; // Callback to close the modal
}

const SignUpModal: React.FC<SignUpModalProps> = ({ open, onClose }) => {
  const { user } = useAuth();

  // Close the modal if the user is authenticated
  useEffect(() => {
    if (user) {
      onClose();
    }
  }, [user, onClose]);

  return (
    <UnauthorizedApolloProvider>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <MediumModalPaper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Box></Box>
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Modal Content */}
          <Box padding={2}>
            <SignUp />
          </Box>
        </MediumModalPaper>
      </CenteredModal>
    </UnauthorizedApolloProvider>
  );
};

export default SignUpModal;
