import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import MapIcon from '@mui/icons-material/Map';
import { Box, Link } from '@mui/material';
import { ReactNode } from 'react';

import { Maybe, Recommendation } from '../../../generated/public_graphql';

interface RecommendationProps {
  readonly recommentations: Maybe<Recommendation>[];
}

export function CreateTripStepRecommendations(props: RecommendationProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {props.recommentations &&
        props.recommentations.length > 0 &&
        props.recommentations.map((recommendation, i) => {
          return (
            <div key={i}>
              {recommendation && (
                <Box
                  sx={theme => ({
                    background:
                      'linear-gradient(180deg, #FAFAFA 0%, #FFF3DB 90%)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                    marginBottom: 2,
                  })}
                >
                  <RecommendationTitle>
                    {recommendation.name}
                  </RecommendationTitle>
                  {recommendation.latitude && recommendation.longitude && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <DirectionsIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkNavigation
                        lat={recommendation.latitude}
                        lng={recommendation.longitude}
                        copy={'Take me there'}
                      />
                    </Box>
                  )}
                  {recommendation.locationUrl && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <MapIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkMapsUrl
                        url={recommendation.locationUrl}
                        copy={'Open in Google Maps'}
                      />
                    </Box>
                  )}
                  {recommendation && recommendation.url && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <EventTwoToneIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkBooking
                        url={recommendation.url}
                        copy={'Get more info'}
                      />
                    </Box>
                  )}
                  <RecommendationDescription>
                    {recommendation.description}
                  </RecommendationDescription>
                </Box>
              )}
            </div>
          );
        })}
    </Box>
  );
}

function RecommendationTitle(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        fontWeight: 500,
        fontSize: 18,
        color: '#14142B',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0.5,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

function RecommendationDescription(props: { children: ReactNode }) {
  return (
    <Box
      sx={theme => ({
        marginBottom: 1,
        fontWeight: 400,
        fontSize: 18,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 0.5,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function ExternalLinkMapsUrl(props: { url: string; copy: string }) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={{
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: '#6E7191',

        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkBooking(props: { url: string; copy: string }) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: theme.palette.primary.light,
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkNavigation(props: {
  lat: number;
  lng: number;
  copy: string;
}) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={'http://www.google.com/maps/place/' + props.lat + ',' + props.lng}
      sx={{
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: '#6E7191',

        '&:hover': {
          textDecoration: 'none',
        },
      }}
    >
      {props.copy}
    </Link>
  );
}
