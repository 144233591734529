import {
  Box,
  Typography,
  Grid,
  ContainerProps,
  BoxProps,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  GetTheAppButton,
  ProminentButton,
} from '../../theme-components/Buttons';
import Journey from '../illustrations/Journey';

interface GradientContainerProps extends ContainerProps {
  gradient?: string;
}

interface ColorWordProps extends BoxProps {
  marginRight?: string;
  marginLeft?: string;
}

export const OuterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
    width: '100vw',
  },
  // [theme.breakpoints.down('sm')]: {
  //   width: '100',
  // },

  // backgroundColor: '#FAF7F6', // Light background color for the main area
  // padding: theme.spacing(2), // Less space around the container
}));

export const Description = styled(Box)(({ theme }) => ({
  color: '#000000',
  fontWeight: 600,
  fontSize: 35,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

export const BoxPadding = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(3),
  },
}));

export const BoxActivity = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
  // [theme.breakpoints.down('sm')]: {
  //   flexWrap: 'wrap',
  //   justifyContent: 'center',
  // },
}));

export const GradientContainer = styled(Box)<GradientContainerProps>(
  ({ theme, gradient }) => ({
    width: '100%',
    background: gradient || 'linear-gradient(180deg, #FFF3DB 0%, #FFFFFF 100%)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 2,
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderRadius: 0,
    },
  }),
);

export const ColorWord = styled(Box, {
  shouldForwardProp: prop => prop !== 'marginRight' && prop !== 'marginLeft',
})<ColorWordProps>(({ theme, marginRight = '0', marginLeft = '0' }) => ({
  color: '#624AF2',
  fontWeight: 600,
  fontSize: 35,
  marginRight,
  marginLeft,
  [theme.breakpoints.down('md')]: {
    fontSize: 25,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const HeroTextContainer = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(3),
  [theme.breakpoints.down('lg')]: {
    marginTop: theme.spacing(-5),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    textAlign: 'center',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#333',
  fontWeight: 700,
  fontSize: 50,
  [theme.breakpoints.down('lg')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
  },
}));

const HighlightedText = styled('span')(({ theme }) => ({
  color: '#FF6484',
  textDecoration: 'underline',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  color: '#555',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(-3),
  marginBottom: theme.spacing(-3),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(-3),
  },
}));

export function HomeHeader() {
  const navigate = useNavigate();
  return (
    <GradientContainer>
      <Grid container alignItems="center">
        {/* <Grid item spacing={{ md: 0, lg: 4 }} xs={12} md={6}> */}
        <Grid item xs={12} md={6}>
          <HeroTextContainer>
            <Title>
              <HighlightedText
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/explore');
                }}
              >
                Find
              </HighlightedText>
              or{' '}
              <HighlightedText
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/my-trips');
                }}
              >
                create
              </HighlightedText>{' '}
              your perfect itinerary.
            </Title>
            <Subtitle>
              Rooutie is an online guide and platform to help you create that
              perfect itinerary for your next holiday.
            </Subtitle>
            <Box
              sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column', // Switch to column for screens smaller than 'md'
                },
              })}
            >
              <ProminentButton
                sx={theme => ({
                  marginTop: 5,
                  marginRight: 2,
                  [theme.breakpoints.down('md')]: {
                    marginTop: 2,
                  },
                  [theme.breakpoints.down('sm')]: {
                    // display: 'none',
                  },
                })}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/my-trips');
                }}
              >
                Create new trip
              </ProminentButton>
              <GetTheAppButton
                sx={theme => ({
                  marginTop: 5,
                  marginRight: 2,
                  [theme.breakpoints.down('md')]: {
                    marginTop: 2,
                  },
                })}
                onClick={() => {
                  gtag('event', 'app-bar-create-trip');
                  navigate('/download');
                }}
              >
                Get The App
              </GetTheAppButton>
            </Box>
          </HeroTextContainer>
        </Grid>
        {/* <Grid spacing={{ md: 0, lg: 0 }} item xs={12} md={6}> */}
        <Grid item xs={12} md={6}>
          <IllustrationContainer>
            <Journey
              style={{ width: '100%', maxWidth: '500px', height: 'auto' }}
            />
          </IllustrationContainer>
        </Grid>
        <Box
          sx={theme => ({
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            // justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
              // justifyContent: 'center',
              // marginTop: 2,
              // marginRight: 2,
              // display: 'none',
            },
          })}
        >
          {/* <ProminentButton
            sx={theme => ({
              marginTop: 5,
              marginRight: 2,
              [theme.breakpoints.down('md')]: {
                marginTop: 0,
              },
              [theme.breakpoints.up('sm')]: {
                display: 'none',
              },
            })}
            onClick={() => {
              gtag('event', 'app-bar-create-trip');
              navigate('/my-trips');
            }}
          >
            Create new trip
          </ProminentButton> */}
        </Box>
      </Grid>
    </GradientContainer>
  );
}
