import { useQuery } from '@apollo/client';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { QUERY_COUNTRY_TRIPS } from '../gql-public/countryTripsQuery';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

export function CountryTrips(props: { countryId: string }) {
  const { loading, error, data } = useQuery(QUERY_COUNTRY_TRIPS, {
    variables: {
      country_id: props.countryId,
    },
  });

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <>
      {loading ? (
        <LoadingCards />
      ) : (
        <>
          {error || !data || !data.countryTrips ? (
            <div>Couldn't load trips, please refresh or come back later.</div>
          ) : (
            <Trips trips={data.countryTrips} />
          )}
        </>
      )}
    </>
  );
}

function LoadingCards() {
  const loadingCards = [1, 2];

  return (
    <Grid container>
      {loadingCards.map(index => (
        <React.Fragment key={index}>
          <Card
            key={index}
            sx={theme => ({
              backgroundColor: 'transparent',
              border: 'none',
              boxShadow: 'none',
              position: 'relative',
              marginTop: 3,
              marginRight: 1,
              [theme.breakpoints.down('xl')]: {
                width: '32%',
              },
              [theme.breakpoints.down('lg')]: {
                width: '32%',
              },
              [theme.breakpoints.down('md')]: {
                width: '48%',
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                marginRight: 0,
                marginLeft: 0,
              },
            })}
          >
            <Skeleton
              variant="rectangular"
              sx={{
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
                height: 200,
                width: '100%',
              }}
            />
            <CardContent
              sx={{
                padding: 0,
                paddingTop: 2,
                paddingBottom: 0,
                '&:last-child': {
                  paddingBottom: '0px', // Overwrite the padding-bottom for last-child
                },
              }}
            >
              <Skeleton />
              <Skeleton />
            </CardContent>
          </Card>
        </React.Fragment>
      ))}
    </Grid>
  );
}

const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 20,
  paddingBottom: 2,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

const TripLength = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  //   marginTop: 5,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  // marginRight: 5,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export function Trips(props: PropsTripsList) {
  const navigate = useNavigate();

  return (
    <Grid container>
      {props.trips.map((trip, index) => (
        <React.Fragment key={index}>
          {trip && (
            <Card
              key={index + trip.id}
              sx={theme => ({
                backgroundColor: 'transparent',
                border: 'none',
                boxShadow: 'none',
                position: 'relative',
                marginTop: 3,
                marginRight: 1,
                width: '24%',
                [theme.breakpoints.down('xl')]: {
                  width: '24%',
                },
                [theme.breakpoints.down('lg')]: {
                  width: '32%',
                },
                [theme.breakpoints.down('md')]: {
                  width: '48%',
                },
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  marginRight: 0, // Ensures no right margin on small screens
                  marginLeft: 0, // Ensures no left margin on small screens
                },
                [theme.breakpoints.down('xs')]: {
                  width: '100%',
                  marginRight: 0, // Ensures no right margin on small screens
                  marginLeft: 0, // Ensures no left margin on small screens
                },
              })}
            >
              <Box
                onClick={() => {
                  navigate('/trip/' + trip.id);
                  gtag('event', 'user-trip-edit-card');
                }}
              >
                {trip.images && trip.images.length > 0 ? (
                  <CardMedia
                    component="img"
                    height="200"
                    sx={{ borderRadius: '8px' }}
                    image={trip.images[0]!.path!}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    height="200"
                    sx={{ borderRadius: '8px' }}
                    image={'/assets/image/placeholder-svg.svg'}
                  />
                )}
                <CardContent
                  sx={{
                    padding: 0,
                    paddingTop: 2,
                    paddingBottom: 0,
                    '&:last-child': {
                      paddingBottom: '0px', // Overwrite the padding-bottom for last-child
                    },
                  }}
                >
                  <TripTitle>{trip.name}</TripTitle>

                  {/* <Box justifyContent="flex-end"> */}
                  {trip &&
                    trip.itineraryCountries &&
                    trip.itineraryCountries.length > 0 && (
                      <>
                        {/* <Divider /> */}

                        <Stack
                          direction="row"
                          alignItems="center" // Center vertically
                          //   justifyContent="center" // Center horizontally
                          //   height="100vh" // Full height for centering
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap', // This allows the countries to wrap onto the next line
                          }}
                        >
                          {trip.itineraryCountries.map((country, index) => (
                            <React.Fragment key={index}>
                              {country && (
                                <>
                                  {trip.length && index === 0 && (
                                    <TripLength>
                                      {trip.length + ' days  '}
                                    </TripLength>
                                  )}
                                  <Box
                                    sx={{
                                      width: 5, // Width of the dot
                                      height: 5, // Height of the dot
                                      backgroundColor: 'black', // Color of the dot
                                      borderRadius: '50%', // Make it circular
                                      mx: 1, // Horizontal margin for spacing
                                    }}
                                  />

                                  <TripCountry>
                                    {country.countryName}
                                  </TripCountry>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </Stack>
                      </>
                    )}
                  {/* </Box> */}
                </CardContent>
              </Box>
            </Card>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}
