import { gql } from 'graphql-tag';

export const QUERY_GET_TRIP = gql`
  query trip($trip_id: String!) {
    trip(trip_id: $trip_id) {
      id
      name
      tripType
      description
      length
      archived
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      userDetails {
        id
        nickName
      }
      itineraryCountries {
        countryName
        itineraryId
      }
      images {
        id
        path
        header
        main
        secondary
        tertiary
      }
      itinerary {
        id
        days {
          id
          dayNumber
          cities {
            cityName
          }
          steps {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            url
            locationUrl
            city
            country
            countryCode
            region
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              openingHours {
                weekDay
                hours
              }
              images {
                path
                title
                contributorId
                imageUrl
              }
            }
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
        coordinates {
          id
          title
          latitude
          longitude
          step {
            id
            title
            type
            description
            stepNumber
            latitude
            longitude
            locationUrl
            duration
            url
            city
            country
            countryCode
            region
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
            placeImages {
              path
              id
              title
              contributorId
              imageUrl
            }
            place {
              id
              lat
              lng
              city
              country
              countryCode
              region
              externalPlaceId
              title
              category
              noOfReviews
              rating
              description
              quickFact
              phoneNumber
              website
              address
              openingHours {
                weekDay
                hours
              }
              images {
                title
                path
                contributorId
                imageUrl
              }
            }
            accommodations {
              id
              name
              description
              url
              budget
              latitude
              longitude
              locationUrl
              images {
                id
                path
                header
                main
                secondary
                tertiary
              }
            }
            recommendations {
              id
              name
              type
              description
              url
              latitude
              longitude
              locationUrl
            }
          }
        }
      }
    }
  }
`;
