import { gql } from 'graphql-tag';

export const MUTATION_CREATE_USER_TRIP_WITH_DAY = gql`
  mutation createUserTripWithDay(
    $name: String!
    $place_url: String
    $step_id: String
    $trip_type: String!
  ) {
    createUserTripWithDay(
      name: $name
      place_url: $place_url
      step_id: $step_id
      trip_type: $trip_type
    ) {
      id
      description
      tripType
      name
      length
      archived
    }
  }
`;
