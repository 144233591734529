import { useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { PrimaryButton } from '../../../theme-components/Buttons';
import { FullScreenBusAnimation } from '../../animations/BusAnimation';
import { MUTATION_ADD_PLACE } from '../../gql-user/addPlacesMutation';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../styling/modal';

import { ShareInstructionModal } from './add-step/AddStep';
import { StyledFormControl } from './Styles';

export const LinkPlaceToStepModal: React.FC<{
  stepId: string;
  handleCloseModal: () => void;
}> = ({ stepId, handleCloseModal }) => {
  //   const dispatch = useDispatch();
  const [linkPlaceModal, setLinkPlaceModalOpen] = useState(false);

  const handleOpenModal = () => {
    gtag('event', 'link-place-to-step-button');
    setLinkPlaceModalOpen(true);
  };

  const closeModal = () => {
    gtag('event', 'link-place-to-step-button');
    setLinkPlaceModalOpen(false);
    handleCloseModal();
  };

  return (
    <>
      <Box onClick={handleOpenModal} sx={{ width: '100%' }}>
        Link place from Maps
      </Box>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={linkPlaceModal}
        onClose={closeModal}
        closeAfterTransition
      >
        <MediumModalPaper>
          <ModalTitle>Link place</ModalTitle>
          <ModalDescription>
            Easily copy google maps place sharable URL and paste bellow to link
            place.
          </ModalDescription>
          <LinkPlace stepId={stepId} handleCloseModal={closeModal} />
        </MediumModalPaper>
      </CenteredModal>
    </>
  );
};

const LinkPlace: React.FC<{
  stepId: string;
  handleCloseModal: () => void;
}> = ({ stepId, handleCloseModal }) => {
  const [placeUrl, setPlaceUrl] = useState('');
  const dispatch = useDispatch();
  const [isValidUrl, setIsValidUrl] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  // Open the keyboard automatically
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField to open the keyboard
    }
  }, []);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [linkPlace, { loading }] = useMutation(MUTATION_ADD_PLACE, {
    variables: {
      url: placeUrl,
      step_id: stepId,
    },
  });

  return (
    <Stack direction="column">
      <StyledFormControl>
        <TextField
          inputRef={textFieldRef}
          label="Google maps place shared URL"
          fullWidth={true}
          variant="outlined"
          onChange={handleChange}
          helperText={
            !isValidUrl &&
            'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleOpen}
                  aria-label="show share instructions"
                >
                  <InfoIcon />
                </IconButton>
                <ShareInstructionModal open={open} handleClose={handleClose} />
              </InputAdornment>
            ),
          }}
        />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrimaryButton
            variant="contained"
            color="primary"
            disabled={placeUrl === '' || loading}
            onClick={async () => {
              gtag('event', 'link-place-to-step');
              try {
                const res = await linkPlace();
                if (
                  res &&
                  res.data &&
                  res.data.createUserPlace &&
                  res.data.createUserPlace.days
                ) {
                  gtag('event', 'place-linked');
                  dispatch(setDays(res.data.createUserPlace.days));
                  handleCloseModal();
                }
              } catch (e) {
                gtag('event', 'error-link-place');
                dispatch(setErrorTitle('Error linking place'));
                dispatch(setErrorModalOpen(true));
              }
            }}
          >
            Link place
          </PrimaryButton>
        </Box>
      </StyledFormControl>
      {loading && (
        <FullScreenBusAnimation copy="This can take around 10-20 seconds. Just continue researching another place and when you will be back this will be done." />
      )}
    </Stack>
  );
};
