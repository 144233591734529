import { useQuery } from '@apollo/client';
import { Instagram, YouTube } from '@mui/icons-material';
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Link,
  IconButton,
  SvgIcon,
  SvgIconProps,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { Trips } from '../components/country/country-trips';
import { QUERY_USER_PUBLIC_TRIPS } from '../components/gql-public/userPublicTrips';
import {
  GradientContainer,
  OuterContainer,
} from '../components/home/HomeHeader';
import { RoutePage } from '../components/route';
import { UserPublicInfo } from '../generated/public_graphql';
import { isRunningStandalone } from '../utils/helpers';

export default function UserPublicTrips() {
  gtag('event', 'public-country-page');
  const { id } = useParams<{ id: string }>();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  console.log('id', id);

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { data, error, loading } = useQuery(QUERY_USER_PUBLIC_TRIPS, {
    variables: { user_id: id || '' }, // Use id if available
    skip: !id, // Skip query if id is not available
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (error) {
    return <>Error loading trips</>;
  }

  return (
    <RoutePage>
      <OuterContainer>
        <GradientContainer>
          {data &&
            data.userPublicTrips &&
            data.userPublicTrips &&
            data.userPublicTrips.publicUserInfo && (
              <UserProfile
                publicUserInfo={data.userPublicTrips.publicUserInfo}
              />
            )}
          {data && data.userPublicTrips && data.userPublicTrips.trips && (
            <Trips trips={data.userPublicTrips.trips} />
          )}
          {isStandalone && (
            <Box
              sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
            />
          )}
        </GradientContainer>
      </OuterContainer>
    </RoutePage>
  );
}

interface UserProfileProps {
  publicUserInfo: UserPublicInfo;
}

const UserProfile: React.FC<UserProfileProps> = ({ publicUserInfo }) => {
  const { nickName, tikTokUrl, instagramUrl, youTubeUrl, profilePictureUrl } =
    publicUserInfo;

  return (
    <Box
      sx={{
        width: 'fit-content', // Adjust width to content
        margin: '0 auto', // Center horizontally
        p: 2, // Padding around the box
        display: 'flex', // Use flexbox for layout
        alignItems: 'center', // Align items vertically centered
      }}
    >
      {/* Profile picture on the left */}
      <Avatar
        src={profilePictureUrl ?? undefined} // Use profilePictureUrl if available
        alt={nickName ?? undefined} // Alt text for avatar
        sx={{ width: 120, height: 120, marginRight: 2 }} // Add margin to the right of the avatar
      />

      {/* Nickname and social media on the right */}
      <Box>
        {/* Nickname */}
        {nickName && (
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            sx={{ marginLeft: 1 }}
          >
            {nickName}
          </Typography>
        )}

        {/* Social media icons in a row */}
        <Box display="flex" gap={1}>
          {tikTokUrl ? (
            <Link href={'tikTokUrl'} target="_blank" rel="noopener">
              <IconButton>
                <TikTokIcon fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip title="TikTok account not linked yet">
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <TikTokIcon fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {instagramUrl ? (
            <Link href={instagramUrl} target="_blank" rel="noopener">
              <IconButton>
                <Instagram fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip title="Instagram account not linked yet">
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <Instagram fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}

          {youTubeUrl ? (
            <Link href={youTubeUrl} target="_blank" rel="noopener">
              <IconButton>
                <YouTube fontSize="large" />
              </IconButton>
            </Link>
          ) : (
            <Tooltip title="YouTube account not linked yet">
              <span>
                {/* Wrapping in a span is necessary to handle disabled prop on IconButton */}
                <IconButton disabled={true} sx={{ color: '#d3d3d3' }}>
                  <YouTube fontSize="large" />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const TikTokIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" />
    </SvgIcon>
  );
};
