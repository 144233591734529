import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Map, Marker } from 'react-map-gl'; // Import Popup
import { useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import LanguageIcon from '@mui/icons-material/Language';
import DirectionsIcon from '@mui/icons-material/Directions';
import MapIcon from '@mui/icons-material/Map';

import { ItineraryCoordinates, Maybe } from '../../generated/user_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { Place, Step } from '../../generated/public_graphql';
import GoogleIcon from '../icons/GoogleIcon';

import {
  CardContent,
  Typography,
  Box,
  Rating,
  Grid,
  Button,
  IconButton,
  Stack,
  Link,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import CloseIcon from '@mui/icons-material/Close';
import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { AddToTripButton } from './AddToTrip';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import { formatNumber, getMapUrls } from '../../utils/helpers';
import { TodaysHours } from './Hours';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import { ImageCard } from '../create-trip/edit-trip/StepImages';
import { ResponsiveChip } from './PublicPlaceCard';

type TripType = 'public' | 'user' | 'preview';

export type TripMapProps = {
  tripType: TripType;
  onMarkerClick?: (stepId: string) => void;
  onCloseClick?: () => void;
  openOnMobile?: boolean;
};

export function TripMap({
  tripType,
  onMarkerClick,
  onCloseClick,
  openOnMobile,
}: TripMapProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);
  const trip = tripType === 'user' ? userTrip : publicTrip;

  const boundingBox = useMemo(() => {
    if (
      trip &&
      trip.itinerary &&
      trip.itinerary.coordinates &&
      trip.itinerary.coordinates.length > 0
    ) {
      return calculateBoundingBox(trip.itinerary.coordinates);
    }
    return [
      [0, 0],
      [0, 0],
    ];
  }, [trip]); // Depend on trip so it recalculates if trip changes

  const [viewport, setViewport] = useState({
    latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
    longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
    zoom: 10,
    width: '100%',
    height: '100%',
  });

  const fitBounds = useCallback(
    map => {
      map.fitBounds(boundingBox, {
        padding: { top: 40, bottom: 10, left: 10, right: 10 },
        duration: 2000,
      });
    },
    [boundingBox],
  );

  const [selectedPlace, setSelectedPlace] = useState<{ step: Step } | null>(
    null,
  );

  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(
    null,
  );

  const [mapWidth, setMapWidth] = useState(0);
  const [mapPosition, setMapPosition] = useState(0);
  const [mapVisiblePercentage, setMapVisiblePercentage] = useState(0);
  const [distanceFromBottom, setDistanceFromBottom] = useState(0);

  const mapRef = useRef<HTMLDivElement>(null);

  const calculateMapPosition = () => {
    if (mapRef.current) {
      const rect = mapRef.current.getBoundingClientRect();

      const leftPosition = rect.left;
      const mapWidth = rect.width;
      const mapHeight = rect.height;

      setMapPosition(leftPosition);
      setMapWidth(mapWidth);

      const visibleHeight =
        Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      const visibleHeightClamped = Math.max(0, visibleHeight);
      const visibilityPercentage = (visibleHeightClamped / rect.height) * 100;

      setMapVisiblePercentage(visibilityPercentage);
      const distanceFromBottom = window.innerHeight - rect.bottom;
      setDistanceFromBottom(distanceFromBottom);
    }
  };

  useEffect(() => {
    // Call on mount
    calculateMapPosition();
    // Listen for resize events
    window.addEventListener('resize', calculateMapPosition);
    window.addEventListener('scroll', calculateMapPosition);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', calculateMapPosition);
      window.addEventListener('scroll', calculateMapPosition);
    };
  }, []); // Empty dependency array to run only on mount and when resized

  if (!trip) {
    return <></>;
  }

  const handleClose = () => {
    setSelectedPlace(null);
    setSelectedMarkerIndex(null);
    if (onCloseClick) {
      onCloseClick(); // Call the parent-provided function if it exists.
    }
  };

  const handleMapLoad = event => {
    fitBounds(event.target);
  };

  const handleMarkerClick = (step, latitude, longitude) => {
    console.log('step', step);
    if (step) {
      // Ensure the properties are defined or transform null into undefined
      setSelectedPlace({
        step: step ? step : null,
      });
    }
    if (onMarkerClick) {
      onMarkerClick(step.id); // Call the parent-provided function if it exists.
    }

    const newViewport = {
      ...viewport,
      latitude, // Center the map on the marker
      longitude, // Center the map on the marker
      zoom: 5, // Set a desired zoom level (adjust as necessary)
    };

    // Update the viewport
    setViewport(newViewport);

    gtag('event', 'map-click-on-marker');
  };

  return (
    <Box
      id="map"
      ref={mapRef}
      sx={theme => ({
        flex: '1 0 0',
        position: 'sticky',
        top: '0vh',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
        zIndex: 1000,
        borderTopLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,
        borderBottomLeftRadius: mapVisiblePercentage > 99.9 ? 0 : 10,
        display: 'block',
        [theme.breakpoints.down(500)]: {
          display: openOnMobile ? 'block' : 'none',
        },
      })}
    >
      <Map
        initialViewState={{
          latitude: (boundingBox[0][1] + boundingBox[1][1]) / 2,
          longitude: (boundingBox[0][0] + boundingBox[1][0]) / 2,
          zoom: 10,
        }}
        style={{ width: viewport.width, height: viewport.height }}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onLoad={handleMapLoad}
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
      >
        {trip.itinerary &&
          trip.itinerary.coordinates &&
          trip.itinerary.coordinates.length > 0 &&
          trip.itinerary.coordinates.map((co, i) => {
            const step = co?.step;
            const isSelected = selectedMarkerIndex === i; // Check if this marker is selected
            return (
              co && (
                <Marker
                  key={i}
                  longitude={co.longitude!}
                  latitude={co.latitude!}
                  onClick={() => {
                    handleMarkerClick(step, co.latitude, co.longitude);
                    setSelectedMarkerIndex(i);
                    gtag('event', 'map-click-on-marker');
                  }}
                  anchor="bottom"
                >
                  <img
                    src={
                      isSelected
                        ? '/assets/icons/pin-selected.svg'
                        : '/assets/icons/pin.svg'
                    }
                    alt="Map Marker"
                    width={isSelected ? 40 : 30}
                    height={isSelected ? 40 : 30}
                    style={{ cursor: 'pointer' }} // Add this inline style
                  />
                </Marker>
              )
            );
          })}
        {/* Render Popup when marker is clicked */}
        {selectedPlace && (
          <PlaceCard
            step={selectedPlace ? selectedPlace.step : null}
            isMapVisible={mapVisiblePercentage > 99.9}
            mapWidth={mapWidth}
            mapPosition={mapPosition}
            onClose={handleClose}
            distanceFromBottom={distanceFromBottom}
            openOnMobile={openOnMobile}
          />
        )}
      </Map>
    </Box>
  );
}

interface PlaceCardProps {
  step: Step | null;
  onClose: () => void;
  isMapVisible: boolean;
  mapWidth: number;
  mapPosition: number;
  distanceFromBottom: number;
  openOnMobile: boolean | undefined;
}

const PlaceCard: React.FC<PlaceCardProps> = ({
  step,
  onClose,
  isMapVisible,
  mapWidth,
  mapPosition,
  distanceFromBottom,
  openOnMobile,
}) => {
  let bottomValue;
  if (openOnMobile) {
    bottomValue = 0;
  } else if (distanceFromBottom > 0) {
    bottomValue = `${distanceFromBottom + 10}px`;
  } else {
    bottomValue = '10px';
  }

  let bottomValueActionButtons;
  if (openOnMobile) {
    bottomValueActionButtons = '400px';
  } else if (distanceFromBottom > 0) {
    bottomValueActionButtons = `${distanceFromBottom + 230}px`;
  } else {
    bottomValueActionButtons = '230px';
  }

  const { takeMeThereUrl, openGoogleMapsUrl } = getMapUrls(step!);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          position: 'fixed',
          bottom: bottomValueActionButtons,
          zIndex: 10,
          left: openOnMobile ? 0 : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          backgroundColor: 'rgba(240, 240, 240, 0.9)',
          padding: 1,
          paddingRight: 2,
          paddingLeft: 2,
          borderTopRightRadius: '10px',
          borderTopLeftRadius: '10px',
          ...(openOnMobile ? { width: '100%' } : {}),
        }}
      >
        {step && (
          <Box mr={1} sx={{}}>
            <AuthorizedApolloProvider>
              <AddToTripButton stepId={step.id} dayId={''} />
            </AuthorizedApolloProvider>
          </Box>
        )}

        <IconButton
          onClick={onClose}
          style={{
            backgroundColor: 'rgba(54, 69, 79, 0.9)',
            borderRadius: '50%',
            color: '#ffffff',
            width: 30,
            height: 30,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box
        style={{
          bottom: bottomValue,
          backgroundColor: 'white',
          padding: 2,
          borderRadius: '10px',
          overflowY: 'auto',
          zIndex: 10,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: openOnMobile ? 0 : '10px',
          borderBottomRightRadius: openOnMobile ? 0 : '10px',
          position: isMapVisible ? 'absolute' : 'fixed',
          left: isMapVisible ? '50%' : `${mapPosition + 15}px`,
          width: openOnMobile
            ? '100vw'
            : `${(mapWidth - 30).toFixed(1).toString()}px`,
          height: openOnMobile ? '400px' : '220px',
          minHeight: openOnMobile ? '395px' : '215px',
          paddingBottom: '5px',
          boxSizing: 'border-box',
          ...(isMapVisible ? { transform: 'translateX(-50%)' } : {}),
        }}
      >
        <Box
          style={{
            position: 'relative',
          }}
        >
          <CardContent>
            {/* Title and Category */}
            {step && step.title && <TitleH4>{step.title}</TitleH4>}
            {/* <Typography variant="body2" color="text.secondary">
              {place.category}
            </Typography> */}
            {/* Rating and Number of Reviews */}
            {step && step.place && step.place.rating && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{ mb: 0.5 }}
              >
                <Box
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: '#AB88F3',
                    display: 'flex', // Enable flexbox layout
                    alignItems: 'center', // Vertically center content
                    justifyContent: 'center', // Horizontally center content
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                >
                  <GoogleIcon />
                </Box>

                <>
                  <Typography
                    sx={{ fontWeight: 600, color: '#E6B800', fontSize: 18 }}
                  >
                    {formatNumber(step.place.rating)}
                  </Typography>
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                </>

                {step && step.place && step.place.noOfReviews && (
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 16,
                      alignSelf: 'center',
                      // color: '#6E7191',
                    }}
                  >
                    ({step.place.rating ? step.place.noOfReviews : 'No reviews'}
                    )
                  </Typography>
                )}
              </Stack>
            )}
            {/* Opening Hours */}
            {step &&
              step.place &&
              step.place.openingHours &&
              step.place.openingHours.length > 0 && (
                <TodaysHours place={step.place} />
              )}
            {step &&
              step.place &&
              step.place.website &&
              step.place.website !== '' && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <LanguageIcon
                    sx={theme => ({
                      width: 25,
                      height: 25,
                      marginRight: 10,
                      color: theme.palette.icon.main,
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <Button
                    component="a"
                    href={step && step.place && step.place.website}
                    target="_blank"
                    rel="noopener"
                    size="small"
                    sx={{
                      margin: 0,
                      padding: 0,
                      fontWeight: 500,
                      fontSize: 16,
                      alignSelf: 'center',
                      // color: '#6E7191',
                    }}
                  >
                    Visit website
                  </Button>
                </Stack>
              )}
            {/* Address */}
            {step && step.place && step.place && step.place.address && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <LocationOnIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: theme.palette.icon.main,
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                  }}
                >
                  {step.place.address}
                </Typography>
              </Stack>
            )}
            {/* Contact Details */}
            {step && step.place && step.place && step.place.phoneNumber && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{ mt: 1 }}
              >
                <PhoneIcon
                  sx={theme => ({
                    width: 25,
                    height: 25,
                    marginRight: 10,
                    color: theme.palette.icon.main,
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
                <Link
                  href={`tel:${step && step.place && step.place.phoneNumber}`}
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignSelf: 'center',
                    color: '#6E7191',
                  }}
                >
                  {step.place.phoneNumber}
                </Link>
              </Stack>
            )}
            {step &&
            step.description &&
            !(
              step.accommodations &&
              step.accommodations[0] &&
              step.accommodations[0].description
            ) ? (
              <Box sx={{ mt: 2 }}>
                {/* <Divider /> */}
                <Body1>
                  <RenderHtmlFromResponseNoMargin content={step.description} />
                </Body1>
              </Box>
            ) : (
              step &&
              step.accommodations &&
              step.accommodations[0] &&
              step.accommodations[0].description && (
                <Box sx={{ mt: 2 }}>
                  {/* <Divider /> */}
                  <Body1>
                    <RenderHtmlFromResponseNoMargin
                      content={step.accommodations[0].description}
                    />
                  </Body1>
                </Box>
              )
            )}
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mt: 1 }}
            >
              {takeMeThereUrl && (
                <Link
                  href={takeMeThereUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    gtag('event', 'public-link-navigate-click');
                  }}
                >
                  <ResponsiveChip
                    mobileCopy="Navigate"
                    desktopCopy="Navigate"
                    icon={DirectionsIcon}
                  />
                </Link>
              )}
              {openGoogleMapsUrl && (
                <Link
                  href={openGoogleMapsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    gtag('event', 'public-link-maps-url');
                  }}
                >
                  <ResponsiveChip
                    mobileCopy="Maps"
                    desktopCopy="Google Maps"
                    icon={MapIcon}
                  />
                </Link>
              )}
            </Stack>
            {step &&
              step.place &&
              step.place.images &&
              step.place.images.length > 0 && (
                <Grid container spacing={2} mt={2}>
                  {step.place.images.map(image => (
                    <Grid item xs={4} key={image!.path}>
                      <Box
                        sx={{
                          position: 'relative',
                          width: '100%',
                          height: '150px', // Set a fixed height for all images
                          overflow: 'hidden', // Hide anything outside the box
                          borderRadius: '4px', // Optional: to add rounded corners
                        }}
                      >
                        {/* <img
                          src={image!.imageUrl!}
                          alt={image!.title!}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensure image covers the area without stretching
                          }}
                        /> */}
                        <ImageCard image={image} index={image!.id} />
                        <Typography
                          variant="caption"
                          sx={{
                            position: 'absolute',
                            bottom: '8px', // Adjust to move away from the bottom
                            left: '8px', // Adjust to move away from the left
                            color: 'white', // Set text color to stand out on image
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background for better visibility
                            padding: '4px 8px', // Add padding for readability
                            borderRadius: '4px', // Optional: to round the text box
                          }}
                        >
                          {image!.title!}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
          </CardContent>
        </Box>
      </Box>
    </>
    // </Card>
  );
};

function calculateBoundingBox(coordinates: Maybe<ItineraryCoordinates>[]) {
  // Initialize variables with extreme values
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  // Iterate over all coordinates
  coordinates
    .filter(
      (item): item is ItineraryCoordinates =>
        item !== null && item !== undefined,
    )
    .forEach(({ latitude, longitude }) => {
      if (isValidCoordinates(latitude, longitude)) {
      } else {
      }
      if (
        latitude !== null &&
        latitude !== undefined &&
        longitude !== null &&
        longitude !== undefined
      ) {
        if (latitude < minLat) minLat = latitude;
        if (latitude > maxLat) maxLat = latitude;
        if (longitude < minLng) minLng = longitude;
        if (longitude > maxLng) maxLng = longitude;
      }
    });

  // Return the bounding box
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
}

function isValidLatitude(lat) {
  return lat >= -90 && lat <= 90;
}

function isValidLongitude(lng) {
  return lng >= -180 && lng <= 180;
}

function isValidCoordinates(lat, lng) {
  return isValidLatitude(lat) && isValidLongitude(lng);
}
