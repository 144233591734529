import { getAuth, getIdToken } from 'firebase/auth';
import { useEffect, useState } from 'react';

import { useAuth } from '../components/auth/firebase';
import { Step } from '../generated/public_graphql';
import { Image, Maybe } from '../generated/user_graphql';

function isValidLatLng(input: string): boolean {
  // Updated regular expression to match the latitude/longitude format
  const re =
    /^(-?\d{1,3}°\d{1,2}'\d{1,2}(?:\.\d+)?\"[NSEW])\s+(-?\d{1,3}°\d{1,2}'\d{1,2}(?:\.\d+)?\"[NSEW])$/;

  return re.test(input);
}

export function getMapUrls(step: Step): {
  takeMeThereUrl: string | null;
  openGoogleMapsUrl: string | null;
} {
  let takeMeThereUrl: string | null = null;
  let openGoogleMapsUrl: string | null = null;

  if (
    step.place &&
    step.place.address &&
    step.place.title &&
    !isValidLatLng(step.place.title)
  ) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(step.place.title) +
      '+' +
      encodeURIComponent(step.place.address);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by place name and coordinates
  } else if (
    step.place &&
    step.place.lat &&
    step.place.lng &&
    step.place.title &&
    !isValidLatLng(step.place.title)
  )
    if (step.place && step.place.externalPlaceId) {
      // Show place by place ID
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        step.place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } // Show place by place ID
    else if (step.place && step.place.externalPlaceId) {
      takeMeThereUrl =
        'https://www.google.com/maps/place/?q=place_id:' +
        step.place.externalPlaceId;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by place name and address
    } else {
      takeMeThereUrl =
        'https://www.google.com/maps/search/?api=1&query=' +
        encodeURIComponent(step.place.title) +
        '+' +
        step.place.lat +
        '%2C' +
        step.place.lng;
      openGoogleMapsUrl = takeMeThereUrl;

      // Show place by name only
    }
  else if (step.place && step.place.title && !isValidLatLng(step.place.title)) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      encodeURIComponent(step.place.title);
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by coordinates only
  } else if (step.place && step.place.lat && step.place.lng) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      step.place.lat +
      '%2C' +
      step.place.lng;
    openGoogleMapsUrl = takeMeThereUrl;

    // Show place by step coordinates only (user can enter those)
  } else if (step.latitude && step.longitude) {
    takeMeThereUrl =
      'https://www.google.com/maps/search/?api=1&query=' +
      step.latitude +
      '%2C' +
      step.longitude;
  }
  return { takeMeThereUrl, openGoogleMapsUrl };
}

export function formatNumber(num) {
  if (num >= 0 && num < 10 && Number.isInteger(num)) {
    return num + '.0';
  }
  return num.toString();
}

export function formatSecondsToTime(seconds) {
  // Convert seconds to a number in case a string or decimal is passed
  const totalSeconds = Number(seconds);

  if (isNaN(totalSeconds) || totalSeconds < 0) {
    return 'Invalid input';
  }

  // Calculate hours and minutes
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.round((totalSeconds % 3600) / 60);

  // Build the formatted output
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} hour${hours !== 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      formattedTime += ` `;
    }
    formattedTime += `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }

  // Handle edge case when totalSeconds is less than 60 and no hours or minutes were calculated
  if (hours === 0 && minutes === 0) {
    formattedTime = `0 minutes`;
  }

  return formattedTime;
}

export function NumberToMonth(monthNumber: number) {
  switch (monthNumber) {
    case 1:
      return 'January';
    case 2:
      return 'February';
    case 3:
      return 'March';
    case 4:
      return 'April';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'July';
    case 8:
      return 'August';
    case 9:
      return 'September';
    case 10:
      return 'October';
    case 11:
      return 'November';
    case 12:
      return 'December';
  }
}

export function getFourImages(images: Maybe<Image>[]) {
  let headerImage: string | null | undefined = null;
  let mainImage: string | null | undefined = null;
  let secondaryImage: string | null | undefined = null;
  let tertiaryImage: string | null | undefined = null;

  if (images) {
    // Get HEADER
    images.forEach(image => {
      if (image && image.header) {
        headerImage = image.path;
      }
    });

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.main) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.secondary) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      images.forEach(image => {
        if (image && image.tertiary) {
          headerImage = image.path;
        }
      });
    }

    if (!headerImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Main image
    if (headerImage) {
      images.forEach(image => {
        if (image && image.main && image.path !== headerImage) {
          mainImage = image.path;
        }
      });

      if (!mainImage) {
        images.forEach(image => {
          if (image && image.secondary && image.path !== headerImage) {
            mainImage = image.path;
          }
        });
      }

      if (!mainImage) {
        images.forEach(image => {
          if (image && image.tertiary && image.path !== headerImage) {
            mainImage = image.path;
          }
        });
      }
    }
    if (!mainImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Secondary image
    if (mainImage) {
      images.forEach(image => {
        if (
          image &&
          image.secondary &&
          image.path !== headerImage &&
          image.path !== mainImage
        ) {
          secondaryImage = image.path;
        }
      });

      if (!secondaryImage) {
        images.forEach(image => {
          if (
            image &&
            image.tertiary &&
            image.path !== headerImage &&
            image.path !== mainImage
          ) {
            secondaryImage = image.path;
          }
        });
      }
    }
    if (!secondaryImage) {
      return [headerImage, mainImage, secondaryImage, tertiaryImage];
    }

    // get Tertiary image
    if (secondaryImage) {
      images.forEach(image => {
        if (
          image &&
          image.tertiary &&
          image.path !== headerImage &&
          image.path !== mainImage &&
          image.path !== secondaryImage
        ) {
          tertiaryImage = image.path;
        }
      });
    }
  }

  return [headerImage, mainImage, secondaryImage, tertiaryImage];
}

export function getTwoImages(images: Maybe<Image>[]) {
  let mainImage: string | null | undefined = null;
  let secondaryImage: string | null | undefined = null;

  if (images) {
    // Get primary image
    images.forEach(image => {
      if (image && image.header) {
        mainImage = image.path;
      }
    });

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.main) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.secondary) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      images.forEach(image => {
        if (image && image.tertiary) {
          mainImage = image.path;
        }
      });
    }

    if (!mainImage) {
      return [mainImage, secondaryImage];
    }

    // Get secondary image
    if (mainImage) {
      images.forEach(image => {
        if (image && image.main && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }

    if (!secondaryImage) {
      images.forEach(image => {
        if (image && image.secondary && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }

    if (!secondaryImage) {
      images.forEach(image => {
        if (image && image.tertiary && image.path !== mainImage) {
          secondaryImage = image.path;
        }
      });
    }
  }
  return [mainImage, secondaryImage];
}

export function validateEmail(mail) {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
      mail,
    )
  ) {
    return true;
  }
  // alert('You have entered an invalid email address!');
  return false;
}

export function returnFormatedDate(date: Date, addDays: number) {
  const inputDate = new Date(date);

  if (isNaN(inputDate.getTime())) {
    // If the input date is invalid, return an empty string
    return '';
  }
  const newDate = new Date(inputDate.setDate(inputDate.getDate() + addDays));

  const weekday = new Array(7);
  weekday[0] = 'Sun';
  weekday[1] = 'Mon';
  weekday[2] = 'Tue';
  weekday[3] = 'Wed';
  weekday[4] = 'Thu';
  weekday[5] = 'Fri';
  weekday[6] = 'Sat';

  const month = new Array(12);
  month[0] = '1';
  month[1] = '2';
  month[2] = '3';
  month[3] = '4';
  month[4] = '5';
  month[5] = '6';
  month[6] = '7';
  month[7] = '8';
  month[8] = '9';
  month[9] = '10';
  month[10] = '11';
  month[11] = '12';

  let displayDay: string | null = null;

  if (newDate) {
    displayDay =
      weekday[newDate.getDay()] +
      ' ' +
      newDate.getDate() +
      '/' +
      month[newDate.getMonth()] +
      '/' +
      newDate.getFullYear();
  }

  if (displayDay) {
    return displayDay;
  } else {
    return '';
  }
}

export const useUserRoles = () => {
  const { isAuthenticated, idToken } = useAuth();
  const [roles, setRoles] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const getUserRoles = async () => {
      if (isAuthenticated && user != null) {
        try {
          // const token = await getAccessTokenSilently();

          const token = await getIdToken(user);
          const decodedToken = JSON.parse(atob(token.split('.')[1]));

          // Assuming roles are stored under a custom claim (namespace-based)
          const userRoles = decodedToken['https://rooutie.com/roles'] || [];
          setRoles(userRoles);
        } catch (err) {
          console.error('Error fetching roles:', err);
        }
      }
    };

    getUserRoles();
  }, [isAuthenticated, idToken]);

  return roles;
};

export const isPublisher = roles => {
  return roles.includes('Publisher');
};

export const isAdmin = roles => {
  return roles.includes('Admin');
};

// utils.ts
export const isRunningStandalone = (): boolean => {
  try {
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any).standalone ||
      document.referrer.includes('android-app://');
    return isStandalone;
  } catch (error) {
    console.error('Error determining standalone mode:', error);
    return false;
  }
};
