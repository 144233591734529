import { gql } from 'graphql-tag';

export const QUERY_GET_USER_SHARED_TRIPS = gql`
  query userSharedTripsList {
    userSharedTripsList {
      id
      name
      length
      published
      tripType
      publish_pending
      images {
        id
        path
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        countryName
        itineraryId
      }
    }
  }
`;
