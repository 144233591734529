import { Box } from '@mui/material';

import { Step } from '../../../generated/user_graphql';

import PlaceCard from './PlaceCard';

export function DayStepDetails(props: { step: Step }) {
  return (
    <Box
      sx={theme => ({
        width: '100%',
        paddingLeft: 3.25,
        marginTop: 1,
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 3,
        },
      })}
    >
      {props.step && (
        <>
          {props.step.type &&
          props.step.type.includes('STEP_TYPE_TRANSPORT') &&
          (!props.step.description || props.step.description === '') ? null : (
            <>{props.step && <PlaceCard step={props.step} />}</>
          )}
        </>
      )}
    </Box>
  );
}
