import { gql } from 'graphql-tag';

export const QUERY_COUNTRIES_TRIPS = gql`
  query countriesTrips {
    countriesTrips {
      country {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      countryTrips {
        id
        name
        tripType
        length
        images {
          id
          path
          header
          main
          secondary
          tertiary
        }
        countries {
          id
          name
          description
          url
          iso2Code
          iso3Code
        }
        itineraryCountries {
          countryName
          itineraryId
        }
      }
    }
  }
`;
