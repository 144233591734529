import { useMutation } from '@apollo/client';
import { Box, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { setDays } from '../../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../../store/ErrorSlice';
import {
  setAddStepModalOpen,
  setStepDescription,
  setStepDescriptionAdded,
  setStepLatitude,
  setStepLongitude,
  stepsSelector,
} from '../../../../store/StepSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../theme-components/Buttons';
import { Loader } from '../../../../theme-components/Loader';
import { MUTATION_UPDATE_STEP } from '../../../gql-user/updateStep';
import { VerticalFlex } from '../../../helpers/flex';
import MyEditor from '../../../MyEditor';
import { ModalDescription } from '../../../styling/modal';
import { StyledFormControl } from '../Styles';

export const AddStepInfo: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const {
    stepId,
    stepDescription,
    stepDuration,
    stepMoreInfoUrl,
    stepCategory,
    stepLatitude,
    stepLongitude,
  } = useSelector(stepsSelector);

  const [updateStep, { loading }] = useMutation(MUTATION_UPDATE_STEP, {
    variables: {
      step_id: stepId,
      description: stepDescription,
      duration: stepDuration,
      url: stepMoreInfoUrl,
      latitude: stepLatitude,
      longitude: stepLongitude,
    },
  });

  const handleEditorChange = (content: string) => {
    dispatch(setStepDescription(content));
  };

  return (
    <>
      <VerticalFlex>
        <StyledFormControl>
          <ModalDescription>Description</ModalDescription>
          <MyEditor value={stepDescription} onChange={handleEditorChange} />
        </StyledFormControl>
        {stepCategory !== 'CATEGORY_ACCOMMODATION' &&
        stepCategory !== 'CATEGORY_TRANSPORT' ? (
          <>
            <StyledFormControl>
              Easily right click on any place on Google Maps and copy
              coordinates.
              {/* <TextField
                id="standard-basic"
                label="Step additional info URL"
                name="step-location"
                multiline={true}
                fullWidth={true}
                variant="outlined"
                onChange={e => {
                  dispatch(setStepMoreInfoUrl(e.target.value));
                }}
              /> */}
              <TextField
                id="standard-basic"
                label="Place location (paste coordinates)"
                name="step-location"
                multiline={true}
                fullWidth={true}
                variant="outlined"
                onChange={e => {
                  const res = e.target.value.split(',', 2);
                  const latitude = parseFloat(res[0]);
                  const longitude = parseFloat(res[1]);
                  if (latitude <= 90 && latitude >= -90) {
                    dispatch(setStepLatitude(latitude));
                  } else {
                    dispatch(setStepLatitude(0));
                  }
                  if (longitude <= 180 && latitude >= -180) {
                    dispatch(setStepLongitude(longitude));
                  } else {
                    dispatch(setStepLongitude(0));
                  }
                }}
              />
            </StyledFormControl>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                justifyContent: 'flex-end', // Aligns items to the right
              }}
            >
              {stepId && (
                <VerticalFlex>
                  <Box display="flex">
                    <Box mr={2}>
                      <SecondaryButton
                        onClick={() => {
                          gtag('event', 'add-step-add-details-later');
                          dispatch(setAddStepModalOpen(false));
                          handleCloseModal();
                        }}
                      >
                        Add later
                      </SecondaryButton>
                    </Box>

                    <PrimaryButton
                      disabled={loading}
                      onClick={async () => {
                        try {
                          gtag('event', 'add-step-add-details-next');
                          const res = await updateStep();

                          if (
                            res &&
                            res.data &&
                            res.data.updateUserStep &&
                            res.data.updateUserStep.days
                          ) {
                            dispatch(setDays(res.data.updateUserStep.days));
                            dispatch(setStepDescriptionAdded(true));
                          }
                        } catch (e) {
                          gtag('event', 'error-add-step-add-details');
                          dispatch(setErrorTitle('Error adding details'));
                          dispatch(setErrorModalOpen(true));
                          handleCloseModal();
                        }
                      }}
                    >
                      {loading ? <Loader /> : 'Add to itinerary'}
                    </PrimaryButton>
                  </Box>
                </VerticalFlex>
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'flex-end', // Aligns items to the right
            }}
          >
            {stepId && (
              <VerticalFlex>
                <Box>
                  <PrimaryButton
                    disabled={loading}
                    onClick={async () => {
                      if (stepDescription) {
                        const res = await updateStep();
                        if (
                          res &&
                          res.data &&
                          res.data.updateUserStep &&
                          res.data.updateUserStep.days
                        ) {
                          dispatch(setDays(res.data.updateUserStep.days));
                        }
                      }
                      dispatch(setAddStepModalOpen(false));
                    }}
                  >
                    {loading ? <Loader /> : 'Finish'}
                  </PrimaryButton>
                </Box>
              </VerticalFlex>
            )}
          </Box>
        )}
      </VerticalFlex>
    </>
  );
};
