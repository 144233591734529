import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import MapIcon from '@mui/icons-material/Map';
import { Box, Link } from '@mui/material';

import { Maybe, Recommendation } from '../../generated/public_graphql';
import { Body1, TitleH5 } from '../../theme-components/Typography';

interface RecommendationProps {
  readonly recommentations: Maybe<Recommendation>[];
}

export function StepRecommendations(props: RecommendationProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {props.recommentations &&
        props.recommentations.length > 0 &&
        props.recommentations.map((recommendation, i) => {
          return (
            <div key={i}>
              {recommendation && (
                <Box
                  sx={{
                    background:
                      'linear-gradient(180deg, #FAFAFA 0%, #E9D7FC 100%)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: 3,
                    marginBottom: 2,
                  }}
                >
                  <TitleH5>{recommendation.name}</TitleH5>
                  {recommendation.latitude && recommendation.longitude && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <DirectionsIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkNavigation
                        lat={recommendation.latitude}
                        lng={recommendation.longitude}
                        copy={'Take me there'}
                      />
                    </Box>
                  )}
                  {recommendation.locationUrl && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <MapIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkMapsUrl
                        url={recommendation.locationUrl}
                        copy={'Open in Google Maps'}
                      />
                    </Box>
                  )}
                  {recommendation && recommendation.url && (
                    <Box
                      sx={theme => ({
                        marginBottom: theme.spacing(0.5),
                        marginTop: theme.spacing(0.5),
                        alignItems: 'center',
                        display: 'flex',
                      })}
                    >
                      <EventTwoToneIcon
                        sx={theme => ({
                          width: 25,
                          height: 25,
                          marginRight: 1,
                          color: theme.palette.icon.main,
                        })}
                      />
                      <ExternalLinkBooking
                        url={recommendation.url}
                        copy={'Get more info'}
                      />
                    </Box>
                  )}
                  <Body1>{recommendation.description}</Body1>
                </Box>
              )}
            </div>
          );
        })}
    </Box>
  );
}

export function ExternalLinkMapsUrl(props: { url: string; copy: string }) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: '#6E7191',
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkBooking(props: { url: string; copy: string }) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={props.url}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: theme.palette.primary.light,
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}

export function ExternalLinkNavigation(props: {
  lat: number;
  lng: number;
  copy: string;
}) {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      href={'http://www.google.com/maps/place/' + props.lat + ',' + props.lng}
      sx={theme => ({
        fontWeight: 500,
        fontSize: 16,
        alignSelf: 'center',
        color: '#6E7191',
        '&:hover': {
          textDecoration: 'none',
        },
      })}
    >
      {props.copy}
    </Link>
  );
}
