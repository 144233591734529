import { configureStore } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './components/App';
import { AppWrapper } from './components/AppWrapper';
import { AuthProvider } from './components/auth/AuthWrapper';
import { firebaseConfig } from './components/auth/firebase';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import accommodationSliceReducer from './store/AccommodationSlice';
import daysSliceReducer from './store/DaySlice';
import errorSliceReducer from './store/ErrorSlice';
import { publicTripSliceReducer } from './store/PublicTripSlice';
import stepsSliceReducer from './store/StepSlice';
import { tripSliceReducer } from './store/TripSlice';
import { userSliceReducer } from './store/UserSlice';
import UnauthorizedApolloProvider from './UnAuthorizedApolloProvider';

const store = configureStore({
  reducer: {
    publicTrip: publicTripSliceReducer,
    userTrip: tripSliceReducer,
    userTripDays: daysSliceReducer,
    userAddingStep: stepsSliceReducer,
    userAddingAccommodation: accommodationSliceReducer,
    errorModal: errorSliceReducer,
    user: userSliceReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

// Initialise FB
initializeApp(firebaseConfig);

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <Router>
      <AuthProvider>
        <UnauthorizedApolloProvider>
          <AppWrapper>
            <App />
          </AppWrapper>
        </UnauthorizedApolloProvider>
      </AuthProvider>
    </Router>
  </Provider>,
);

// Register the service worker
serviceWorkerRegistration.register();
