import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { Img } from 'react-image';

import { Trip } from '../../generated/public_graphql';
import { getFourImages } from '../../utils/helpers';

import AddTripImagesModal from './manage-trip-modals/AddTripImages';

interface TripHeaderProps {
  readonly trip: Trip;
}

export function CreateTripHeaderImages(props: TripHeaderProps) {
  // const classes = useStyles();

  let header: string | null | undefined = null;
  let main: string | null | undefined = null;
  let secondary: string | null | undefined = null;
  let tertiary: string | null | undefined = null;
  let tripImages: null[] = [];

  if (props.trip && props.trip.images && props.trip.images.length > 0) {
    tripImages = getFourImages(props.trip.images);
  }
  header = tripImages[0];
  main = tripImages[1];
  secondary = tripImages[2];
  tertiary = tripImages[3];

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
      }}
    >
      {props.trip &&
      props.trip.images &&
      props.trip.images &&
      props.trip.images.length > 0 ? (
        <>
          {header && main && secondary && tertiary && (
            <Box
              sx={{
                marginTop: 1,
                marginBottom: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', // Ensures images are centered horizontally
                alignItems: 'center',
              }}
            >
              <Box
                sx={theme => ({
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  width: '100%',
                  marginRight: 0.5,
                  height: 350,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 280,
                  },

                  [theme.breakpoints.down('xs')]: {
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    height: 230,
                    marginRight: 0,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    width: '100%',
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('xs')]: {
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 0,
                      height: 230,
                      marginRight: 0,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      width: '100%',
                      height: 350,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('xs')]: {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 10,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 0,
                        height: 230,
                        marginRight: 0,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={header}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopLeftRadius: 10,
                            borderBottomLeftRadius: 10,
                            width: '100%',
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              height: 280,
                            },
                            [theme.breakpoints.down('xs')]: {
                              borderTopRightRadius: 0,
                              borderTopLeftRadius: 10,
                              borderBottomLeftRadius: 10,
                              borderBottomRightRadius: 0,
                              height: 230,
                              marginRight: 0,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  marginRight: 0.5,
                  width: '30%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 350,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    height: 280,
                  },
                  [theme.breakpoints.down('xs')]: {
                    height: 230,
                    width: '40%',
                    marginLeft: 0.5,
                    marginRight: 0,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    maxWidth: 300,
                    width: '100%',
                    marginBottom: 0.5,
                    height: 180,
                    [theme.breakpoints.down('md')]: {
                      height: 175,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 145,
                      borderTopRightRadius: 10,
                    },
                    [theme.breakpoints.down('xs')]: {
                      height: 115,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={secondary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          maxWidth: 300,
                          width: '100%',
                          marginBottom: 0.5,
                          height: 180,
                          [theme.breakpoints.down('md')]: {
                            height: 175,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 145,
                            borderTopRightRadius: 10,
                          },
                          [theme.breakpoints.down('xs')]: {
                            height: 115,
                          },
                        })}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={theme => ({
                    maxWidth: 300,
                    width: '100%',
                    height: 165,
                    [theme.breakpoints.down('md')]: {
                      height: 145,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 130,
                      borderBottomRightRadius: 10,
                    },
                    [theme.breakpoints.down('xs')]: {
                      height: 110,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={tertiary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          maxWidth: 300,
                          width: '100%',
                          height: 165,
                          [theme.breakpoints.down('md')]: {
                            height: 145,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 130,
                            borderBottomRightRadius: 10,
                          },
                          [theme.breakpoints.down('xs')]: {
                            height: 110,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  display: { xs: 'none', sm: 'block' },
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  width: '40%',
                  height: 350,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 280,
                  },
                  [theme.breakpoints.down('xs')]: {
                    height: 130,
                    width: '100%',
                    flexDirection: 'column',
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    width: '100%',
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('xs')]: {
                      borderTopRightRadius: 0,
                      borderBottomLeftRadius: '10px',
                      marginBottom: 0,
                      height: 130,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={main}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopRightRadius: '10px',
                          borderBottomRightRadius: '10px',
                          width: '100%',
                          height: 350,
                          [theme.breakpoints.down('md')]: {
                            height: 325,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 280,
                          },
                          [theme.breakpoints.down('xs')]: {
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: '10px',
                            marginBottom: 0,
                            height: 130,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
            // </Box>
          )}
          {header && main && secondary && !tertiary && (
            <Box
              sx={{
                marginTop: 1,
                marginBottom: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center', // Ensures images are centered horizontally
                alignItems: 'center',
              }}
            >
              <Box
                sx={theme => ({
                  borderTopLeftRadius: '10px',
                  borderBottomLeftRadius: '10px',
                  width: '100%',
                  marginRight: 0.5,
                  height: 350,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 280,
                  },

                  [theme.breakpoints.down('xs')]: {
                    borderTopRightRadius: '10px',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    height: 230,
                    marginRight: 0,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    width: '100%',
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('xs')]: {
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: 0,
                      height: 230,
                      marginRight: 0,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={header}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopLeftRadius: '10px',
                          borderBottomLeftRadius: '10px',
                          width: '100%',
                          height: 350,
                          [theme.breakpoints.down('md')]: {
                            height: 325,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 280,
                          },
                          [theme.breakpoints.down('xs')]: {
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            borderBottomRightRadius: 0,
                            height: 230,
                            marginRight: 0,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={theme => ({
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 350,
                  [theme.breakpoints.down('md')]: {
                    height: 325,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 280,
                  },
                  [theme.breakpoints.down('xs')]: {
                    // display: 'none',
                    // width: 0,
                    // height: 0,
                    marginLeft: 0.5,
                  },
                })}
              >
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    width: '100%',
                    height: 180,
                    marginBottom: 0.5,
                    [theme.breakpoints.down('md')]: {
                      height: 175,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 140,
                    },
                    [theme.breakpoints.down('xs')]: {
                      borderTopRightRadius: '10px',
                      borderBottomLeftRadius: 0,
                      marginBottom: 0.5,
                      // marginRight: 0.5,
                      height: 115,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={main}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderTopRightRadius: '10px',
                          width: '100%',
                          height: 180,
                          marginBottom: 0.5,
                          [theme.breakpoints.down('md')]: {
                            height: 175,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 140,
                          },
                          [theme.breakpoints.down('xs')]: {
                            borderTopRightRadius: '10px',
                            borderBottomLeftRadius: 0,
                            marginBottom: 0.5,
                            // marginRight: 0.5,
                            height: 115,
                          },
                        })}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={theme => ({
                    borderBottomRightRadius: '10px',
                    width: '100%',
                    height: 165,
                    [theme.breakpoints.down('md')]: {
                      height: 145,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 135,
                    },
                    [theme.breakpoints.down('xs')]: {
                      height: 110,
                    },
                  })}
                >
                  <Img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      borderRadius: 'inherit', // Inherit border radius from the parent Box
                    }}
                    src={secondary}
                    loader={
                      <Skeleton
                        variant="rectangular"
                        sx={theme => ({
                          borderBottomRightRadius: '10px',
                          width: '100%',
                          height: 165,
                          [theme.breakpoints.down('md')]: {
                            height: 145,
                          },
                          [theme.breakpoints.down('sm')]: {
                            height: 135,
                          },
                          [theme.breakpoints.down('xs')]: {
                            height: 110,
                          },
                        })}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          )}
          {header && main && !secondary && !tertiary && (
            <>
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center', // Ensures images are centered horizontally
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    width: '100%',
                    marginRight: 0.5,
                    height: 350,
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 280,
                    },

                    [theme.breakpoints.down('xs')]: {
                      borderTopRightRadius: '10px',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                      height: 230,
                      marginRight: 0,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      width: '100%',
                      height: 350,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('xs')]: {
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: 0,
                        height: 230,
                        marginRight: 0,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={header}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopLeftRadius: '10px',
                            borderBottomLeftRadius: '10px',
                            width: '100%',
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              height: 280,
                            },
                            [theme.breakpoints.down('xs')]: {
                              borderTopRightRadius: 0,
                              borderTopLeftRadius: '10px',
                              borderBottomLeftRadius: '10px',
                              borderBottomRightRadius: 0,
                              height: 230,
                              marginRight: 0,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={theme => ({
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    width: '45%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.breakpoints.down('xs')]: {
                      marginLeft: 0.5,
                      flexDirection: 'row',
                      width: '100%',
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px',
                      width: '100%',
                      height: 350,
                      [theme.breakpoints.down('md')]: {
                        height: 325,
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: 280,
                      },
                      [theme.breakpoints.down('xs')]: {
                        height: 230,
                      },
                    })}
                  >
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      src={main}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            width: '100%',
                            height: 350,
                            [theme.breakpoints.down('md')]: {
                              height: 325,
                            },
                            [theme.breakpoints.down('sm')]: {
                              height: 280,
                            },
                            [theme.breakpoints.down('xs')]: {
                              height: 230,
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {header && !main && !secondary && !tertiary && (
            <>
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center', // Ensures images are centered horizontally
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={theme => ({
                    borderRadius: '10px',
                    width: '100%',
                    height: 350,
                    // overflow: 'hidden',
                    [theme.breakpoints.down('md')]: {
                      height: 325,
                    },
                    [theme.breakpoints.down('sm')]: {
                      height: 280,
                    },
                    [theme.breakpoints.down('xs')]: {
                      height: 245,
                    },
                  })}
                >
                  <Box
                    sx={theme => ({
                      borderRadius: '10px',
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover', // Ensures the image fills the box without distortion
                      [theme.breakpoints.down('md')]: {
                        height: '100%', // Stretch to fill the available height
                      },
                      [theme.breakpoints.down('sm')]: {
                        height: '100%', // Same for small screens
                      },
                      [theme.breakpoints.down('xs')]: {
                        height: '100%', // And for extra small screens
                      },
                    })}
                  >
                    <Img
                      src={header}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        borderRadius: 'inherit', // Inherit border radius from the parent Box
                      }}
                      loader={
                        <Skeleton
                          variant="rectangular"
                          sx={theme => ({
                            borderRadius: '10px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Ensures the image fills the box without distortion
                            [theme.breakpoints.down('md')]: {
                              height: '100%', // Stretch to fill the available height
                            },
                            [theme.breakpoints.down('sm')]: {
                              height: '100%', // Same for small screens
                            },
                            [theme.breakpoints.down('xs')]: {
                              height: '100%', // And for extra small screens
                            },
                          })}
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              marginTop: 1,
              marginBottom: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center', // Ensures images are centered horizontally
              alignItems: 'center',
            }}
          >
            <Box
              sx={theme => ({
                width: '100%',
                height: 350,
                // overflow: 'hidden',
                [theme.breakpoints.down('md')]: {
                  height: 325,
                },
                [theme.breakpoints.down('sm')]: {
                  height: 280,
                },
                [theme.breakpoints.down('xs')]: {
                  height: 245,
                },
              })}
            >
              <Box
                sx={theme => ({
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Ensures the image fills the box without distortion
                  [theme.breakpoints.down('md')]: {
                    height: '100%', // Stretch to fill the available height
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: '100%', // Same for small screens
                  },
                  [theme.breakpoints.down('xs')]: {
                    height: '100%', // And for extra small screens
                  },
                })}
              >
                <Img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover', // Ensures the image fills the box without distortion
                    borderRadius: 'inherit', // Inherit border radius from the parent Box
                  }}
                  src={'/assets/image/placeholder-svg.svg'}
                  loader={
                    <Skeleton
                      variant="rectangular"
                      sx={theme => ({
                        borderRadius: '10px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensures the image fills the box without distortion
                        [theme.breakpoints.down('md')]: {
                          height: '100%', // Stretch to fill the available height
                        },
                        [theme.breakpoints.down('sm')]: {
                          height: '100%', // Same for small screens
                        },
                        [theme.breakpoints.down('xs')]: {
                          height: '100%', // And for extra small screens
                        },
                      })}
                    />
                  }
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <AddTripImagesModal tripId={props.trip.id} trip={props.trip} />
    </Box>
  );
}
