import { getAuth, onAuthStateChanged, getIdToken, User } from 'firebase/auth';
import React, { useEffect, useState, createContext } from 'react';

// Define the shape of your context state
interface AuthContextType {
  user: User | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  idToken: string | null;
  error: Error | null;
}

// Create the AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a custom hook to use the AuthContext
// export const useAuth = (): AuthContextType => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error('useAuth must be used within an AuthProvider');
//   }
//   return context;
// };

// Create the AuthProvider component
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
        try {
          const token = await getIdToken(user);
          setIdToken(token);
        } catch (err) {
          setError(err as Error);
        }
      } else {
        setUser(null);
        setIdToken(null);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isLoading, isAuthenticated, idToken, error }}
    >
      {children}
    </AuthContext.Provider>
  );
};
