import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Button, Tooltip, Box, Stack } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setStepType } from '../../../store/StepSlice';
import { AddStepToDay } from '../manage-trip-modals/add-step/AddStepToDayModal';

interface ActionsDayProps {
  dayId: string;
  dayNumber: number;
  iconsOnly: boolean;
  tripType?: string | undefined | null;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const ActionsDay: React.FC<ActionsDayProps> = ({
  dayId,
  dayNumber,
  iconsOnly,
  tripType,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [accomodation, setAccomodation] = useState(false);
  const [transport, setTransport] = useState(false);
  const [place, setPlace] = useState(false);
  const [activity, setActivity] = useState(false);
  const [foodDrink, setFoodDrink] = useState(false);
  const [accomodationIcons, setAccomodationIcons] = useState(false);
  const [transportIcons, setTransportIcons] = useState(false);
  const [placeIcons, setPlaceIcons] = useState(false);
  const [activityIcons, setActivityIcons] = useState(false);
  const [foodDrinkIcons, setFoodDrinkIcons] = useState(false);
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [scrollY, setScrollY] = useState(0);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);

  const dispatch = useDispatch();

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;
    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
  };

  const handleClose = () => {
    setIsModalDisplayed(false);

    // Reset all step states
    setPlace(false);
    setTransport(false);
    setAccomodation(false);
    setActivity(false);
    setFoodDrink(false);

    // Reset icon states
    setPlaceIcons(false);
    setTransportIcons(false);
    setAccomodationIcons(false);
    setActivityIcons(false);
    setFoodDrinkIcons(false);

    setAnchorEl(null);
    enableScroll();
  };

  const handleCloseModal = () => {
    // Close the modal
    setIsModalDisplayed(false);

    // Reset all step states
    setPlace(false);
    setTransport(false);
    setAccomodation(false);
    setActivity(false);
    setFoodDrink(false);

    // Reset icon states
    setPlaceIcons(false);
    setTransportIcons(false);
    setAccomodationIcons(false);
    setActivityIcons(false);
    setFoodDrinkIcons(false);

    // Ensure menu is closed too, if it was open
    setAnchorEl(null);
    // Delay as there was some race condition not allowing to scroll
    setTimeout(() => {
      enableScroll();
    }, 300);
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    // document.body.style.top = ''; // Reset top
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  useEffect(() => {
    if (isModalDisplayed) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    return () => {
      // setIsModalDisplayed(false);
      // there was enable scroll but that caused unexpected screen scrolls to top of the screen
    };
  }, [isModalDisplayed]);

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  return (
    <Box
      sx={theme => ({
        flexShrink: 0, // Prevent the button from shrinking
      })}
    >
      {!iconsOnly ? (
        <>
          <Button
            variant="contained"
            endIcon={
              <ArrowDropDownIcon
                sx={theme => ({
                  height: 17,
                  width: 17,
                  pointerEvents: 'none',
                })}
              />
            }
            onClick={e => {
              const scrollPosition = window.scrollY;
              setScrollY(scrollPosition);
              handleClick(e);
            }}
            sx={theme => ({
              fontSize: 12,
              paddingTop: 0.5,
              paddingBottom: 0.5,
              paddingLeft: 1.75,
              paddingRight: 1.75,
              backgroundColor: '#36454F',
              borderRadius: 20,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              boxShadow: 'none', // Disable shadow
              '&:hover': {
                boxShadow: 'none',
                backgroundColor: '#4A5D6F',
              },
            })}
          >
            {tripType && tripType === 'guide' ? (
              <>Add to guide</>
            ) : (
              <>
                Add to Day {dayNumber}
                {iconsOnly && ': '}
              </>
            )}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                setPlace(true);
                setTransport(false);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_PLACE'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_PLACE"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={place}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                setPlace(false);
                setTransport(false);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(true);
                dispatch(setStepType('CATEGORY_FOOD_DRINK'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_FOOD_DRINK"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={foodDrink}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                setPlace(false);
                setTransport(false);
                setAccomodation(true);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_ACCOMMODATION'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_ACCOMMODATION"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={accomodation}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                setPlace(false);
                setTransport(true);
                setAccomodation(false);
                setActivity(false);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_TRANSPORT'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_TRANSPORT"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={transport}
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                setPlace(false);
                setTransport(false);
                setAccomodation(false);
                setActivity(true);
                setFoodDrink(false);
                dispatch(setStepType('CATEGORY_ACTIVITY'));
              }}
            >
              <AddStepToDay
                dayId={dayId}
                category="CATEGORY_ACTIVITY"
                iconsOnly={false}
                handleCloseModal={handleCloseModal}
                modalOpen={activity}
              />
            </MenuItem>
            <Tooltip title="Coming soon!">
              <span>
                {/* Wrapper required for disabled MenuItem to allow Tooltip */}
                <MenuItem disabled>
                  <ListItemIcon>
                    <DeleteTwoToneIcon />
                  </ListItemIcon>
                  Delete day
                </MenuItem>
              </span>
            </Tooltip>
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            fontSize: 18,
            fontWeight: 600,
            marginLeft: 2,
            padding: '10px 20px',
            backgroundColor: '#F0F0F0',
            borderRadius: '10px',
            // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            marginBottom: 2,
            // transition: 'box-shadow 0.3s ease, transform 0.3s ease', // Smooth transitions
            // '&:hover': {
            //   boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.15)', // Slightly stronger shadow on hover
            //   transform: 'translateY(-2px)', // Lift on hover
            // },
          }}
        >
          <Stack direction="column" alignItems="center" width="100%">
            <Box
              sx={{
                fontSize: 20,
                fontWeight: 700,
                color: '#333',
                marginBottom: 1,
              }}
            >
              {tripType && tripType === 'guide' ? (
                <>Add to guide</>
              ) : (
                <>Add to Day {dayNumber}</>
              )}
            </Box>
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              spacing={2}
              rowGap={1}
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#555',
                padding: '8px 16px',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
              }}
            >
              <Box
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  setPlaceIcons(true);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_PLACE'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_PLACE"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={placeIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(true);
                  dispatch(setStepType('CATEGORY_FOOD_DRINK'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_FOOD_DRINK"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={foodDrinkIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(true);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_ACCOMMODATION'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_ACCOMMODATION"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={accomodationIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  setPlaceIcons(false);
                  setTransportIcons(true);
                  setAccomodationIcons(false);
                  setActivityIcons(false);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_TRANSPORT'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_TRANSPORT"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={transportIcons}
                />
              </Box>
              <Box
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  setPlaceIcons(false);
                  setTransportIcons(false);
                  setAccomodationIcons(false);
                  setActivityIcons(true);
                  setFoodDrinkIcons(false);
                  dispatch(setStepType('CATEGORY_ACTIVITY'));
                }}
                sx={{
                  cursor: 'pointer', // Ensure pointer cursor on hover
                }}
              >
                <AddStepToDay
                  dayId={dayId}
                  category="CATEGORY_ACTIVITY"
                  iconsOnly={true}
                  handleCloseModal={handleCloseModal}
                  modalOpen={activityIcons}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
