import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth } from './components/auth/firebase';
import { FullScreenBusAnimation } from './components/animations/BusAnimation';
import { LoaderCentred, LoaderFullScreen } from './theme-components/Loader';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

interface Props {
  children: React.ReactNode;
}

// Create the Apollo Client once and keep it globally
let apolloClient: ApolloClient<any> | null = null;

// Create a function to initialize Apollo Client
const createApolloClient = (authLink: any, errorLink: any, httpLink: any) => {
  if (!apolloClient) {
    console.log('Creating Apollo Client');
    apolloClient = new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache(),
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: { errorPolicy: 'all' },
        query: { errorPolicy: 'all' },
        mutate: { errorPolicy: 'all' },
      },
    });
  }
  return apolloClient;
};

const AuthorizedApolloProvider: React.FC<Props> = React.memo(
  ({ children = <LoaderCentred /> }: Props) => {
    const { isLoading } = useAuth();
    let { user: myUser, idToken } = useAuth();
    const [tokenExpiration, setTokenExpiration] = useState<number | null>(null);

    const currentIdTokenRef = useRef<string | null>(idToken);

    useEffect(() => {
      if (myUser && idToken) {
        const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
        if (decodedToken.exp) {
          const expTime = decodedToken.exp * 1000; // Convert to milliseconds
          setTokenExpiration(expTime); // Set the token expiration time
        }
        currentIdTokenRef.current = idToken; // Update reference to the current token
      } else {
        console.log('gert user again');
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async user => {
          if (user) {
            console.log('User is signed in:', user);
            myUser = user;
            idToken = await myUser.getIdToken(); // No 'true' argument, so no forced refresh
            const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
            if (decodedToken.exp) {
              const expTime = decodedToken.exp * 1000; // Convert to milliseconds
              setTokenExpiration(expTime); // Set the token expiration time
            }
            currentIdTokenRef.current = idToken; //
          } else {
            console.log('No user is signed in, re-authenticating...');
            // Handle re-authentication logic here, such as redirecting to login
          }
        });
        return () => unsubscribe();
      }
    }, [myUser, idToken]);

    // Function to refresh the auth token
    const refreshAuthToken = async (
      maxRetries = 3,
      retryDelay = 300,
    ): Promise<string | null> => {
      let attempts = 0;

      while (attempts < maxRetries) {
        try {
          if (myUser) {
            console.log('refreshing token');
            const newToken = await myUser.getIdToken(true); // Force refresh
            console.log('Refreshed Token:', newToken);
            return newToken; // Return the new token
          } else {
            // guard in case there is not user
            attempts++;
          }
        } catch (error) {
          attempts++;
          console.error(`Attempt ${attempts} failed: ${error}`);

          if (attempts < maxRetries) {
            await new Promise(resolve => setTimeout(resolve, retryDelay)); // Wait before retrying
          } else {
            console.error('Max retries reached. Returning current token.');
          }
        }
      }

      // Fallback to the current token if refresh fails
      return currentIdTokenRef.current;
    };

    const getFreshToken = async () => {
      if (!tokenExpiration) {
        const auth = getAuth();
        console.log('no token exp');
        // return currentIdTokenRef.current; // Return current token if no expiration is set
        const unsubscribe = onAuthStateChanged(auth, async user => {
          if (user) {
            console.log('User is signed in:', user);
            myUser = user;
            idToken = await myUser.getIdToken(); // No 'true' argument, so no forced refresh
            const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
            if (decodedToken.exp) {
              const expTime = decodedToken.exp * 1000; // Convert to milliseconds
              setTokenExpiration(expTime); // Set the token expiration time
            }
            currentIdTokenRef.current = idToken; //
            return currentIdTokenRef.current;
          } else {
            console.log('No user is signed in, re-authenticating...');
            return currentIdTokenRef.current;
            // Handle re-authentication logic here, such as redirecting to login
          }
        });
        return currentIdTokenRef.current;
      }

      const now = Date.now();
      const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds

      if (now >= tokenExpiration || now >= tokenExpiration - bufferTime) {
        const newToken = await refreshAuthToken(); // Refresh the token
        return newToken;
      }

      return currentIdTokenRef.current; // Token is still valid
    };

    // Memoize the authLink
    const authLink = useMemo(
      () =>
        setContext(async (_, { headers }) => {
          const currentToken = await getFreshToken();
          return {
            headers: {
              ...headers,
              Authorization: `Bearer ${currentToken}`,
            },
          };
        }),
      [tokenExpiration], // Only change when `tokenExpiration` changes
    );

    // Memoize the error link
    const errorLink = useMemo(
      () =>
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              );
            });
          }
          if (networkError) {
            console.error(`[Network error]: ${networkError}`);
          }
        }),
      [], // Never changes
    );

    // Memoize the httpLink
    const httpLink = useMemo(
      () =>
        new HttpLink({
          uri: process.env.REACT_APP_CONFIG_URL_LOCKED,
          fetchOptions: { credentials: 'same-origin' },
        }),
      [],
    );

    // Initialize Apollo Client once and reuse it
    const client = useMemo(
      () => createApolloClient(authLink, errorLink, httpLink),
      [authLink],
    );

    // Show a loading animation if the user is still loading
    if (isLoading) {
      return <LoaderCentred />;
    }

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  },
);

export default AuthorizedApolloProvider;
