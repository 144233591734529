import { Box, Typography, styled } from '@mui/material';

// Define styled components with MUI v5
export const TitleH1 = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: 40,
  // marginTop: 20,
  // marginBottom: 10,
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    // marginTop: 10,
    fontSize: 30,
  },
}));

export const TitleH2 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 30,
  [theme.breakpoints.down('sm')]: {
    // marginBottom: 5,
    fontSize: 26,
    width: '85%',
  },
}));

export const TitleH3 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  color: '#14142B',
  [theme.breakpoints.down('sm')]: {
    fontSize: 21,
  },
}));

export const TitleH4 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  color: '#14142B',
  overflow: 'hidden', // Hide overflow content
  textOverflow: 'ellipsis', // Show ellipsis when content overflows
  whiteSpace: 'nowrap', // Prevent text from wrapping to a new line
  // maxWidth: '100%', // Ensure the component does not exceed the width of its container
  boxSizing: 'border-box', // Include padding and border in the element's total width and height
  flexGrow: 1,
  paddingRight: 1,
  [theme.breakpoints.down('lg')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 18,
    // maxWidth: '480px', // Adjust maxWidth for smaller screens if needed
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
    // maxWidth: '320px', // Adjust maxWidth for smaller screens if needed
  },
}));

export const TitleH5 = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  color: '#393745',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
    // marginBottom: 5,
  },
}));

export const Body1 = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  color: '#636363',
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const Body2 = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const Body2Centered = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  textAlign: 'center',
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  color: theme.palette.secondary.dark,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const HelpText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  color: '#636363',
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const BoldText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
}));
