import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector, setTripDescription } from '../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_UPDATE_USER_TRIP } from '../../gql-user/updateUserTrip';
import MyEditor from '../../MyEditor';
import {
  CenteredModal,
  LargeModalPaper,
  ModalDescription,
  ModalTitle,
} from '../../styling/modal';

import 'react-quill/dist/quill.snow.css';

export default function EditTripDescriptionModal(props: {
  description?: string;
  tripType?: string;
  editTripId: string;
  tripActions: boolean;
  handleCloseModal?: () => void;
}) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { tripDescription } = useSelector(tripsSelector);

  const [editTrip, { loading }] = useMutation(MUTATION_UPDATE_USER_TRIP, {
    variables: { description: tripDescription, trip_id: props.editTripId },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props.handleCloseModal !== undefined) {
      props.handleCloseModal();
    }
  };

  const handleEditorChange = (content: string) => {
    dispatch(setTripDescription(content));
  };

  return (
    <>
      {props.tripActions ? (
        <Box
          onClick={() => {
            gtag('event', 'edit-trip-description');
            handleOpen();
          }}
        >
          Edit description
        </Box>
      ) : (
        <>
          {!props.description && (
            <TextButton
              onClick={() => {
                gtag('event', 'add-trip-description');
                handleOpen();
              }}
            >
              {props.tripType && props.tripType === 'guide' ? (
                <>{'Add guide description'}</>
              ) : (
                <>{'Add trip description'}</>
              )}
            </TextButton>
          )}
        </>
      )}

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <LargeModalPaper>
          {props.description ? (
            <ModalTitle>Edit description</ModalTitle>
          ) : (
            <ModalTitle>Add description</ModalTitle>
          )}
          <ModalDescription>
            {props.tripType && props.tripType === 'guide' ? (
              <>
                {
                  'Roughly describe what is traveler going to experience on this guide.'
                }
              </>
            ) : (
              <>
                {
                  'Roughly describe what is traveler going to experience on this trip'
                }
              </>
            )}
          </ModalDescription>
          <MyEditor value={tripDescription} onChange={handleEditorChange} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              mt={2}
            >
              <Box>
                <SecondaryButton
                  disabled={loading}
                  onClick={async () => {
                    gtag('event', 'publish-trip-close');
                    setOpen(false);
                    handleClose();
                  }}
                  sx={{ marginRight: 1 }}
                >
                  Close
                </SecondaryButton>
                <PrimaryButton
                  onClick={async () => {
                    gtag('event', 'edit-trip-description-save');
                    try {
                      await editTrip();
                      handleClose();
                    } catch (e) {
                      gtag('event', 'error-edit-trip-description-save');
                      dispatch(setErrorTitle('Error saving description'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {loading ? <Loader /> : 'Save description'}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>
        </LargeModalPaper>
      </CenteredModal>
    </>
  );
}
