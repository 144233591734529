import React, { useState } from 'react';
import { Box, Stack, IconButton, Drawer, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import { TripMap, TripMapProps } from './TripMap';

export function MapDrawer({
  tripType,
  onMarkerClick,
  onCloseClick,
}: TripMapProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(prev => !prev);
  };

  return (
    <div>
      {/* Sticky Toggle Button */}
      <Box
        sx={theme => ({
          position: 'fixed',
          top: '60px',
          padding: 0.5,
          zIndex: 5000,
          right: '0px', // Touch the right edge
          display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          flexDirection: 'column', // Align icons and titles vertically
          backgroundColor: 'white', // Set background color using theme
          borderTopLeftRadius: '8px', // Rounded top left corner
          borderBottomLeftRadius: '8px', // Rounded bottom left corner
        })}
      >
        <IconButton
          onClick={toggleDrawer}
          sx={theme => ({
            backgroundColor: theme.palette.background.paper, // Using theme color for background
            display: { xs: 'flex', sm: 'none' }, // Show only on small screens
          })}
        >
          {isDrawerOpen ? (
            <Stack direction="row" spacing={1}>
              <ListIcon />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                })}
              >
                Itinerary
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row" spacing={1}>
              <MapIcon />
              <Typography
                sx={theme => ({
                  fontWeight: 600,
                })}
              >
                Map
              </Typography>
            </Stack>
          )}
        </IconButton>
      </Box>

      {/* Drawer Component */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{ width: '100%' }} // Set the width of the drawer
      >
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Placeholder for Mapbox */}
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: '#e0e0e0', // Light gray background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            <TripMap
              tripType={tripType}
              onMarkerClick={onMarkerClick}
              onCloseClick={onCloseClick}
              openOnMobile={isDrawerOpen}
            />
          </div>
        </Box>
      </Drawer>
    </div>
  );
}

export default MapDrawer;
