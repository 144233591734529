import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Button, ButtonProps, ButtonTypeMap, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

const EditLinkButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 10,
  cursor: 'pointer',
  borderRadius: 20,
  backgroundColor: '#F5F5F5',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#eaeaea',
  },
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

// const TextLinkButton = styled(Button)(({ theme }) => ({
//   alignSelf: 'center',
//   fontWeight: 500,
//   fontSize: 16,
//   paddingTop: 5,
//   paddingBottom: 5,
//   paddingRight: 10,
//   paddingLeft: 0,
//   cursor: 'pointer',
//   borderRadius: 20,
//   backgroundColor: 'none',
//   '&:hover': {
//     backgroundColor: 'transparent',
//   },
//   color: theme.palette.primary.main,
//   [theme.breakpoints.down('sm')]: {
//     fontSize: 14,
//   },
// }));
interface TextLinkButtonProps extends ButtonProps {
  customColor?: string; // Use a different name for custom color
}

const TextLinkButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'customColor', // Prevent customColor prop from being forwarded to the DOM
})<TextLinkButtonProps>(({ theme, customColor }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 0,
  cursor: 'pointer',
  borderRadius: 20,
  backgroundColor: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  color: customColor || theme.palette.primary.main, // Use custom color if provided
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const DeleteTextButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 10,
  paddingLeft: 0,
  cursor: 'pointer',
  color: theme.palette.error.main,
  borderRadius: 20,
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const ProminentButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#000000',
  backgroundColor: '#F4B740',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F6AF24',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

const ProminentButtonThinStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 16,
  paddingTop: 5,
  paddingBottom: 5,
  paddingRight: 20,
  paddingLeft: 20,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#000000',
  backgroundColor: '#F4B740',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F6AF24',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

const ProminentButtonWideStyled = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#000000',
  backgroundColor: '#F4B740',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#F6AF24',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const SecondaryButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#4E4B66',
  backgroundColor: '#D6D9FC',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#B5B6FA',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const PrimaryButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#FFFFFF',
  backgroundColor: '#676BEE',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#4447B1',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const LightButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  minWidth: 100,
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  borderRadius: 40,
  color: '#6529B1',
  backgroundColor: '#DEE0FC',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#B5B6FA',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const PrimaryButtonWideStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  width: 200,
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 7,
  paddingBottom: 7,
  paddingRight: 25,
  paddingLeft: 25,
  cursor: 'pointer',
  borderRadius: 40,
  color: '#FFFFFF',
  backgroundColor: '#676BEE',
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#4447B1',
  },
  '&:disabled': {
    backgroundColor: '#D6D9FC',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

// Export the components with the original constant names
export const LinkButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <EditLinkButton {...props} />;

// export const TextButton = <
//   D extends React.ElementType = ButtonTypeMap['defaultComponent'],
//   P = Record<string, unknown>,
// >(
//   props: ButtonProps<D, P>,
// ) => <TextLinkButton {...props} />;

export const TextButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>({
  customColor, // Use customColor instead of color
  ...props
}: ButtonProps<D, P> & { customColor?: string }) => {
  return <TextLinkButton customColor={customColor} {...props} />;
};

export const DeleteTextButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <DeleteTextButtonStyled {...props} />;

export const ProminentButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <ProminentButtonStyled {...props} />;

export const ProminentButtonThin = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <ProminentButtonThinStyled {...props} />;

export const ProminentButtonWide = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <ProminentButtonWideStyled {...props} />;

export const SecondaryButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <SecondaryButtonStyled {...props} />;

export const PrimaryButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <PrimaryButtonStyled {...props} />;

export const LightButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <LightButtonStyled {...props} />;

export const PrimaryButtonWide = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => <PrimaryButtonWideStyled {...props} />;

const GetTheAppButtonStyled = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 600,
  fontSize: 18,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  cursor: 'pointer',
  borderRadius: 30,
  color: '#FFFFFF', // White text for contrast
  backgroundColor: '#FF6F61', // Coral background
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Add a subtle shadow
  transition: 'background-color 0.3s ease', // Smooth hover transition
  '&:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  '&:hover': {
    backgroundColor: '#FF4D3E', // Darker Coral on hover
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 16, // Slightly smaller font on small screens
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
  },
}));

export const GetTheAppButton = <
  D extends React.ElementType = ButtonTypeMap['defaultComponent'],
  P = Record<string, unknown>,
>(
  props: ButtonProps<D, P>,
) => {
  const [platform, setPlatform] = useState<'ios' | 'android' | null>(null);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Check if it's iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setPlatform('ios');
    }
    // Check if it's Android
    else if (/android/i.test(userAgent)) {
      setPlatform('android');
    }
  }, []);

  return (
    <GetTheAppButtonStyled {...props}>
      {/* Conditionally render icon based on platform */}
      {platform === 'ios' && <AppleIcon style={{ marginRight: 8 }} />}
      {platform === 'android' && <AndroidIcon style={{ marginRight: 8 }} />}
      Get The App
    </GetTheAppButtonStyled>
  );
};
