import { Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import { accommodationsSelector } from '../../../../store/AccommodationSlice';
import { setAddStepModalOpen } from '../../../../store/StepSlice';
import { tripsSelector } from '../../../../store/TripSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../theme-components/Buttons';
import { VerticalFlex } from '../../../helpers/flex';
import UploadImage from '../../../Upload';

export const AddPhotosToAccommodation: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const dispatch = useDispatch();

  const { accommodationId } = useSelector(accommodationsSelector);
  const { trip } = useSelector(tripsSelector);

  return (
    <>
      <VerticalFlex>
        <UploadImage
          tripId={trip ? trip.id : ''}
          accommodationId={accommodationId}
          main={true}
        />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            justifyContent: 'flex-end', // Aligns items to the right
          }}
        >
          <VerticalFlex>
            <Box display="flex">
              <Box mr={2}>
                <SecondaryButton
                  onClick={e => {
                    gtag('event', 'add-accommodation-photos-add-later');
                    dispatch(setAddStepModalOpen(false));
                    handleCloseModal();
                  }}
                >
                  Add later
                </SecondaryButton>
              </Box>

              <PrimaryButton
                onClick={() => {
                  gtag('event', 'add-accommodation-photos-finish');
                  dispatch(setAddStepModalOpen(false));
                  handleCloseModal();
                }}
              >
                Finish
              </PrimaryButton>
            </Box>
          </VerticalFlex>
        </Box>
      </VerticalFlex>
    </>
  );
};
