import { gql } from 'graphql-tag';

export const MUTATION_COPY_STEP_TO_DAY = gql`
  mutation copyStepToDay(
    $original_step_id: String!
    $new_day_id: String!
    $new_step_number: Int!
  ) {
    copyStepToDay(
      original_step_id: $original_step_id
      new_day_id: $new_day_id
      new_step_number: $new_step_number
    ) {
      id
      days {
        id
        dayNumber
        cities {
          cityName
        }
        steps {
          id
          title
          type
          description
          stepNumber
          latitude
          longitude
          locationUrl
          duration
          url
          city
          country
          countryCode
          region
          images {
            id
            path
            header
            main
            secondary
            tertiary
          }
          placeImages {
            path
            id
            title
            contributorId
            imageUrl
          }
          place {
            id
            lat
            lng
            city
            country
            countryCode
            region
            externalPlaceId
            title
            category
            noOfReviews
            rating
            description
            quickFact
            phoneNumber
            website
            address
            openingHours {
              weekDay
              hours
            }
            images {
              title
              path
              contributorId
              imageUrl
            }
          }
          accommodations {
            id
            name
            description
            url
            budget
            latitude
            longitude
            locationUrl
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
          }
          recommendations {
            id
            name
            type
            description
            url
            latitude
            longitude
            locationUrl
          }
        }
      }
      coordinates {
        id
        title
        latitude
        longitude
        step {
          id
          title
          type
          description
          stepNumber
          latitude
          longitude
          locationUrl
          duration
          url
          city
          country
          countryCode
          region
          images {
            id
            path
            header
            main
            secondary
            tertiary
          }
          placeImages {
            path
            id
            title
            contributorId
            imageUrl
          }
          place {
            id
            lat
            lng
            city
            country
            countryCode
            region
            externalPlaceId
            title
            category
            noOfReviews
            rating
            description
            quickFact
            phoneNumber
            website
            address
            openingHours {
              weekDay
              hours
            }
            images {
              title
              path
              contributorId
              imageUrl
            }
          }
          accommodations {
            id
            name
            description
            url
            budget
            latitude
            longitude
            locationUrl
            images {
              id
              path
              header
              main
              secondary
              tertiary
            }
          }
          recommendations {
            id
            name
            type
            description
            url
            latitude
            longitude
            locationUrl
          }
        }
      }
    }
  }
`;
