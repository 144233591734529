import { gql } from 'graphql-tag';

export const QUERY_TRIPS = gql`
  query tripsList {
    tripsList {
      id
      name
      tripType
      length
      images {
        id
        path
        header
        main
        secondary
        tertiary
      }
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        countryName
        itineraryId
      }
    }
  }
`;
