import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { Header } from '../components/AppWrapper';
import { useAuth } from '../components/auth/firebase';
import { Profile } from '../components/auth/Profile';
import SignUp from '../components/auth/SignUp';
import { VerticalFlex } from '../components/helpers/flex';
import LoginIllustration from '../components/illustrations/Login';
import { isRunningStandalone } from '../utils/helpers';
import { RoutePage } from '../components/route';
import { LoaderFullScreen } from '../theme-components/Loader';

export default function MyAccountRoute() {
  gtag('event', 'user-account-page');
  const { isAuthenticated, isLoading } = useAuth();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  return (
    <RoutePage>
      <Box width="100%">
        {!isAuthenticated ? (
          <Box
            sx={theme => ({
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
              justifyContent: 'space-around',
              alignSelf: 'center',
              alignItems: 'center',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
              },
            })}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{
                marginTop: 1,
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                height="100vh"
              >
                <Stack
                  direction="column"
                  height="80vh"
                  display="flex"
                  justifyContent="center"
                  width="100%"
                >
                  <Box
                    sx={{
                      width: 250,
                      height: 200,
                      display: 'flex',
                      paddingRight: '30px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: 2,
                      flexDirection: 'row',
                      alignSelf: 'center',
                    }}
                  >
                    <LoginIllustration
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                  <SignUp />
                </Stack>
              </Box>
            </Box>
          </Box>
        ) : (
          <AuthorizedApolloProvider>
            <Stack direction="column" width="100%">
              <Box display="flex" flexGrow={1} mt={1}>
                <Header>Your traveler profile</Header>
              </Box>
              <Profile />
            </Stack>
          </AuthorizedApolloProvider>
        )}
      </Box>
      {isStandalone && (
        <Box
          sx={{ paddingBottom: 'calc(70px + env(safe-area-inset-bottom))' }}
        />
      )}
    </RoutePage>
  );
}
