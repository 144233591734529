import { Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { ReactNode } from 'react';

interface ChildProps {
  readonly children?: ReactNode;
}

export function RoutePage(props: ChildProps) {
  return (
    <Box
      sx={theme => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 2,
        paddingRight: 12,
        paddingLeft: 12,
        [theme.breakpoints.down('lg')]: {
          paddingRight: 5,
          paddingLeft: 5,
          // marginTop: 0,
        },
        [theme.breakpoints.down('md')]: {
          paddingRight: 1,
          paddingLeft: 1,
          marginTop: 0,
        },
      })}
    >
      {props.children}
    </Box>
  );
}

export function RouteTitle(props: ChildProps) {
  return (
    <Typography variant="h4" component="h1" gutterBottom>
      {props.children}
    </Typography>
  );
}

export function RouteContent(props: ChildProps) {
  return <Box>{props.children}</Box>;
}
