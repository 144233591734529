import { Box, Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { returnFormatedDate } from '../../utils/helpers';

import { TripDay } from './TripDay';

type TripType = 'public' | 'user' | 'preview';

type TripItineraryProps = {
  tripType: TripType;
  startDate?: Date;
  selectedStepId?;
  stepRefs?;
};

export function TripItinerary({
  tripType,
  startDate,
  selectedStepId,
  stepRefs,
}: TripItineraryProps) {
  const { publicTrip } = useSelector(publicTripsSelector);
  const { trip: userTrip } = useSelector(tripsSelector);

  const trip = tripType === 'user' ? userTrip : publicTrip;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {trip && trip.itinerary && (
        <Stack
          direction="row"
          alignItems="left"
          sx={theme => ({
            width: '100%',
          })}
        >
          <Box width="100%" mt={1} mb={3}>
            {trip.itinerary.days &&
              trip.itinerary.days.length > 0 &&
              trip.itinerary.days.map((day, i) => {
                return (
                  <React.Fragment key={i}>
                    {day && (
                      <>
                        {startDate ? (
                          <TripDay
                            key={i + day.id}
                            day={day}
                            date={returnFormatedDate(startDate, i)}
                            selectedStepId={selectedStepId}
                            stepRefs={stepRefs}
                          />
                        ) : (
                          <TripDay
                            key={i + day.id}
                            day={day}
                            selectedStepId={selectedStepId}
                            stepRefs={stepRefs}
                          />
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </Box>
        </Stack>
      )}
    </Box>
  );
}
