import DirectionsIcon from '@mui/icons-material/Directions';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import InfoIcon from '@mui/icons-material/Info';
import MapIcon from '@mui/icons-material/Map';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Typography,
  Stack,
  Chip,
  Link,
  SvgIconProps,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import {
  Maybe,
  Place,
  Step,
  Image,
  StepPlaceImage,
} from '../../generated/public_graphql';
import {
  setOpenHoursModal,
  setPlaceIdForOpenHours,
} from '../../store/StepSlice';
import { Body1, TitleH4 } from '../../theme-components/Typography';
import GoogleIcon from '../icons/GoogleIcon';
import { RenderHtmlFromResponseNoMargin } from '../RenderHtmlFromResponse';
import {
  ExternalLinkProminent,
  ExternalLinkRegular,
} from '../trip-details/TripDay';

import { AddToTripButton } from './AddToTrip';
import { PlaceMoreInfoModal, TodaysHours } from './Hours';
import {
  StepPlaceImages,
  StepPlaceSingleImage,
  UserStepImages,
  UserStepSingleImage,
} from './StepImages';
import { StepRecommendations } from './StepRecommendation';
import { formatNumber, getMapUrls } from '../../utils/helpers';

interface PlaceCardProps {
  step: Step;
  dayId: string;
  selected?: boolean;
}

export const PublicPlaceCard: React.FC<PlaceCardProps> = ({
  step,
  dayId,
  selected,
}) => {
  const dispatch = useDispatch();
  let place: Place | null = null;
  console.log('selected', selected);

  if (step && step.place) {
    place = step.place;
  }
  if (!step) {
    return <></>;
  }

  let customAccomodationImages: Maybe<Image>[] = [];
  if (
    step.accommodations &&
    step.accommodations[0] &&
    step.accommodations[0].images &&
    step.accommodations[0].images.length > 0
  ) {
    customAccomodationImages = step.accommodations[0].images;
  }

  let customStepImages: Maybe<Image>[] = [];
  if (step.images && step.images.length > 0) {
    customStepImages = step.images;
  }

  let placeImages: Maybe<StepPlaceImage>[] = [];
  if (step && step.placeImages && step.placeImages.length > 0) {
    placeImages = step.placeImages;
  }

  // //externalPlaceId
  // let takeMeThereUrl: string | null = null;
  // let openGoogleMapsUrl: string | null = null;

  // if (step.place && step.place.address && step.place.title) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     encodeURIComponent(step.place.title) +
  //     '+' +
  //     encodeURIComponent(step.place.address);
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by place name and coordinates
  // } else if (step.place && step.place.lat && step.place.lng && step.place.title)
  //   if (step.place && step.place.externalPlaceId) {
  //     // Show place by place ID
  //     takeMeThereUrl =
  //       'https://www.google.com/maps/place/?q=place_id:' +
  //       step.place.externalPlaceId;
  //     openGoogleMapsUrl = takeMeThereUrl;

  //     // Show place by place name and address
  //   } // Show place by place ID
  //   else if (step.place && step.place.externalPlaceId) {
  //     takeMeThereUrl =
  //       'https://www.google.com/maps/place/?q=place_id:' +
  //       step.place.externalPlaceId;
  //     openGoogleMapsUrl = takeMeThereUrl;

  //     // Show place by place name and address
  //   } else {
  //     takeMeThereUrl =
  //       'https://www.google.com/maps/search/?api=1&query=' +
  //       encodeURIComponent(step.place.title) +
  //       '+' +
  //       step.place.lat +
  //       '%2C' +
  //       step.place.lng;
  //     openGoogleMapsUrl = takeMeThereUrl;

  //     // Show place by name only
  //   }
  // else if (step.place && step.place.title) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     encodeURIComponent(step.place.title);
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by coordinates only
  // } else if (step.place && step.place.lat && step.place.lng) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     step.place.lat +
  //     '%2C' +
  //     step.place.lng;
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by step coordinates only (user can enter those)
  // } else if (step.latitude && step.longitude) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     step.latitude +
  //     '%2C' +
  //     step.longitude;
  // }

  // // Show place by place ID
  // if (step.place && step.place.externalPlaceId) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/place/?q=place_id:' +
  //     step.place.externalPlaceId;
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by place name and address
  // } else if (step.place && step.place.address && step.place.title) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     encodeURIComponent(step.place.title) +
  //     '+' +
  //     encodeURIComponent(step.place.address);
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by place name and coordinates
  // } else if (
  //   step.place &&
  //   step.place.lat &&
  //   step.place.lng &&
  //   step.place.title
  // ) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     encodeURIComponent(step.place.title) +
  //     '+' +
  //     step.place.lat +
  //     '%2C' +
  //     step.place.lng;
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by name only
  // } else if (step.place && step.place.title) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     encodeURIComponent(step.place.title);
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by coordinates only
  // } else if (step.place && step.place.lat && step.place.lng) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     step.place.lat +
  //     '%2C' +
  //     step.place.lng;
  //   openGoogleMapsUrl = takeMeThereUrl;

  //   // Show place by step coordinates only (user can enter those)
  // } else if (step.latitude && step.longitude) {
  //   takeMeThereUrl =
  //     'https://www.google.com/maps/search/?api=1&query=' +
  //     step.latitude +
  //     '%2C' +
  //     step.longitude;
  // }

  const { takeMeThereUrl, openGoogleMapsUrl } = getMapUrls(step);

  let moreInfoForPlace = false;
  if (
    step.place &&
    ((step.place.openingHours && step.place.openingHours.length > 0) ||
      step.place.website ||
      step.place.address ||
      step.place.phoneNumber)
  ) {
    moreInfoForPlace = true;
  }

  return (
    <Stack
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        paddingTop: 1,
        paddingBottom: 1,
        borderRadius: '10px',
        backgroundColor: selected ? '#f1eaff' : 'transparent',
        marginBottom: 2,
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          marginBottom: 1,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            maxWidth: '99%',
          },
        })}
      >
        <Box
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 1,
            alignSelf: 'center',
            [theme.breakpoints.down('sm')]: {
              marginRight: 0.5,
            },
          })}
        >
          {selected ? (
            <PlaceTwoToneIcon
              sx={theme => ({
                width: 30,
                height: 30,
                marginRight: '-5px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                  width: 25,
                  height: 25,
                },
              })}
            />
          ) : (
            <PlaceTwoToneIcon
              sx={theme => ({
                width: 25,
                height: 25,
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  width: 20,
                  height: 20,
                },
              })}
            />
          )}
        </Box>
        {step.title && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              maxWidth: '100%',
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <TitleH4>{step.title}</TitleH4>
            <AuthorizedApolloProvider>
              <Box sx={{ marginRight: selected ? 1 : 0 }}>
                <AddToTripButton stepId={step.id} dayId={dayId} />
              </Box>
            </AuthorizedApolloProvider>
          </Stack>
        )}
      </Stack>

      <Box
        sx={theme => ({
          paddingLeft: 4,

          [theme.breakpoints.down('sm')]: {
            paddingLeft: 3,
          },
        })}
      >
        {place && (place.rating || place.noOfReviews) && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{ mb: 0.5 }}
          >
            <Box
              sx={theme => ({
                width: 25,
                height: 25,
                marginRight: 10,
                color: '#AB88F3',
                display: 'flex', // Enable flexbox layout
                alignItems: 'center', // Vertically center content
                justifyContent: 'center', // Horizontally center content
                [theme.breakpoints.down('sm')]: {
                  width: 20,
                  height: 20,
                },
              })}
            >
              <GoogleIcon />
            </Box>

            {place.rating && (
              <>
                <Typography
                  sx={{ fontWeight: 600, color: '#E6B800', fontSize: 18 }}
                >
                  {formatNumber(place.rating)}
                </Typography>
                <StarIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    marginRight: 10,
                    color: '#E6B800',
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
              </>
            )}
            {place.noOfReviews && (
              <Typography variant="body1">
                ({place.rating ? place.noOfReviews : 'No reviews'})
              </Typography>
            )}
          </Stack>
        )}
        {/* Operating Hours */}
        {place && place.openingHours && place.openingHours.length > 0 && (
          <TodaysHours place={place} />
        )}
        {step.accommodations &&
          step.accommodations[0] &&
          step.accommodations[0].url && (
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.5}
              sx={{ mb: 0.5 }}
            >
              <EventTwoToneIcon
                sx={theme => ({
                  width: 25,
                  height: 25,
                  marginRight: 10,
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    width: 20,
                    height: 20,
                  },
                })}
              />
              <ExternalLinkProminent
                url={step.accommodations[0].url}
                copy={'Check availability'}
              />
            </Stack>
          )}
        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mt: 1 }}>
          {takeMeThereUrl && (
            <Link
              href={takeMeThereUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                gtag('event', 'public-link-navigate-click');
              }}
            >
              <ResponsiveChip
                mobileCopy="Navigate"
                desktopCopy="Navigate"
                icon={DirectionsIcon}
              />
            </Link>
          )}
          {openGoogleMapsUrl && (
            <Link
              href={openGoogleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                gtag('event', 'public-link-maps-url');
              }}
            >
              <ResponsiveChip
                mobileCopy="Maps"
                desktopCopy="Google Maps"
                icon={MapIcon}
              />
            </Link>
          )}
          {moreInfoForPlace && (
            <Link
              onClick={() => {
                gtag('event', 'public-place-more-info-click');
                dispatch(setOpenHoursModal(true));
                dispatch(setPlaceIdForOpenHours(step.place!.id));
              }}
            >
              <ResponsiveChip
                mobileCopy="More"
                desktopCopy="More info"
                icon={InfoIcon}
              />
            </Link>
          )}
        </Stack>
        {step.description &&
        !(
          step.accommodations &&
          step.accommodations[0] &&
          step.accommodations[0].description
        ) ? (
          <Box sx={{ mt: 2 }}>
            {/* <Divider /> */}
            <Body1>
              <RenderHtmlFromResponseNoMargin content={step.description} />
            </Body1>
          </Box>
        ) : (
          step.accommodations &&
          step.accommodations[0] &&
          step.accommodations[0].description && (
            <Box sx={{ mt: 2 }}>
              {/* <Divider /> */}
              <Body1>
                <RenderHtmlFromResponseNoMargin
                  content={step.accommodations[0].description}
                />
              </Body1>
            </Box>
          )
        )}
        {step.url && <ExternalLinkRegular url={step.url} copy={'Read more'} />}
        <Box sx={{ paddingRight: 1 }}>
          {customAccomodationImages && customAccomodationImages.length > 0 && (
            <>
              {customAccomodationImages.length > 1 ? (
                <UserStepImages images={customAccomodationImages} />
              ) : (
                <UserStepSingleImage images={customAccomodationImages} />
              )}
            </>
          )}
          {customStepImages.length > 0 &&
            customAccomodationImages.length === 0 && (
              <>
                {customStepImages.length > 1 ? (
                  <UserStepImages images={customStepImages} />
                ) : (
                  <UserStepSingleImage images={customStepImages} />
                )}
              </>
            )}
          {customStepImages.length === 0 &&
            customAccomodationImages.length === 0 &&
            placeImages.length > 0 && (
              <>
                {placeImages.length > 1 ? (
                  <StepPlaceImages images={placeImages} />
                ) : (
                  <StepPlaceSingleImage images={placeImages} />
                )}
              </>
            )}
          {step.recommendations && step.recommendations.length > 0 && (
            <StepRecommendations recommentations={step.recommendations} />
          )}
          {step.place && <PlaceMoreInfoModal place={step.place} />}
          {step.recommendations && step.recommendations.length > 0 && (
            <StepRecommendations recommentations={step.recommendations} />
          )}
        </Box>
      </Box>
    </Stack>
  );
};

export default PublicPlaceCard;

interface ResponsiveChipProps {
  mobileCopy: string;
  desktopCopy: string;
  icon?: React.ComponentType<SvgIconProps>; // Icon component type
}

export const ResponsiveChip: React.FC<ResponsiveChipProps> = ({
  mobileCopy,
  desktopCopy,
  icon: IconComponent = InfoIcon,
}) => {
  return (
    <Chip
      label={
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconComponent
            sx={theme => ({
              width: 20,
              height: 20,
              transition: 'color 0.3s ease',
              color: '#6E7191',
              [theme.breakpoints.down('sm')]: {
                width: 18,
                height: 18,
              },
            })}
          />

          <Box
            sx={theme => ({
              display: 'block',
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            {mobileCopy}
          </Box>
          <Box
            sx={theme => ({
              display: 'block',
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            })}
          >
            {desktopCopy}
          </Box>
        </Stack>
      }
      variant="outlined"
      clickable
      sx={theme => ({
        fontWeight: 500,
        textDecoration: 'none',
        backgroundColor: '#F5F5F5',
        borderColor: '#E0E0E0',
        borderRadius: '16px',
        padding: '8px 10px',
        boxShadow: 'none',
        fontSize: 14,
        color: '#8A8D9C',
        transition: 'background-color 0.3s ease, border-color 0.3s ease',
        '&:hover': {
          backgroundColor: '#E0E0E0 !important',
          borderColor: '#B0B0B0',
          textDecoration: 'none',
          color: '#6E7191',
        },
        [theme.breakpoints.down('sm')]: {
          padding: '6px 8px',
        },
      })}
    />
  );
};
