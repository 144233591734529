import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { TitleH3 } from '../../../theme-components/Typography';
import { VerticalFlex, HorizontalFlex } from '../../helpers/flex';
import { DayStepDetails } from '../edit-trip/DayStepDetails';

import { DragabbleDaySteps } from './DraggableDayStep';
import {
  Clone,
  ContainedHandle,
  Container,
  ContainerDay,
  CreateTripStepTitle,
  Handle,
  Item,
} from './Styles';

export function DraggableItineraryDays(props: { existingTripDays }) {
  const [collapsedSteps, setCollapsedSteps] = useState(false);

  return (
    <>
      <Box
        sx={theme => ({ alignSelf: 'flex-end', marginRight: 15 })}
        onClick={() => setCollapsedSteps(!collapsedSteps)}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={collapsedSteps}
                onChange={() => setCollapsedSteps(!collapsedSteps)}
              />
            }
            labelPlacement="start"
            // label={collapsedSteps ? 'Show details' : 'Hide details'}
            label="Reorder itinerary"
          />
        </FormGroup>
      </Box>
      <Droppable
        droppableId="existingDays"
        type="itineraryDay"
        isDropDisabled={true}
      >
        {(provided, snapshot) => (
          <>
            <ContainerDay
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {props.existingTripDays.map((day, index) => (
                <Box key={index}>
                  <Draggable
                    key={day.id.replace('_temp', '')}
                    draggableId={day.id.replace('_temp', '')}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          $isDragging={snapshot.isDragging}
                          style={provided.draggableProps.style}
                        >
                          <Stack width="100%" direction="column">
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center" // Optional: Aligns items vertically at the center
                              sx={{
                                width: '100%', // Optional: Ensures the Stack takes up the full width
                              }}
                            >
                              <ContainedHandle {...provided.dragHandleProps}>
                                <DragIndicatorIcon
                                  sx={theme => ({
                                    width: 25,
                                    height: 25,
                                  })}
                                />
                                <TodayTwoToneIcon
                                  sx={theme => ({
                                    marginLeft: 0,
                                    marginRight: 1,
                                    width: 25,
                                    height: 25,
                                    alignSelf: 'center',
                                    color: theme.palette.icon.main,
                                    [theme.breakpoints.down('sm')]: {
                                      marginLeft: 0,
                                    },
                                  })}
                                />
                                <TitleH3>{'Day ' + day.dayNumber}</TitleH3>
                              </ContainedHandle>
                            </Stack>
                            <Stack
                              direction="row"
                              sx={theme => ({
                                marginTop: 1,
                                paddingLeft: 7.5,
                                [theme.breakpoints.down('sm')]: {
                                  paddingLeft: 7,
                                  // paddingLeft: '40px',
                                },
                              })}
                            >
                              {day && day.cities && day.cities.length > 0 && (
                                <MapsHomeWorkTwoToneIcon
                                  sx={theme => ({
                                    marginRight: 1,
                                    width: 25,
                                    height: 25,
                                    alignSelf: 'center',
                                    color: theme.palette.icon.main,
                                  })}
                                />
                              )}
                              <Typography
                                sx={theme => ({
                                  fontWeight: 500,
                                  fontSize: 18,
                                  marginTop: theme.spacing(0.5),
                                  color: '#4E4B66',
                                  [theme.breakpoints.down('sm')]: {
                                    fontSize: 16,
                                  },
                                })}
                              >
                                {day &&
                                  day.cities &&
                                  day.cities.length > 0 &&
                                  day.cities.map((city, i) => (
                                    <React.Fragment key={i}>
                                      {city && (
                                        <>
                                          {city.cityName}
                                          {i !== day.cities!.length - 1 && (
                                            <>{', '}</>
                                          )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Typography>
                            </Stack>
                            <Box mt={1}>
                              <DragabbleDaySteps
                                day={day}
                                collapsedSteps={collapsedSteps}
                              />
                            </Box>
                          </Stack>
                        </Item>
                        {snapshot.isDragging && (
                          <DayClone day={day} collapsedSteps={collapsedSteps} />
                        )}
                      </>
                    )}
                  </Draggable>
                </Box>
              ))}
            </ContainerDay>
          </>
        )}
      </Droppable>
    </>
  );
}

function DayClone(props: { day; collapsedSteps: boolean }) {
  return (
    <Clone>
      <Item>
        <VerticalFlex>
          <HorizontalFlex>
            <Handle>
              <DragIndicatorIcon />
            </Handle>
            <TitleH3>{'Day ' + props.day.dayNumber}</TitleH3>
          </HorizontalFlex>
          {props.day.steps &&
            props.day.steps.map((step, index) => (
              <Box key={index}>
                {step && (
                  <Item key={index}>
                    <Container>
                      <VerticalFlex>
                        <HorizontalFlex>
                          <Handle>
                            {/* <DragIndicatorIcon /> */}

                            <CreateTripStepTitle step={step} showEdit={false} />
                          </Handle>
                        </HorizontalFlex>
                        {!props.collapsedSteps && (
                          <DayStepDetails step={step} />
                        )}
                      </VerticalFlex>
                    </Container>
                  </Item>
                )}
              </Box>
            ))}
        </VerticalFlex>
      </Item>
    </Clone>
  );
}
