import { useMutation } from '@apollo/client';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import {
  Box,
  FormControlLabel,
  Switch,
  FormGroup,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { daysSelector, setDays } from '../../../store/DaySlice';
import { tripsSelector } from '../../../store/TripSlice';
import { Loader } from '../../../theme-components/Loader';
import { TitleH3 } from '../../../theme-components/Typography';
import {
  isRunningStandalone,
  returnFormatedDate,
} from '../../../utils/helpers';
import { MUTATION_ADD_DAY_TO_ITINERARY } from '../../gql-user/addDayToItinerary';
import { ActionsDay } from '../edit-trip/ActionsDay';

import { DraggableDroppableSteps } from './DraggableDroppableSteps';
import { ContainedHandle, ContainerDay, Item } from './Styles';
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone';

export function DraggableDroppableDays(props: { showEmptyDay: boolean }) {
  const dispatch = useDispatch();
  const [collapsedSteps, setCollapsedSteps] = useState(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  const { days, dayLoadingIndex, stepLoadingIndex } = useSelector(daysSelector);
  const { trip } = useSelector(tripsSelector);

  const [addDay, { loading: loadingNewDay, error: errorNewDay }] = useMutation(
    MUTATION_ADD_DAY_TO_ITINERARY,
  );

  return (
    <>
      <Box
        sx={theme => ({
          position: 'fixed', // Sticks to a fixed position
          bottom: isStandalone
            ? 'calc(100px + env(safe-area-inset-bottom))'
            : '16px', // Distance from the bottom of the screen
          left: '50%', // Center horizontally relative to the viewport
          transform: 'translateX(-50%)', // Offset to truly center the element
          // width: 60, // Adjust the size of the circular element
          // height: 60,
          borderRadius: '20px', // Make the element circular
          backgroundColor: theme.palette.grey[900], // MUI primary color (or use any custom color)
          // color: 'white',
          // fontWeight: 900,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 3,
          paddingLeft: 1,
          paddingY: 1,
          // zIndex: 100,
          zIndex: 100000,
          [theme.breakpoints.down('sm')]: {
            zIndex: 1000,
          },
        })}
      >
        <Box
          onClick={() => {
            gtag('event', 'collapse-details');
            setCollapsedSteps(!collapsedSteps);
          }}
        >
          <FormGroup>
            <FormControlLabel
              onClick={() => {
                gtag('event', 'collapse-details');
                setCollapsedSteps(!collapsedSteps);
              }}
              control={
                <Switch
                  size="small"
                  checked={collapsedSteps}
                  onChange={() => setCollapsedSteps(!collapsedSteps)}
                  sx={{
                    '& .MuiSwitch-switchBase': {
                      color: 'white', // Handle color when unchecked
                    },
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'primary.main', // Handle color when checked
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: 'white', // Track color when unchecked
                    },
                    '& .MuiSwitch-track.Mui-checked': {
                      backgroundColor: 'primary.main', // Track color when checked
                    },
                  }}
                />
              }
              labelPlacement="start"
              label={
                <Typography
                  onClick={() => {
                    gtag('event', 'collapse-details');
                    setCollapsedSteps(!collapsedSteps);
                  }}
                  sx={{ fontWeight: 700, color: 'white' }}
                >
                  {/* {collapsedSteps ? 'Show details' : 'Hide details'} */}
                  Reorder itinerary
                </Typography>
              }
            />
          </FormGroup>
        </Box>
      </Box>
      <Droppable type="itineraryDay" droppableId="itinerary">
        {(provided, snapshot) => (
          <ContainerDay
            ref={provided.innerRef}
            $isDraggingOver={snapshot.isDraggingOver}
          >
            {days &&
              days.map((day, dayIndex) => (
                <Box
                  key={dayIndex}
                  sx={theme => ({
                    [theme.breakpoints.down('sm')]: {
                      marginLeft: '-5px',
                    },
                  })}
                >
                  {day && (
                    <Draggable
                      key={day.id}
                      draggableId={day.id}
                      index={dayIndex}
                    >
                      {(provided, snapshot) => (
                        <>
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            $isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                          >
                            <Stack width="100%" direction="column">
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                  width: '100%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                }}
                              >
                                <Box>
                                  {dayLoadingIndex === dayIndex &&
                                  stepLoadingIndex === -1 ? (
                                    <ContainedHandle
                                      {...provided.dragHandleProps}
                                    >
                                      <Loader size={20} />
                                    </ContainedHandle>
                                  ) : (
                                    <ContainedHandle
                                      {...provided.dragHandleProps}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          position: 'relative',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {trip &&
                                        (!trip.tripType ||
                                          trip.tripType !== 'guide') ? (
                                          <>
                                            <DragIndicatorIcon
                                              sx={theme => ({
                                                width: 25,
                                                height: 25,
                                              })}
                                            />
                                            <TodayTwoToneIcon
                                              sx={theme => ({
                                                marginLeft: 0,
                                                marginRight: 1,
                                                width: 25,
                                                height: 25,
                                                alignSelf: 'center',
                                                color: theme.palette.icon.main,
                                                [theme.breakpoints.down('sm')]:
                                                  {
                                                    marginLeft: 0,
                                                  },
                                              })}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <Box
                                              sx={theme => ({
                                                width: 25,
                                                height: 25,
                                              })}
                                            />
                                            <StarsTwoToneIcon
                                              sx={theme => ({
                                                marginLeft: 0,
                                                marginRight: 1,
                                                width: 25,
                                                height: 25,
                                                alignSelf: 'center',
                                                color: theme.palette.icon.main,
                                                [theme.breakpoints.down('sm')]:
                                                  {
                                                    marginLeft: 0,
                                                  },
                                              })}
                                            />
                                          </>
                                        )}

                                        <TitleH3>
                                          {trip &&
                                          (!trip.tripType ||
                                            trip.tripType !== 'guide') ? (
                                            <> {'Day ' + day.dayNumber}</>
                                          ) : (
                                            <>Guide</>
                                          )}
                                        </TitleH3>
                                        <Box pl={1}>
                                          {trip && trip.startDate && (
                                            <Typography
                                              sx={theme => ({
                                                fontWeight: 500,
                                                fontSize: 18,
                                                marginTop: theme.spacing(0.5),
                                                color: '#4E4B66',
                                                [theme.breakpoints.down('sm')]:
                                                  {
                                                    fontSize: 16,
                                                  },
                                              })}
                                            >
                                              {returnFormatedDate(
                                                trip.startDate,
                                                dayIndex,
                                              )}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Stack>
                                    </ContainedHandle>
                                  )}
                                </Box>
                                {day.steps &&
                                  day.steps.length > 0 &&
                                  !props.showEmptyDay && (
                                    <Box
                                      sx={theme => ({
                                        // zoom: 1.11,
                                      })}
                                    >
                                      <ActionsDay
                                        dayId={day.id}
                                        dayNumber={day.dayNumber}
                                        iconsOnly={false}
                                        tripType={trip!.tripType}
                                      />
                                    </Box>
                                  )}
                              </Stack>
                              <Stack
                                direction="row"
                                sx={theme => ({
                                  marginTop: 1,
                                  paddingLeft: 3.25,
                                  [theme.breakpoints.down('sm')]: {
                                    paddingLeft: 3,
                                    // paddingLeft: '40px',
                                  },
                                })}
                              >
                                {day && day.cities && day.cities.length > 0 && (
                                  <MapsHomeWorkTwoToneIcon
                                    sx={theme => ({
                                      marginRight: 1,
                                      width: 25,
                                      height: 25,
                                      alignSelf: 'center',
                                      color: theme.palette.icon.main,
                                    })}
                                  />
                                )}
                                <Typography
                                  sx={theme => ({
                                    fontWeight: 500,
                                    fontSize: 18,
                                    marginTop: 0.5,
                                    color: '#4E4B66',
                                    [theme.breakpoints.down('sm')]: {
                                      fontSize: 16,
                                    },
                                  })}
                                >
                                  {day &&
                                    day.cities &&
                                    day.cities.length > 0 &&
                                    day.cities.map((city, i) => (
                                      <React.Fragment key={i}>
                                        {city && (
                                          <>
                                            {city.cityName}
                                            {i !== day.cities!.length - 1 && (
                                              <>{', '}</>
                                            )}
                                          </>
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Typography>
                                {/* <Box className={classes.alignButtons}>
                                  <DayInfoModal day={day} />
                                </Box> */}
                              </Stack>
                              <Box mt={1}>
                                <DraggableDroppableSteps
                                  tripId={trip!.id}
                                  day={day}
                                  dayIndex={dayIndex}
                                  collapsedSteps={collapsedSteps}
                                  showEmptyDay={props.showEmptyDay}
                                  tripType={
                                    trip && trip.tripType
                                      ? trip.tripType
                                      : undefined
                                  }
                                />
                              </Box>
                            </Stack>
                          </Item>
                          {!props.showEmptyDay && (
                            <ActionsDay
                              dayId={day.id}
                              dayNumber={day.dayNumber}
                              iconsOnly={true}
                              tripType={trip!.tripType}
                            />
                          )}
                        </>
                      )}
                    </Draggable>
                  )}
                </Box>
              ))}
            {provided.placeholder}
            {trip &&
              trip.itinerary &&
              (!trip.tripType || trip.tripType !== 'guide') && (
                <>
                  <Divider sx={{ marginTop: 3, marginLeft: 2 }} />
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    flexWrap="wrap"
                    mt={2}
                    mb={3}
                  >
                    <Box
                      onClick={async () => {
                        gtag('event', 'add-new-day');
                        const response = await addDay({
                          variables: {
                            itinerary_id: trip.itinerary!.id,
                          },
                        });
                        dispatch(setDays(response.data.createUserDay.days));
                      }}
                      sx={theme => ({
                        fontSize: 16,
                        fontWeight: 600,
                        paddingTop: 1,
                        paddingBottom: 1,
                        paddingLeft: 3,
                        paddingRight: 3,
                        color: '#FFFFFF',
                        backgroundColor: '#36454F',
                        borderRadius: 20,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        boxShadow: 'none', // Disable shadow
                        '&:hover': {
                          boxShadow: 'none',
                          backgroundColor: '#4A5D6F',
                        },
                        [theme.breakpoints.down('sm')]: {
                          fontSize: 14,
                        },
                      })}
                    >
                      {loadingNewDay ? (
                        <Loader color="primary" size={20} />
                      ) : (
                        <>{'Add another day (Day ' + (days.length + 1) + ')'}</>
                      )}
                    </Box>
                    <br />
                    {errorNewDay && (
                      <div>Error adding day to this itinerary</div>
                    )}
                  </Stack>
                </>
              )}
          </ContainerDay>
        )}
      </Droppable>
    </>
  );
}
