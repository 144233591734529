import { useLazyQuery, useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info'; // Import the Info icon
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  ProminentButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { FullScreenBusAnimation } from '../../animations/BusAnimation';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../../gql-user/createUserTripMutation';
import { QUERY_GET_COUNTRIES } from '../../gql-user/getCountries';
import { VerticalFlex } from '../../helpers/flex';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../styling/modal';

import { StyledFormControl } from './Styles';

export default function CreateTripModal(props: {
  card?: boolean;
  type?: string;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [name, setName] = useState('');
  // const [countryId, setCountryId] = useState('');
  const [placeUrl, setPlaceUrl] = useState('');
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);
  const [isValidUrl, setIsValidUrl] = useState(true);

  const validateUrl = url => {
    const validUrlPatterns = [
      'https://maps.app.goo.gl/',
      'https://www.google.com/maps',
    ];

    // Check if the entered URL starts with any of the valid patterns
    return validUrlPatterns.some(pattern => url.startsWith(pattern));
  };

  const handleChange = e => {
    const newUrl = e.target.value;
    setPlaceUrl(newUrl);

    // Validate the URL and update the validation state
    setIsValidUrl(validateUrl(newUrl));
  };

  const [addTrip, { loading, data }] = useMutation(
    MUTATION_CREATE_USER_TRIP_WITH_DAY,
    {
      variables: {
        name: name,
        place_url: placeUrl,
        trip_type: props.type && props.type,
      },
    },
  );

  const [
    getCountries,
    { data: countries, loading: countriesLoading, error: countriesError },
  ] = useLazyQuery(QUERY_GET_COUNTRIES);

  if (data && data.createUserTripWithDay.id) {
    navigate('/create-route/' + data.createUserTripWithDay.id);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      {!props.card ? (
        <ProminentButton
          onClick={() => {
            gtag('event', 'create-new-trip-button');
            handleOpen();
            getCountries();
          }}
        >
          {props.type && props.type === 'guide' ? (
            <>{'Create new guide'}</>
          ) : (
            <>{'Create new trip'}</>
          )}
        </ProminentButton>
      ) : (
        <Card
          sx={theme => ({
            // maxWidth: 345,
            position: 'relative',
            borderRadius: 3,
            marginTop: 3,
            marginRight: 1,
            width: '24%',
            [theme.breakpoints.down('xl')]: {
              width: '24%',
            },
            [theme.breakpoints.down('lg')]: {
              width: '32%',
            },
            [theme.breakpoints.down('md')]: {
              width: '48%',
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              marginRight: 0, // Ensures no right margin on small screens
              marginLeft: 0, // Ensures no left margin on small screens
            },
            [theme.breakpoints.down('xs')]: {
              width: '100%',
              marginRight: 0, // Ensures no right margin on small screens
              marginLeft: 0, // Ensures no left margin on small screens
            },
          })}
          onClick={() => {
            gtag('event', 'create-new-trip-card');
            handleOpen();
            getCountries();
          }}
        >
          <CardMedia
            component="img"
            height="150"
            sx={{ borderRadius: '8px 8px 0 0' }}
            image={'/assets/image/placeholder-svg.svg'}
          />
          <CardContent
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-end', // Align items to the right
            }}
          >
            <ProminentButton>
              {props.type && props.type === 'guide' ? (
                <>{'Create new guide'}</>
              ) : (
                <>{'Create new trip'}</>
              )}
            </ProminentButton>
          </CardContent>
        </Card>
      )}

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <>
          {countriesLoading && <Loader />}
          {open && countries && countries.countriesList && !countriesLoading ? (
            <MediumModalPaper>
              <ModalTitle>
                {props.type && props.type === 'guide' ? (
                  <>{'Create new guide'}</>
                ) : (
                  <>{'Create new trip'}</>
                )}
              </ModalTitle>
              <ModalDescription>
                {props.type && props.type === 'guide' ? (
                  <>{'Pick the guide name to get started.'}</>
                ) : (
                  <>{'Pick the trip name to get started'}</>
                )}
              </ModalDescription>
              <StyledFormControl>
                <TextField
                  id="standard-basic"
                  label={
                    props.type && props.type === 'guide'
                      ? 'Guide name'
                      : 'Trip name'
                  }
                  name="name"
                  fullWidth={true}
                  variant="outlined"
                  onChange={e => setName(e.target.value)}
                />
              </StyledFormControl>
              {/* <OrDivider /> */}
              <ModalDescription>
                Optionally enter your Google Maps list of places sharable link.
                <IconButton
                  onClick={handleOpenInfo}
                  aria-label="show share instructions"
                  sx={{ width: 10, height: 10, marginLeft: 1 }}
                >
                  <InfoIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
              </ModalDescription>
              <StyledFormControl>
                <TextField
                  label="Google Maps place shared URL"
                  fullWidth={true}
                  variant="outlined"
                  onChange={handleChange}
                  value={placeUrl}
                  error={!isValidUrl}
                  helperText={
                    !isValidUrl &&
                    'URL must start with https://maps.app.goo.gl/ or https://www.google.com/maps'
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleOpenInfo}
                          aria-label="show share instructions"
                        >
                          <InfoIcon />
                        </IconButton>
                        <SharePlacesModal
                          open={openInfo}
                          handleClose={handleCloseInfo}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledFormControl>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
                mt={2}
              >
                <VerticalFlex>
                  <PrimaryButton
                    disabled={loading || name === ''}
                    onClick={async () => {
                      gtag('event', 'create-new-trip-save');
                      try {
                        await addTrip();
                        handleClose();
                      } catch (e) {
                        gtag('event', 'error-create-new-trip-save');
                        dispatch(setErrorTitle('Error adding location'));
                        dispatch(setErrorModalOpen(true));
                      }
                    }}
                  >
                    {loading && <Loader />}
                    {!loading && props.type && props.type === 'guide' ? (
                      <>{'Create new guide'}</>
                    ) : (
                      <>{'Create new trip'}</>
                    )}
                  </PrimaryButton>
                </VerticalFlex>
              </Box>
            </MediumModalPaper>
          ) : (
            <Box>
              {countriesError && (
                <p>
                  Something went wrong <br />
                  please try again later
                </p>
              )}
            </Box>
          )}
        </>
      </CenteredModal>
      {loading && placeUrl !== '' && (
        <FullScreenBusAnimation copy="This can take a wile depending on number of places on your list. You do not need to wait just come back later and your trip will be generated." />
      )}
    </Box>
  );
}

// const OrDivider = () => (
//   <Box sx={{ display: 'flex', alignItems: 'center' }}>
//     <Divider sx={{ flexGrow: 1 }} />
//     <Typography sx={{ mx: 2, fontSize: 14 }}></Typography>
//     <Divider sx={{ flexGrow: 1 }} />
//   </Box>
// );

function SharePlacesModal({ open, handleClose }) {
  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={open}
      onClose={handleClose}
    >
      {/* <Box sx={modalStyle}> */}
      <MediumModalPaper>
        <ModalTitle>How bulk import from Google Maps</ModalTitle>
        <Stack direction="row" mt={3}>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', marginRight: 2 }}
          >
            <img
              src="/assets/image/google-share-list.png"
              alt="Share button on Google Maps"
              style={{
                width: '90%',
                borderRadius: 8,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          </Box>
          <ModalDescription id="share-instruction-description" sx={{ mt: 2 }}>
            To quickly add your saved places from Google Maps, just click on{' '}
            <strong>"Share {'->'} Send link to view"</strong> button and copy
            the URL. Then just paste to rooutie.
          </ModalDescription>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', // Aligns items to the right
          }}
        >
          {/* <VerticalFlex> */}
          <SecondaryButton
            onClick={handleClose}
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Got it
          </SecondaryButton>
        </Box>
      </MediumModalPaper>
      {/* </Box> */}
    </CenteredModal>
  );
}
