import { useQuery } from '@apollo/client';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { errorsSelector } from '../../store/ErrorSlice';
import { isAdmin, isPublisher, useUserRoles } from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import CreateTripModal from '../create-trip/manage-trip-modals/CreateTripModal';
import ErrorModal from '../Error';
import { QUERY_GET_USER_SHARED_TRIPS } from '../gql-user/userSharedTripsQuery';
import { QUERY_GET_USER_TRIPS } from '../gql-user/userTripsQuery';
import { HorizontalFlex } from '../helpers/flex';

import ShareTripModal from './ShareTripModal';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

export function UserTrips() {
  const { loading, error, data } = useQuery(QUERY_GET_USER_TRIPS);
  const {
    loading: loadingShared,
    error: errorShared,
    data: dataShared,
  } = useQuery(QUERY_GET_USER_SHARED_TRIPS);
  const { errorModalOpen } = useSelector(errorsSelector);

  if (loading || loadingShared) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  return (
    <>
      <Box
        sx={{ width: '100%', paddingTop: 2, paddingLeft: 1, paddingRight: 1 }}
      >
        {errorModalOpen && <ErrorModal />}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Header>My trips</Header>
          {data && data.userTripsList && data.userTripsList.length > 0 && (
            <CreateTripModal card={false} type="itinerary" />
          )}
        </Box>

        {error || !data ? (
          <div>Error loading your trips </div>
        ) : (
          <CreatedTrips trips={data.userTripsList} />
        )}

        {errorShared || !dataShared ? (
          <div>Error loading shared trips </div>
        ) : (
          <>
            {dataShared.userSharedTripsList.length > 0 && (
              <>
                <Header>Shared with me</Header>
                <Divider />
                <SharedTrips trips={dataShared.userSharedTripsList} />
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}

function CreatedTrips(props: PropsTripsList) {
  const roles = useUserRoles();
  const navigate = useNavigate();
  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Card
                    key={index + trip.id}
                    sx={theme => ({
                      // maxWidth: 345,
                      position: 'relative',
                      borderRadius: 3,
                      marginTop: 3,
                      marginRight: 1,
                      width: '24%',
                      [theme.breakpoints.down('xl')]: {
                        width: '24%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '32%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginRight: 0, // Ensures no right margin on small screens
                        marginLeft: 0, // Ensures no left margin on small screens
                      },
                      [theme.breakpoints.down('xs')]: {
                        width: '100%',
                        marginRight: 0, // Ensures no right margin on small screens
                        marginLeft: 0, // Ensures no left margin on small screens
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        navigate('/create-route/' + trip.id);
                        gtag('event', 'user-trip-edit-card');
                      }}
                    >
                      {trip.images && trip.images.length > 0 ? (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={trip.images[0]!.path!}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={'/assets/image/placeholder-svg.svg'}
                        />
                      )}
                      <CardContent
                        sx={{
                          '&:last-child': {
                            paddingBottom: '16px', // Overwrite the padding-bottom for last-child
                          },
                        }}
                      >
                        <TripTitle>{trip.name}</TripTitle>
                        {trip.length && (
                          <TripLength>{trip.length + ' days'}</TripLength>
                        )}

                        {trip.itineraryCountries &&
                        trip.itineraryCountries.length > 0 ? (
                          <TripCountries>
                            {trip.itineraryCountries.map((country, index) => (
                              <React.Fragment key={index}>
                                {country && (
                                  <>
                                    {country.countryName}
                                    {trip.itineraryCountries &&
                                      index !==
                                        trip.itineraryCountries.length - 1 && (
                                        <>{','}&nbsp;</>
                                      )}
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                          </TripCountries>
                        ) : (
                          <> &nbsp; &nbsp; &nbsp; </>
                        )}
                      </CardContent>
                    </Box>
                    <Divider />
                    <Stack
                      width="100%"
                      direction="row"
                      sx={{
                        paddingTop: 2,
                        // paddingLeft: ,
                        paddingBottom: 2,
                        justifyContent: 'space-around', // Distribute items evenly
                      }}
                      display="flex"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => {
                          navigate('/preview/' + trip.id);
                          gtag('event', 'user-trip-preview-icon');
                        }}
                        sx={{
                          padding: 0, // Remove padding for a cleaner look
                          backgroundColor: 'transparent', // Transparent background
                          color: theme => theme.palette.icon.main, // Use the theme color for the icon and text
                          display: 'flex', // Align icon and text
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: theme =>
                              theme.palette.action.hover, // Optional hover effect
                          },
                        }}
                      >
                        <VisibilityTwoToneIcon
                          sx={{
                            width: 20, // Increase icon size
                            height: 20, // Increase icon size
                            marginRight: 0.5, // Space between icon and text
                          }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          View
                        </Typography>
                      </Button>

                      <Button
                        onClick={() => {
                          navigate('/create-route/' + trip.id);
                          gtag('event', 'user-trip-edit-icon');
                        }}
                        sx={{
                          padding: 0,
                          backgroundColor: 'transparent',
                          color: theme => theme.palette.icon.main,
                          display: 'flex',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: theme =>
                              theme.palette.action.hover,
                          },
                        }}
                      >
                        <EditTwoToneIcon
                          sx={{
                            width: 20,
                            height: 20,
                            marginRight: 0.5,
                          }}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          Edit
                        </Typography>
                      </Button>

                      <ShareTripModal tripId={trip.id} />
                    </Stack>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </Grid>
          <Stack direction="column" width="100%">
            <CreateTripModal card={true} type="itinerary" />
            {/* {(isAdmin(roles) || isPublisher(roles)) && ( */}
            {/* <Box mt={2}> */}
            <CreateTripModal card={true} type="guide" />
            {/* </Box> */}
            {/* )} */}
          </Stack>
        </>
      ) : (
        <CreateTripModal card={true} type="itinerary" />
      )}
    </Box>
  );
}

function SharedTrips(props: PropsTripsList) {
  const navigate = useNavigate();

  return (
    <Box mb={2}>
      {props.trips && props.trips.length > 0 ? (
        <>
          <Grid container>
            {props.trips.map((trip, index) => (
              <React.Fragment key={index}>
                {trip && (
                  <Card
                    key={index + trip.id}
                    sx={theme => ({
                      // maxWidth: 345,
                      position: 'relative',
                      borderRadius: 3,
                      marginTop: 3,
                      marginRight: 1,
                      width: '24%',
                      [theme.breakpoints.down('xl')]: {
                        width: '24%',
                      },
                      [theme.breakpoints.down('lg')]: {
                        width: '32%',
                      },
                      [theme.breakpoints.down('md')]: {
                        width: '48%',
                      },
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        marginRight: 0, // Ensures no right margin on small screens
                        marginLeft: 0, // Ensures no left margin on small screens
                      },
                      [theme.breakpoints.down('xs')]: {
                        width: '100%',
                        marginRight: 0, // Ensures no right margin on small screens
                        marginLeft: 0, // Ensures no left margin on small screens
                      },
                    })}
                  >
                    <Box
                      onClick={() => {
                        navigate('/preview/' + trip.id);
                        gtag('event', 'preview-shared-trip');
                      }}
                    >
                      {trip.images && trip.images.length > 0 ? (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={trip.images[0]!.path!}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          height="150"
                          sx={{ borderRadius: '8px 8px 0 0' }}
                          image={'/assets/image/placeholder-svg.svg'}
                        />
                      )}
                      <CardContent
                        sx={{
                          '&:last-child': {
                            paddingBottom: '16px', // Overwrite the padding-bottom for last-child
                          },
                        }}
                      >
                        <TripTitle>{trip.name}</TripTitle>
                        {trip.length && (
                          <TripLength>{trip.length + ' days'}</TripLength>
                        )}

                        {trip.itineraryCountries &&
                          trip.itineraryCountries.length > 0 && (
                            <>
                              {/* <Divider className={classes.divider} /> */}
                              <HorizontalFlex>
                                {trip.itineraryCountries.map(
                                  (country, index) => (
                                    <React.Fragment key={index}>
                                      {country && (
                                        <TripLength>
                                          {country.countryName}
                                        </TripLength>
                                      )}
                                    </React.Fragment>
                                  ),
                                )}
                              </HorizontalFlex>
                            </>
                          )}
                      </CardContent>
                    </Box>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </Grid>
        </>
      ) : null}
    </Box>
  );
}

const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
}));

const TripLength = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.main,
}));

const TripCountries = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  color: theme.palette.secondary.main,
  whiteSpace: 'nowrap', // Ensures no wrapping of text
}));
