import { useMutation, useQuery } from '@apollo/client';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Box, Divider, Link } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Day } from '../../../generated/user_graphql';
import {
  daysSelector,
  setDayLoadingIndex,
  setDays,
  setStepLoadingIndex,
} from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import { tripsSelector } from '../../../store/TripSlice';
import { FullScreenBusAnimation } from '../../animations/BusAnimation';
import { MUTATION_COPY_DAY_TO_ITINERARY } from '../../gql-user/copyDayMutation';
import { MUTATION_COPY_STEP_TO_DAY } from '../../gql-user/copyStepMutation';
import { MUTATION_MOVE_DAY } from '../../gql-user/moveDayMutation';
import { MUTATION_MOVE_STEP } from '../../gql-user/moveStepMutation';
import { QUERY_USER_GET_PUBLIC_TRIP } from '../../gql-user/userGetPublicTrip';
import { HorizontalFlex } from '../../helpers/flex';
import { RouteContent, RoutePage, RouteTitle } from '../../route';
import { CreateTripDetails } from '../edit-trip/TripDetails';

import {
  copyDay,
  copyStep,
  moveStepBetweenDays,
  reorderDays,
  reorderSteps,
} from './dragAndDropFunctions';
import { DraggableDroppableDays } from './DraggableDroppableDays';
import { DraggableItineraryDays } from './DraggableItineraryDays';

export function DragAndDropUserItineraryTwoTrips() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [copyDayToItinerary] = useMutation(MUTATION_COPY_DAY_TO_ITINERARY);
  const [moveDayInItinerary] = useMutation(MUTATION_MOVE_DAY);
  const [copyStepToItinerary] = useMutation(MUTATION_COPY_STEP_TO_DAY);
  const [moveStep] = useMutation(MUTATION_MOVE_STEP);
  gtag('event', 'create-user-trip-drag-and-drop');

  let seectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  seectedTripIdOnLoad = params.get('selected-trip');

  const { loading, error, data } = useQuery(QUERY_USER_GET_PUBLIC_TRIP, {
    variables: { trip_id: seectedTripIdOnLoad },
  });
  const {
    trip,
    tripName,
    tripDescription,
    loading: tripLoading,
  } = useSelector(tripsSelector);
  const { days, loading: daysLoading } = useSelector(daysSelector);

  if (tripLoading || daysLoading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }
  let existingDays: Day[] | null = null;

  if (
    data &&
    data.publicTrip &&
    data.publicTrip.itinerary &&
    data.publicTrip.itinerary.days &&
    data.publicTrip.itinerary.days.length > 0
  ) {
    existingDays = data.publicTrip.itinerary.days;
  }

  if (!trip || !days) {
    return (
      <>
        <RoutePage>
          <RouteTitle>Trip</RouteTitle>
          <RouteContent>Trip not found</RouteContent>
        </RoutePage>
      </>
    );
  }

  async function onDragEnd(result) {
    const { source, destination, draggableId } = result;
    const userItineraryId = trip!.itinerary!.id;

    if (!destination || !days || !existingDays) {
      return;
    }

    if (
      source.droppableId === 'itinerary' &&
      destination.droppableId === 'itinerary'
    ) {
      // ------------------------
      // Move day
      // ------------------------
      gtag('event', 'move-day-dnd');

      const existingDays = reorderDays(days, source.index, destination.index);

      dispatch(setDayLoadingIndex(destination.index));
      dispatch(setDays(existingDays));

      try {
        const response = await moveDayInItinerary({
          variables: {
            day_id: draggableId.replace('_temp', ''),
            new_day_number: destination.index + 1,
          },
        });

        dispatch(setDays(response.data.moveDay.days));
        dispatch(setDayLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving day'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (source.droppableId === 'existingDays') {
      // ------------------------
      // Copy day
      // ------------------------
      gtag('event', 'copy-day-dnd');

      const newDays = copyDay(existingDays, days, source, destination);
      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(destination.index));
      let originalDayId = draggableId;
      while (originalDayId.includes('_temp')) {
        originalDayId = originalDayId.replace('_temp', '');
      }

      let newItineraryId = userItineraryId;
      while (userItineraryId.includes('_temp')) {
        newItineraryId = newItineraryId.replace('_temp', '');
      }

      try {
        const response = await copyDayToItinerary({
          variables: {
            original_day_id: originalDayId,
            new_itinerary_id: newItineraryId,
            new_day_number: destination.index + 1,
          },
        });

        dispatch(setDays(response.data.copyDayToItinerary.days));
        dispatch(setDayLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error copying day'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (source.droppableId === destination.droppableId) {
      // ------------------------
      // Move step in day
      // ------------------------
      gtag('event', 'move-step-in-day-dnd');

      const { newDays, dayIndex } = reorderSteps(
        days,
        source.droppableId,
        source.index,
        destination.index,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(dayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });

        dispatch(setDays(response.data.moveStep.days));
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else if (source.droppableId.startsWith('existingStepDayId_')) {
      // ------------------------
      // Copy step from existing itinerary
      // ------------------------
      gtag('event', 'copy-step-dnd');

      const { newDays, destinationDayIndex } = copyStep(
        existingDays,
        days,
        draggableId,
        source,
        destination,
      );
      dispatch(setStepLoadingIndex(destination.index));
      dispatch(setDayLoadingIndex(destinationDayIndex));
      dispatch(setDays(newDays));
      try {
        const response = await copyStepToItinerary({
          variables: {
            original_step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });

        dispatch(setDays(response.data.copyStepToDay.days));
        dispatch(setStepLoadingIndex(-1));
        dispatch(setDayLoadingIndex(-1));
      } catch (e) {
        dispatch(setStepLoadingIndex(-1));
        dispatch(setDayLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    } else {
      // ------------------------
      // Move step between days
      // ------------------------
      gtag('event', 'move-step-between-days-dnd');

      const { newDays, destinationDayIndex } = moveStepBetweenDays(
        days,
        source,
        destination,
      );

      dispatch(setDays(newDays));
      dispatch(setDayLoadingIndex(destinationDayIndex));
      dispatch(setStepLoadingIndex(destination.index));

      try {
        const response = await moveStep({
          variables: {
            step_id: draggableId.replace('_temp', ''),
            new_day_id: destination.droppableId.replace('_temp', ''),
            original_day_id: source.droppableId.replace('_temp', ''),
            new_step_number: destination.index + 1,
          },
        });

        dispatch(setDays(response.data.moveStep.days));
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
      } catch (e) {
        dispatch(setDayLoadingIndex(-1));
        dispatch(setStepLoadingIndex(-1));
        dispatch(setErrorTitle('Error moving activity'));
        dispatch(setErrorModalOpen(true));
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={{ marginTop: 1, marginBottom: 1 }}>
        <Box
          sx={{
            display: 'flex', // Use flexbox to arrange items horizontally
            alignItems: 'center', // Center items vertically
            fontWeight: 600,
            fontSize: 16,
            textDecoration: 'none',
            cursor: 'pointer', // Optional: Change cursor to pointer for better UX
            '&:hover, &:visited, &:link, &:active': {
              textDecoration: 'none',
            },
          }}
          onClick={() => {
            navigate('/create-route/' + trip.id);
            gtag('event', 'back-to-edit-single-trip');
          }}
        >
          <ArrowBackTwoToneIcon
            sx={theme => ({
              width: 20, // Fixed width
              height: 20, // Fixed height
              color: theme.palette.primary.main, // Primary color from theme
              fill: theme.palette.primary.main, // Fill color applied directly to path
              marginRight: theme.spacing(0.5), // Add some space between the icon and text
            })}
          />
          <Link
            sx={{
              textDecoration: 'none',
              '&:hover, &:visited, &:link, &:active': {
                textDecoration: 'none',
              },
            }}
          >
            Back to your trip
          </Link>
        </Box>
        <HorizontalFlex>
          <Box
            sx={theme => ({
              width: '50%',
              backgroundColor: '#ffffff',
              borderRadius: '10px',
              padding: 1,
              marginRight: 0.5,
              position: 'relative',
              top: '2vh',
              height: '96vh',
              overflow: 'auto',
              display: 'block',
            })}
            border={1}
            borderColor="#d6d6d6"
          >
            <Box
              display="flex"
              flexDirection="row"
              sx={{ width: '100%', overflow: 'none' }}
            >
              <Box
                flex="1 0 0"
                sx={{
                  // zoom: 0.75,
                  width: '100%',
                  overflow: 'none',
                }}
              >
                <CreateTripDetails
                  tripId={trip.id}
                  tripName={tripName}
                  tripDescription={tripDescription}
                  edit={true}
                  showTripActions={false}
                />
                <DraggableDroppableDays showEmptyDay={true} />
              </Box>
            </Box>
          </Box>

          {loading ? (
            <Box
              sx={theme => ({
                width: '50%',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                padding: 1,
                marginLeft: 0.5,
                position: 'relative',
                top: '2vh',
                height: '96vh',
                overflow: 'auto',
                display: 'block',
              })}
            >
              <FullScreenBusAnimation copy="Almost there!" />
            </Box>
          ) : (
            <>
              {data.publicTrip && existingDays && !error ? (
                <>
                  <Divider sx={theme => ({ width: 2, height: '100%' })} />
                  <Box
                    sx={theme => ({
                      width: '50%',
                      backgroundColor: '#ffffff',
                      borderRadius: '10px',
                      padding: 1,
                      marginLeft: 0.5,
                      position: 'relative',
                      top: '2vh',
                      height: '96vh',
                      overflow: 'auto',
                      display: 'block',
                    })}
                    border={1}
                    borderColor="#d6d6d6"
                  >
                    <Box>
                      <Box
                        flex="1 0 0"
                        // alignItems="center"
                        // justifyContent="center"
                        // height="96vh"
                        // width="100%"
                        sx={
                          {
                            // zoom: 0.75,
                          }
                        }
                      >
                        <CreateTripDetails
                          tripId={data.publicTrip.id}
                          tripName={data.publicTrip.name}
                          tripDescription={data.publicTrip.description}
                          edit={false}
                          showTripActions={false}
                        />
                        <DraggableItineraryDays
                          existingTripDays={existingDays}
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <div>Error getting trip</div>
              )}
            </>
          )}
        </HorizontalFlex>
      </Box>
    </DragDropContext>
  );
}
