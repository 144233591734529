import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PlaceTwoToneIcon from '@mui/icons-material/PlaceTwoTone';
import { Box, Stack, styled } from '@mui/material';

import { Step } from '../../../generated/user_graphql';
import { TitleH4 } from '../../../theme-components/Typography';
import { ActionsStep } from '../edit-trip/ActionsStep';

// export const Item = styled(Box)<{ $isDragging?: boolean }>(
// ({ $isDragging }) => ({
export const Item = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDragging', // Prevent forwarding $isDragging to the DOM
})<{ $isDragging?: boolean }>(({ $isDragging }) => ({
  display: 'flex',
  userSelect: 'none',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  borderRadius: '10px',
  background: $isDragging ? '#e0e0e0' : '#fff',
  border: $isDragging ? '1px dashed #000' : '1px solid transparent',
}));

// // Item component styled using MUI's Box
// export const Item = styled(Box)(({ theme, isDragging }) => ({
//   display: 'flex',
//   userSelect: 'none',
//   alignItems: 'flex-start',
//   alignContent: 'flex-start',
//   borderRadius: '10px',
//   background: '#fff',
//   border: isDragging ? '1px dashed #000' : '1px solid transparent',
// }));

// Clone component that extends Item
export const Clone = styled(Item)(({ theme }) => ({
  '& ~ div': {
    transform: 'none !important',
  },
}));

export const Handle = styled(props => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        userSelect: 'none',
        width: '100%',
        // Use theme in your styling
        // [theme.breakpoints.down('sm')]: {
        //   marginLeft: '-5px',
        // },
      }}
    />
  );
})({});

export const ContainedHandle = styled(props => {
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        userSelect: 'none',
      }}
    />
  );
})({});

// Container component styled using MUI's Box
// export const Container = styled(Box)({
//   width: '100%',
// });

export const Container = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDraggingOver', // Prevent forwarding $isDraggingOver to the DOM
})<{ $isDraggingOver?: boolean }>(({ $isDraggingOver }) => ({
  width: '100%',
  // backgroundColor: isDraggingOver ? 'lightblue' : 'white', // Example of using the prop for styling
}));

// ContainerDay component styled using MUI's Box
export const ContainerDay = styled(Box, {
  shouldForwardProp: prop => prop !== '$isDraggingOver', // Prevent forwarding $isDraggingOver to the DOM
})<{ $isDraggingOver?: boolean }>(({ $isDraggingOver }) => ({
  width: '100%',
}));

export function CreateTripStepTitle(props: {
  step: Step;
  tripId?: string;
  showEdit: boolean;
}) {
  return (
    <Stack
      direction="column"
      alignItems="left"
      sx={theme => ({
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
        },
      })}
    >
      <Stack
        direction="row"
        alignItems="left"
        sx={theme => ({
          // paddingLeft: 0,
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            maxWidth: '99%',
          },
        })}
      >
        <Stack
          direction="row"
          sx={theme => ({
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'center',
            width: 25,
            height: 25,
            [theme.breakpoints.down('sm')]: {
              width: 20,
              height: 20,
            },
          })}
        >
          <DragIndicatorIcon
            sx={theme => ({
              width: 25,
              height: 25,
            })}
          />
        </Stack>
        {/* {props.step.title && ( */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <PlaceTwoToneIcon
              sx={theme => ({
                marginLeft: 0,
                marginRight: 1,
                width: 25,
                height: 25,
                alignSelf: 'center',
                color: theme.palette.icon.main,
                [theme.breakpoints.down('sm')]: {
                  marginLeft: 0,
                },
              })}
            />
            <TitleH4>{props.step.title}</TitleH4>
          </Stack>

          {props.tripId && (
            <Box
              sx={theme => ({
                // zoom: 1.11,
              })}
            >
              {props.showEdit && (
                <ActionsStep
                  copy={'Edit'}
                  step={props.step}
                  tripId={props.tripId}
                />
              )}
            </Box>
          )}
        </Stack>
        {/* )} */}
      </Stack>
    </Stack>
  );
}
