import { Card, CardMedia, Grid, Tooltip, styled, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { Maybe, Image, StepPlaceImage } from '../../../generated/user_graphql';
import { unauthorizedClient } from '../../../UnAuthorizedApolloProvider';
import { FETCH_IMAGE_URL } from '../../gql-public/getPlaceImage';

export function UserStepSingleImage(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <UserImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function UserStepImages(props: { images: Maybe<Image>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <UserImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceImages(props: { images: Maybe<StepPlaceImage>[] }) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');
  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'block',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 3 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
            <Box
              sx={theme => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
                [theme.breakpoints.down('md')]: {
                  display: 'block',
                },
              })}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                wrap="nowrap"
                // sx={{ justifyContent: 'left' }}
              >
                {props.images.map((image, index) => (
                  <React.Fragment key={index}>
                    {index < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={props.images.length === 1 ? 12 : 6}
                        md={props.images.length === 3 ? 12 : 6}
                        key={index}
                      >
                        <ImageCard image={image} index={index} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

export function StepPlaceSingleImage(props: {
  images: Maybe<StepPlaceImage>[];
}) {
  let selectedTripIdOnLoad: string | null = null;
  const params = new URLSearchParams(window.location.search);
  selectedTripIdOnLoad = params.get('selected-trip');

  return (
    <LazyLoad
      height={200}
      offset={150}
      overflow={selectedTripIdOnLoad ? true : false}
    >
      {props.images && props.images.length > 0 && (
        <ImagesBox>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              wrap="nowrap"
              sx={{ justifyContent: 'left' }}
            >
              {props.images.map((image, index) => (
                <React.Fragment key={index}>
                  <Grid item key={index}>
                    <ImageCard image={image} index={index} />
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </ImagesBox>
      )}
    </LazyLoad>
  );
}

const ImagesBox = styled(Box)(({ theme }) => ({
  minWidth: 250,
  marginTop: 10,
  marginBottom: 5,
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(1100)]: {
    marginTop: 10,
    marginLeft: 0,
  },
}));

export const ImageCard = ({ image, index }) => {
  // const [imageSrc, setImageSrc] = useState('');
  // const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  // useEffect(() => {
  //   if (image.path === null || image.path === '') {
  //     console.log('image.path', image.path);
  //     console.log('image', image);
  //     const fetchImage = async () => {
  //       try {
  //         // Use the unauthorizedClient manually for unauthorized requests
  //         const response = await unauthorizedClient.mutate({
  //           mutation: FETCH_IMAGE_URL,
  //           variables: { image_url: image.imageUrl },
  //         });

  //         if (response.data && response.data.fetchImage.imageData) {
  //           // Ensure the response contains base64 data in the correct format
  //           const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type if needed
  //           setImageSrc(base64Data);
  //           setImageLoaded(true);

  //           // Optionally cache the image data in sessionStorage
  //           // sessionStorage.setItem(props.image.imageUrl, base64Data);
  //           saveImageToSessionStorage(image.imageUrl, base64Data);
  //         }
  //       } catch (err) {
  //         console.error('Error fetching image:', err);
  //       }
  //     };
  //     fetchImage();
  //   } else {
  //     setImageSrc(image.path);
  //   }
  // }, [image.imageUrl]);
  const [imageSrc, setImageSrc] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchImage = async () => {
      if (image.path === null || image.path === '') {
        console.log('image.path', image.path);
        console.log('image', image);
        try {
          // Use the unauthorizedClient manually for unauthorized requests
          const response = await unauthorizedClient.mutate({
            mutation: FETCH_IMAGE_URL,
            variables: { image_url: image.imageUrl },
          });

          if (response.data && response.data.fetchImage.imageData) {
            // Ensure the response contains base64 data in the correct format
            const base64Data = `data:image/jpeg;base64,${response.data.fetchImage.imageData}`; // Adjust MIME type if needed

            // Only update state if the component is still mounted
            if (isMounted) {
              setImageSrc(base64Data);
              setImageLoaded(true);
              saveImageToSessionStorage(image.imageUrl, base64Data);
            }
          }
        } catch (err) {
          console.error('Error fetching image:', err);
        }
      } else {
        if (isMounted) {
          setImageSrc(image.path);
        }
      }
    };

    fetchImage();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [image.imageUrl, image.path]); // Include image.path as a dependency

  if (imageSrc === '')
    return (
      <Skeleton
        variant="rectangular"
        sx={theme => ({
          height: 150,
          width: '100%',
          objectFit: 'cover',
          [theme.breakpoints.down('sm')]: {
            height: 130,
          },
        })}
      />
    );

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Tooltip
        key={index}
        title={
          typeof index === 'number' ? (image && image.title) || 'Image' : ''
        }
        disableInteractive
        arrow
        placement="top-start"
      >
        <CardMedia
          component="img"
          src={imageSrc}
          alt="Fetched Image"
          onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
          sx={{
            maxHeight: 200,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
          }}
        />
      </Tooltip>
    </Card>
  );
};

const UserImageCard = ({ image, index }) => {
  const [imageLoaded, setImageLoaded] = useState(false); // State to track image load status

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!imageLoaded && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            maxHeight: 200,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}

      <CardMedia
        component="img"
        src={image!.path!}
        onLoad={() => setImageLoaded(true)} // Set imageLoaded to true once the image is loaded
        sx={{
          maxHeight: 200,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: imageLoaded ? 'block' : 'none', // Hide the image until loaded
        }}
      />
    </Card>
  );
};

function saveImageToSessionStorage(key, data) {
  try {
    // Try to store the data
    sessionStorage.setItem(key, data);
  } catch (e) {
    if (e.name === 'QuotaExceededError') {
      // Quota exceeded, handle the error
      console.warn('SessionStorage quota exceeded. Clearing old items...');

      // Simple eviction strategy: remove the oldest item
      removeOldestItemFromSessionStorage();

      // Try to store the data again after eviction
      try {
        sessionStorage.setItem(key, data);
      } catch (e) {
        console.error('Failed to store data after eviction:', e);
      }
    } else {
      console.error('Error saving data to sessionStorage:', e);
    }
  }
}

function removeOldestItemFromSessionStorage() {
  const keys = Object.keys(sessionStorage);

  // If there are items in sessionStorage, remove the oldest one
  if (keys.length > 0) {
    let oldestKey = keys[0]; // This assumes the first item added is the oldest
    sessionStorage.removeItem(oldestKey);
    oldestKey = keys[1]; // This assumes the first item added is the oldest
    sessionStorage.removeItem(oldestKey);
  }
}
