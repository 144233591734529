import { styled, FormControl } from '@mui/material';

export const StyledFormControl = styled(props => (
  <FormControl variant="outlined" {...props} />
))(({ theme }) => ({
  margin: theme.spacing(1),
  width: '100%',
  minWidth: 120,
  marginLeft: 0,
  marginRight: 0,
}));
