import { useQuery } from '@apollo/client';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Typography,
  styled,
  Skeleton,
  Stack,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Trip } from '../../generated/public_graphql';
import { LightButton } from '../../theme-components/Buttons';
import { TitleH2 } from '../../theme-components/Typography';
import { getFourImages } from '../../utils/helpers';
import { QUERY_COUNTRIES_TRIPS } from '../gql-public/countriesTripsQuery';
import { QUERY_TRIPS } from '../gql-public/tripsListQuery';

import { GradientContainer, OuterContainer } from './HomeHeader';

interface PropsTripsList {
  readonly trips: Maybe<Trip>[];
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: 5,
  flexWrap: 'nowrap', // Prevent wrapping of items
  overflowY: 'auto', // Allows vertical scrolling
  [theme.breakpoints.down('md')]: {
    paddingLeft: 8,
  },
}));

const StyledCard = styled(Box)(({ theme }) => ({
  borderRadius: 20,
  marginRight: 10,
  marginLeft: 10,
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 0,
  width: 400,
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: 280,
    maxHeight: 300,
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: 10,
  borderRadius: 20,
  backgroundColor: '#FFFFFF',
  width: 400,
  '&:last-child': {
    paddingBottom: 14,
  },
  [theme.breakpoints.down('sm')]: {
    width: 280,
  },
}));

const TripImage = styled(CardMedia)(({ theme }) => ({
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  height: 200,
  width: 400,
  [theme.breakpoints.down('sm')]: {
    height: 140,
    width: 280,
  },
}));

const ScrollButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: -170,
  marginBottom: -80,
  justifyContent: 'space-between',
}));

const ButtonBackground = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  margin: 5,
  backgroundColor: '#FFF3DB',
  borderRadius: 20,
  position: 'relative',
  padding: 8,
}));

const TripTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 20,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
}));

const TripLength = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const TripCountry = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  marginRight: 5,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

const MoreInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export function HomeTrips() {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(true);

  const { loading, error, data } = useQuery(QUERY_TRIPS);
  const { data: dataCountries } = useQuery(QUERY_COUNTRIES_TRIPS);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false); // Set flag to false on unmount
  }, []);

  if (loading) {
    return <LoadingCards />;
  }

  if (error) {
    return <div>Couldn't load trips, please refresh or come back later.</div>;
  }

  return (
    <GradientContainer gradient="linear-gradient(180deg, #F9E7E1 0%, #FFFFFF 100%)">
      <TitleH2>Destinations</TitleH2>
      <MoreInfoBox>
        {dataCountries &&
          dataCountries.countriesTrips &&
          dataCountries.countriesTrips.map((countryTrip, index) => (
            <React.Fragment key={index}>
              <ButtonBox>
                <LightButton
                  onClick={() => {
                    navigate('/country/' + countryTrip.country.id);
                  }}
                >
                  {countryTrip.country.name}
                </LightButton>
              </ButtonBox>
            </React.Fragment>
          ))}
      </MoreInfoBox>
      {/* <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}> */}
      <Box>
        <TitleH2>Trending trips</TitleH2>
      </Box>
      {loading ? (
        <LoadingCards />
      ) : (
        <>
          {error || !data || !data.tripsList ? (
            <div>Couldn't load trips, please refresh or come back later.</div>
          ) : (
            <Box
              sx={theme => ({
                marginRight: -2,
                marginLeft: -2,
              })}
            >
              <Trips trips={data.tripsList} />
            </Box>
          )}
        </>
      )}
    </GradientContainer>
  );
}

function LoadingCards() {
  const loadingCards = [1, 2, 3];

  return (
    <StyledGrid container key={1}>
      {loadingCards.map(index => (
        <StyledCard key={index} boxShadow={1}>
          <Skeleton variant="rectangular" />
          <StyledCardContent>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </StyledCardContent>
          <Divider />
          <StyledCardContent>
            <Skeleton />
          </StyledCardContent>
        </StyledCard>
      ))}
    </StyledGrid>
  );
}

function Trips(props: PropsTripsList) {
  const navigate = useNavigate();
  const contentWrapper = useRef<HTMLDivElement | null>(null);

  const handleTripClick = (id: string) => {
    navigate('/trip/' + id);
  };

  return (
    <>
      <StyledGrid container ref={contentWrapper}>
        {props.trips &&
          props.trips.length > 0 &&
          props.trips.map((trip, index) => (
            <StyledCard
              key={index}
              boxShadow={1}
              onClick={() => {
                handleTripClick(trip!.id);
              }}
            >
              {trip!.images && trip!.images.length > 0 ? (
                <TripImage
                  image={
                    trip!.images[0]!.path! && getFourImages(trip!.images!)[0]!
                  }
                />
              ) : (
                <TripImage image={'/assets/image/placeholder-svg.svg'} />
              )}

              <StyledCardContent>
                <TripTitle>{trip!.name}</TripTitle>
                <Stack
                  direction="row"
                  alignItems="center" // Center vertically
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap', // This allows the countries to wrap onto the next line
                  }}
                >
                  {trip &&
                    trip.itineraryCountries &&
                    trip.itineraryCountries.length > 0 &&
                    trip.itineraryCountries.map((country, index) => (
                      <React.Fragment key={index}>
                        {country && (
                          <>
                            {trip.length && index === 0 && (
                              <TripLength>{trip.length + ' days  '}</TripLength>
                            )}
                            <Box
                              sx={{
                                width: 5, // Width of the dot
                                height: 5, // Height of the dot
                                backgroundColor: 'black', // Color of the dot
                                borderRadius: '50%', // Make it circular
                                mx: 1, // Horizontal margin for spacing
                              }}
                            />

                            <TripCountry>{country.countryName}</TripCountry>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                </Stack>
              </StyledCardContent>
            </StyledCard>
          ))}
      </StyledGrid>
      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
        <ScrollButtons>
          <Box onClick={() => sideScroll(contentWrapper!.current!, 1, 420, -5)}>
            <ButtonBackground>
              <ChevronLeftIcon />
            </ButtonBackground>
          </Box>
          <ButtonBackground
            onClick={() => sideScroll(contentWrapper!.current!, 1, 420, 5)}
          >
            <ChevronRightIcon />
          </ButtonBackground>
        </ScrollButtons>
        <Box height={200} />
      </Box>
    </>
  );
}

// const sideScroll = (
//   element: HTMLDivElement,
//   speed: number,
//   distance: number,
//   step: number,
// ) => {
//   let scrollAmount = 0;
//   const slideTimer = setInterval(() => {
//     element.scrollLeft += step;
//     scrollAmount += Math.abs(step);
//     if (scrollAmount >= distance) {
//       clearInterval(slideTimer);
//     }
//   }, speed);
// };
const sideScroll = (
  element: HTMLDivElement,
  speed: number,
  distance: number,
  step: number,
) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);

  // Return a cleanup function
  return () => clearInterval(slideTimer);
};
