import { useQuery } from '@apollo/client';
import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import AuthorizedApolloProvider from '../AuthorizedApolloProvider';
import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { QUERY_GET_TRIP } from '../components/gql-public/tripQuery';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import AuthorisedUserTrips from '../components/trip-details/AuthorisedUserTrips';
import { TripDetails } from '../components/trip-details/TripDetails';
import { TripHeader } from '../components/trip-details/TripHeader';
import { TripItinerary } from '../components/trip-details/TripItinerary';
import { TripMap } from '../components/trip-details/TripMap';
import { Trip, Itinerary } from '../generated/public_graphql';
import { setPublicTrip } from '../store/PublicTripSlice';
import { isRunningStandalone } from '../utils/helpers';
import PublicTrip from '../components/trip-details/Trip';

export default function TripRoute() {
  gtag('event', 'public-trip-page');
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  return (
    <RoutePage>
      <PublicTrip />
      {isStandalone && (
        <Box
          sx={{
            paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
          }}
        />
      )}
    </RoutePage>
  );
}
