import { gql } from 'graphql-tag';

export const MUTATION_DELETE_USER_IMAGE = gql`
  mutation deleteUserImage($id: String!) {
    deleteUserImage(id: $id) {
      id
    }
  }
`;

export const MUTATION_DELETE_STEP_PLACE_IMAGE = gql`
  mutation deleteStepPlaceImage($step_place_image_id: String!) {
    deleteStepPlaceImage(step_place_image_id: $step_place_image_id) {
      id
    }
  }
`;
