import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import { Divider, Stack, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { useSelector } from 'react-redux';

import { tripsSelector } from '../../../store/TripSlice';
import { Body1, TitleH2 } from '../../../theme-components/Typography';
import { RenderHtmlFromResponseNoMargin } from '../../RenderHtmlFromResponse';
import EditTripDescriptionModal from '../manage-trip-modals/AddEditDescription';
import AddStartDateModal from '../manage-trip-modals/AddStartDateModal';

import { ActionsTrip } from './ActionsTrip';

const TripCountry = styled(Typography)(({ theme }) => ({
  alignSelf: 'center',
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: 16,
  },
}));

export function CreateTripDetails(props: {
  tripId: string;
  tripName: string;
  tripDescription: string;
  edit: boolean;
  showTripActions: boolean;
}) {
  const { trip } = useSelector(tripsSelector);

  return (
    <>
      <Box
        sx={theme => ({
          width: '100%',
          paddingLeft: 2,
          // paddingRight: 2,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        })}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Stack
            direction="row"
            justifyContent="space-between"
            // alignItems="center"
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Stack
              direction="row"
              // justifyContent="space-between"
              alignItems="center"
              sx={{
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <CardTravelTwoToneIcon
                sx={theme => ({
                  marginLeft: 0,
                  marginRight: 1,
                  width: 25,
                  height: 25,
                  alignSelf: 'center',
                  color: theme.palette.icon.main,
                  [theme.breakpoints.down('sm')]: {
                    marginLeft: 0,
                  },
                })}
              />
              <TitleH2>{props.tripName}</TitleH2>
            </Stack>
            {props.showTripActions && trip && (
              <Box
                sx={theme => ({
                  // zoom: 1.11,
                  marginTop: 1,
                })}
              >
                <ActionsTrip trip={trip} />
              </Box>
            )}
          </Stack>
        </Box>

        {trip && (
          <TripCountry>
            {(!trip.tripType || trip.tripType !== 'guide') && trip.length && (
              <>
                {trip.length + ' days itinerary'}
                {' ('}
              </>
            )}

            {(!trip.tripType || trip.tripType !== 'guide') && (
              <>
                {trip &&
                  trip.itineraryCountries &&
                  trip.itineraryCountries.length > 0 &&
                  trip.itineraryCountries.map((country, index) => (
                    <React.Fragment key={index}>
                      {country && (
                        <>
                          {country.countryName}
                          {trip.itineraryCountries &&
                            index !== trip.itineraryCountries.length - 1 && (
                              <>{', '}</>
                            )}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                {trip.length && <>{')'}</>}
              </>
            )}
          </TripCountry>
        )}
        {/* <StartDay> */}
        {trip && (!trip.tripType || trip.tripType !== 'guide') && (
          <>
            {trip.startDate ? (
              <AddStartDateModal
                tripId={trip.id}
                date={trip.startDate}
                add={false}
              />
            ) : (
              <AddStartDateModal
                tripId={trip!.id}
                date={new Date()}
                add={true}
              />
            )}
          </>
        )}

        {/* </StartDay> */}
        <Divider
          sx={theme => ({
            marginBottom: theme.spacing(1),
          })}
        />
        <Body1>
          <RenderHtmlFromResponseNoMargin content={props.tripDescription} />
        </Body1>
        {props.edit && (
          <EditTripDescriptionModal
            description={props.tripDescription}
            editTripId={props.tripId}
            tripType={trip && trip.tripType ? trip.tripType : undefined}
            tripActions={false}
          />
        )}
        <Divider
          sx={theme => ({
            marginBottom: theme.spacing(1),
          })}
        />
      </Box>
    </>
  );
}
