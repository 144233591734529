import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDays } from '../../../store/DaySlice';
import { setErrorModalOpen, setErrorTitle } from '../../../store/ErrorSlice';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../theme-components/Buttons';
import { Loader } from '../../../theme-components/Loader';
import { MUTATION_DELETE_STEP } from '../../gql-user/deleteStepMutation';
import { VerticalFlex } from '../../helpers/flex';
import {
  SmallModalPaper,
  ModalDescription,
  ModalTitle,
  CenteredModal,
} from '../../styling/modal';

export const DeleteStepModal: React.FC<{
  stepId: any;
  handleCloseModal: () => void;
}> = ({ stepId, handleCloseModal }) => {
  // export default function DeleteStepModal(props: { step: Step }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [deleteStep, { loading: deleteStepLoading }] = useMutation(
    MUTATION_DELETE_STEP,
    {
      variables: {
        step_id: stepId,
      },
    },
  );

  const closeModal = () => {
    setOpen(false);
    handleCloseModal();
  };

  return (
    <>
      <Box
        onClick={() => {
          gtag('event', 'delete-step-button');
          setOpen(true);
        }}
        sx={{ width: '100%' }}
      >
        Delete
      </Box>

      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
      >
        <SmallModalPaper>
          <ModalTitle>Are you sure?</ModalTitle>
          <ModalDescription>This can not be undone!</ModalDescription>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            mt={2}
          >
            <VerticalFlex>
              <Box>
                <SecondaryButton
                  disabled={deleteStepLoading}
                  onClick={async () => {
                    gtag('event', 'delete-step-close');
                    setOpen(false);
                    closeModal();
                  }}
                  sx={{ marginRight: 1 }}
                >
                  Close
                </SecondaryButton>
                <PrimaryButton
                  disabled={deleteStepLoading}
                  onClick={async () => {
                    gtag('event', 'delete-step-confirm');
                    try {
                      const res = await deleteStep();
                      if (
                        res &&
                        res.data &&
                        res.data.deleteUserStep &&
                        res.data.deleteUserStep.days
                      ) {
                        dispatch(setDays(res.data.deleteUserStep.days));
                        closeModal();
                      }
                    } catch (e) {
                      gtag('event', 'error-delete-step');
                      dispatch(setErrorTitle('Error deleting activity'));
                      dispatch(setErrorModalOpen(true));
                    }
                  }}
                >
                  {deleteStepLoading ? <Loader /> : 'Delete'}
                </PrimaryButton>
              </Box>
            </VerticalFlex>
          </Box>
        </SmallModalPaper>
      </CenteredModal>
    </>
  );
};
