import { gql } from 'graphql-tag';

export const QUERY_GET_PUBLISHED_TRIPS_FOR_COUNTRY = gql`
  query searchTripsByCountry($country_id: String!) {
    searchTripsByCountry(country_id: $country_id) {
      id
      name
      length
      published
      tripType
      publish_pending
      countries {
        id
        name
        description
        url
        iso2Code
        iso3Code
      }
      itineraryCountries {
        countryName
        itineraryId
      }
    }
  }
`;
