import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FullScreenBusAnimation } from '../components/animations/BusAnimation';
import { QUERY_GET_USER } from '../components/gql-user/user';
import { QUERY_USER_TRIP } from '../components/gql-user/userTrip';
import { QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY } from '../components/gql-user/userTripsQuery';
import { RouteContent, RoutePage, RouteTitle } from '../components/route';
import { TripDetails } from '../components/trip-details/TripDetails';
import { TripHeader } from '../components/trip-details/TripHeader';
import { TripItinerary } from '../components/trip-details/TripItinerary';
import { TripMap } from '../components/trip-details/TripMap';
import { User } from '../generated/user_graphql';
import { setPublicTrip } from '../store/PublicTripSlice';
import { setUserTrips } from '../store/TripSlice';
import { setUser } from '../store/UserSlice';
import { isRunningStandalone } from '../utils/helpers';
import { LoaderFullScreen } from '../theme-components/Loader';
import { useAuth } from '../components/auth/firebase';

export default function TripPreviewRoute() {
  const { isLoading } = useAuth();
  const { id } = useParams<{ id: string }>();
  gtag('event', 'trip-preview-page');
  const dispatch = useDispatch();

  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
  }, []);

  const { data, error, loading } = useQuery(QUERY_USER_TRIP, {
    variables: { trip_id: id },
  });

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setPublicTrip(data.userTrip));
    }
  }, [data, dispatch]); // dependencies for useEffect

  const [getUserTrips, { data: userTripsData }] = useLazyQuery(
    QUERY_GET_USER_TRIPS_WITH_DAYS_ONLY,
  );

  const [getUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    QUERY_GET_USER,
  );

  useEffect(() => {
    const fetchUserTrips = async () => {
      await getUserTrips();
      await getUser();
    };

    fetchUserTrips();
  }, [getUserTrips, getUser]);

  useEffect(() => {
    if (
      userTripsData &&
      userTripsData.userTripsWithDaysOnly &&
      userTripsData.userTripsWithDaysOnly.length > 0
    ) {
      dispatch(setUserTrips(userTripsData.userTripsWithDaysOnly));
    }
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userTripsData, dispatch, userData]); // dependencies for useEffect

  if (isLoading) {
    return <LoaderFullScreen />;
  }

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if ((!id || error || !data) && !loading) {
    return (
      <RoutePage>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>Trip id not found</RouteContent>
      </RoutePage>
    );
  }

  return (
    <RoutePage>
      <TripHeader tripType="preview" />
      <Stack
        direction="row"
        alignItems="left"
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={theme => ({
            display: 'block',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              display: 'none',
            },
          })}
        >
          <TripMap tripType="preview" />
        </Box>
        <Box
          sx={theme => ({
            flex: '1 0 0',
            marginLeft: 0,
            width: '50%',
            [theme.breakpoints.down('sm')]: {
              marginLeft: 0,
              width: '100%',
            },
          })}
        >
          <Stack
            direction="column"
            alignItems="top"
            sx={theme => ({
              width: '100%',
              marginLeft: 1,
              [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
              },
            })}
          >
            <TripDetails tripType="preview" />
            <TripItinerary
              tripType="preview"
              startDate={data.userTrip.startDate}
            />
          </Stack>
        </Box>
      </Stack>
      {isStandalone && (
        <Box
          sx={{
            paddingBottom: 'calc(70px + env(safe-area-inset-bottom))',
          }}
        />
      )}
      {/* This gets user trips on page load so they can easilly add any item to the trip */}
      {/* <AuthorisedUserTrips /> */}
    </RoutePage>
  );
}
