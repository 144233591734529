import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import TravelExploreTwoToneIcon from '@mui/icons-material/TravelExploreTwoTone';
import {
  Box,
  Container,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '../theme-components/Buttons';
import { isRunningStandalone } from '../utils/helpers';

export default function AppFooter() {
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  return (
    <>
      {isStandalone ? (
        <>
          <BottomTabBar />
        </>
      ) : (
        <Box
          boxShadow={2}
          sx={{ position: 'absolute', width: '100%', height: 100 }}
        >
          <Container>
            <Box sx={{ paddingTop: 2, textAlign: 'center' }}>
              © 2024 rooutie.com &middot; All rights reserved
            </Box>
            <Box
              sx={theme => ({
                color: theme.palette.secondary.main,
                textAlign: 'center',
                paddingBottom: 2,
              })}
            >
              <ContactMeGate />
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
}
// TODO: fix this in footer
// interface IContactMeGateProps {}
// interface IContactMeHrefProps {}

const ContactMeHref: React.FC<unknown> = props => {
  return (
    <div>
      <a href="mailto:info@rooutie.com">info@rooutie.com</a>
    </div>
  );
};

// Make user click a button to show email adderss via lazy loading
const ContactMeGate: React.FC<unknown> = props => {
  const [showingEmail, setShowingEmail] = React.useState(false);

  const email = showingEmail ? (
    <ContactMeHref />
  ) : (
    <TextButton onClick={() => setShowingEmail(true)}>
      Click for contact info
    </TextButton>
  );
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <div>{email}</div>
    </React.Suspense>
  );
};

const BottomTabBar = () => {
  const [value, setValue] = useState(0); // State to manage active tab
  const navigate = useNavigate(); // React Router navigation hook

  // Handle tab change and navigation
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate('/explore'); // Navigate to "My Account" (Home)
    } else if (newValue === 1) {
      navigate('/my-trips'); // Navigate to "Main" (Explore)
    } else if (newValue === 2) {
      navigate('/my-account'); // Navigate to "Help"
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 960,
        paddingTop: 1,
        backgroundColor: '#FFFFFF',
        overflow: 'visible',
        paddingBottom: 'calc(22px + env(safe-area-inset-bottom))',
        borderTop: '1px solid', // Add top border
        borderColor: 'rgba(0, 0, 0, 0.12)', // Customize the color of the border
      }}
    >
      <BottomNavigation
        value={value}
        onChange={handleChange}
        // sx={{
        //   paddingTop: 1,
        //   paddingBottom: 'calc(14px + env(safe-area-inset-bottom))',
        // }}
      >
        <BottomNavigationAction
          label="Explore"
          showLabel={true}
          icon={<TravelExploreTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
        <BottomNavigationAction
          label="My trips"
          showLabel={true}
          icon={<CardTravelTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
        <BottomNavigationAction
          label="Account"
          showLabel={true}
          icon={<AccountCircleTwoToneIcon sx={{ height: 30, width: 30 }} />}
        />
      </BottomNavigation>
    </Box>
  );
};
