import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProminentButtonThin } from '../theme-components/Buttons';
import { isRunningStandalone } from '../utils/helpers';

import { useAuth } from './auth/firebase';
import { ModernLogo } from './logo/Logo1';
import { RoutePage } from './route';

const LinkButton = styled(Box)(({ theme }) => ({
  // marginLeft: 1,
  fontWeight: 600,
  fontSize: 16,
  padding: 2,

  color: '#3F3D56',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 4,
    fontSize: 24,
    // marginLeft: 5,
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30,
  },
}));

const BoxIconName = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}));

const BoxName = styled(Box)(({ theme }) => ({
  fontFamily: `"Prosto One", cursive`,
  marginLeft: 5,
  fontSize: 40,
  fontWeight: 600,
  color: '#3F3D56',
  alignSelf: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: 30,
    marginLeft: 10,
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  // color: '#3F3D56',
  opacity: 1,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export default function AppBar() {
  const navigate = useNavigate();
  const [isStandalone, setIsStandalone] = useState<boolean>(false); // Default true
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    // Run isRunningStandalone only after the component has mounted
    if (typeof window !== 'undefined') {
      const result = isRunningStandalone();
      setIsStandalone(result);
    }
  }, []); // Empty dependency array ensures this runs only after initial render (on mount)

  return (
    <Box
      boxShadow={1}
      sx={theme => ({
        backgroundColor: '#FFFFFF',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 2,
        paddingBottom: 2,
        maxHeight: '70px',
        overflow: 'visible',
        position: isStandalone ? 'sticky' : 'relative', // Conditionally sticky
        top: isStandalone ? 0 : 'unset', // Stick to the top when in standalone mode
        zIndex: 5000, // Ensure it stays above other content
        [theme.breakpoints.down('sm')]: {
          maxHeight: '50px',
          position: 'sticky',
          top: 0,
        },
      })}
    >
      <RoutePage>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={isStandalone ? 'center' : 'space-between'}
          // justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            // flexGrow={isStandalone ? 0 : 1}
          >
            <BoxIconName
              onClick={() => {
                gtag('event', 'app-bar-rooutie-logo');
                navigate('/');
              }}
            >
              <Logo>
                <ModernLogo />
              </Logo>
              <BoxName>rooutie</BoxName>
            </BoxIconName>
          </Box>
          {!isStandalone && (
            <>
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <CustomButton
                  disableRipple
                  disableFocusRipple
                  href="#"
                  onClick={() => {
                    gtag('event', 'app-bar-create-trip');
                    navigate('/my-trips');
                  }}
                >
                  {/* <RightBox border={1} borderColor="#484848"> */}
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  <LinkButton>Create trip</LinkButton>
                  {/* <Box sx={{ marginTop: -2, marginLeft: -2 }}> */}
                  {/* <NewLabel>NEW</NewLabel> */}
                  <StarIcon
                    sx={theme => ({
                      width: 20,
                      height: 20,
                      color: '#E6B800',
                      [theme.breakpoints.down('sm')]: {
                        width: 20,
                        height: 20,
                      },
                    })}
                  />
                  {/* </Box> */}
                  {/* </RightBox> */}
                </CustomButton>
                <CustomButton
                  disableRipple
                  disableFocusRipple
                  href="#"
                  onClick={() => {
                    gtag('event', 'app-bar-create-trip');
                    navigate('/explore');
                  }}
                >
                  {/* <RightBox border={1} borderColor="#484848"> */}
                  <LinkButton>Countries</LinkButton>
                  {/* </RightBox> */}
                </CustomButton>
                <CustomButton
                  disableRipple
                  disableFocusRipple
                  href="#"
                  onClick={() => {
                    gtag('event', 'app-bar-get-the-app');
                    navigate('/download');
                  }}
                >
                  {/* <RightBox border={1} borderColor="#484848"> */}
                  <LinkButton>Get The App</LinkButton>
                  {/* </RightBox> */}
                </CustomButton>
              </Box>
              <Stack direction="row" alignItems="center">
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <CustomButton
                    disableRipple
                    disableFocusRipple
                    href="#"
                    onClick={() => {
                      gtag('event', 'app-bar-create-trip');
                      navigate('/my-trips');
                    }}
                  >
                    {/* <RightBox border={1} borderColor="#484848"> */}
                    {isAuthenticated ? (
                      <LinkButton>My trips</LinkButton>
                    ) : (
                      <LinkButton>Login</LinkButton>
                    )}
                    {/* </RightBox> */}
                  </CustomButton>
                </Box>

                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                  <CustomButton
                    disableRipple
                    disableFocusRipple
                    href="#"
                    onClick={() => {
                      gtag('event', 'app-bar-create-trip');
                      navigate('/my-account');
                    }}
                  >
                    {/* <RightBox border={1} borderColor="#484848"> */}
                    {isAuthenticated ? (
                      <LinkButton>Account</LinkButton>
                    ) : (
                      <ProminentButtonThin>Sign up</ProminentButtonThin>
                    )}

                    {/* </RightBox> */}
                  </CustomButton>
                </Box>
              </Stack>
              {/* </UserIconButton> */}
              {/* </RightBox> */}
              {/* </CustomIconButton> */}
              <Box sx={{ display: { sm: 'block', md: 'none' } }}>
                <HamburgerMenu />
              </Box>
            </>
          )}
        </Box>
      </RoutePage>
    </Box>
  );
}

const HamburgerMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  // Toggle drawer open/close
  const toggleDrawer = open => event => {
    setIsDrawerOpen(open);
  };

  return (
    <>
      {/* AppBar with Hamburger Icon */}

      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ width: 40, height: 40, cursor: 'pointer' }} />
      </IconButton>

      {/* Drawer for the Hamburger Menu */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          zIndex: 10000,
        }}
        PaperProps={{
          sx: {
            height: 'auto',
            maxHeight: '100%',
            top: 'auto',
            width: '100%',
            padding: 4,
          }, // Ensure drawer height is auto and not full height
        }}
      >
        <Box
          sx={{
            display: 'flex',
            paddingLeft: 2,
            width: '100%',
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          alignItems="flex-start"
        >
          <Stack
            direction="column"
            alignItems="flex-start"
            sx={{ width: '100%' }}
          >
            <Stack
              direction="row"
              justifyContent="flex-end" // Aligns items to the right
              sx={{ width: '100%', paddingBottom: 2 }} // Optional padding
            >
              <CloseTwoToneIcon
                onClick={toggleDrawer(false)}
                sx={{ width: 40, height: 40, cursor: 'pointer' }} // Add cursor pointer for better UX
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              onClick={() => {
                gtag('event', 'app-bar-menu-account');
                navigate('/my-account');
              }}
            >
              <CustomButton disableRipple disableFocusRipple>
                {isAuthenticated ? (
                  <LinkButton>Account</LinkButton>
                ) : (
                  <ProminentButtonThin>Sign up</ProminentButtonThin>
                )}
              </CustomButton>
              <ArrowForwardIosTwoToneIcon />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              onClick={() => {
                gtag('event', 'app-bar-menu-my-trips');
                navigate('/my-trips');
              }}
            >
              <CustomButton disableRipple disableFocusRipple>
                {/* <RightBox border={1} borderColor="#484848"> */}
                {isAuthenticated ? (
                  <LinkButton>My trips</LinkButton>
                ) : (
                  <LinkButton>Login</LinkButton>
                )}
                {/* </RightBox> */}
              </CustomButton>
              <ArrowForwardIosTwoToneIcon />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              onClick={() => {
                gtag('event', 'app-bar-menu-get-the-app');
                navigate('/download');
              }}
            >
              <CustomButton disableRipple disableFocusRipple>
                <LinkButton>Get The App</LinkButton>
              </CustomButton>
              <ArrowForwardIosTwoToneIcon />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              onClick={() => {
                gtag('event', 'app-bar-menu-countries');
                navigate('/explore');
              }}
            >
              <CustomButton disableRipple disableFocusRipple>
                <LinkButton>Countries</LinkButton>
              </CustomButton>
              <ArrowForwardIosTwoToneIcon />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: '100%' }}
              onClick={() => {
                gtag('event', 'app-bar-menu-create-trip');
                navigate('/my-trips');
              }}
            >
              <CustomButton disableRipple disableFocusRipple>
                <LinkButton>Create trip</LinkButton>
                <StarIcon
                  sx={theme => ({
                    width: 20,
                    height: 20,
                    color: '#E6B800',
                    [theme.breakpoints.down('sm')]: {
                      width: 20,
                      height: 20,
                    },
                  })}
                />
              </CustomButton>
              <ArrowForwardIosTwoToneIcon />
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
