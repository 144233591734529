import AddLocationTwoToneIcon from '@mui/icons-material/AddLocationTwoTone';
import DirectionsSubwayFilledTwoToneIcon from '@mui/icons-material/DirectionsSubwayFilledTwoTone';
import FastfoodTwoToneIcon from '@mui/icons-material/FastfoodTwoTone';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  resetAccommodation,
  accommodationsSelector,
} from '../../../../store/AccommodationSlice';
import {
  setAddStepModalOpen,
  setStepDayId,
  resetStep,
  stepsSelector,
} from '../../../../store/StepSlice';
import { TextButton } from '../../../../theme-components/Buttons';
import {
  ModalDescription,
  ModalTitle,
  MediumModalPaper,
  CenteredModal,
} from '../../../styling/modal';

import { AddAccommodationInfo } from './AddAccommodationInfo';
import { AddPhotosToAccommodation } from './AddAccommodationPhotos';
import { AddStep } from './AddStep';
import { AddStepInfo } from './AddStepInfo';
import { AddPhotosAndAdditionalInfoToStep } from './AddStepPhotos';

export const AddStepToDay: React.FC<{
  dayId: string;
  category:
    | 'CATEGORY_ACCOMMODATION'
    | 'CATEGORY_TRANSPORT'
    | 'CATEGORY_PLACE'
    | 'CATEGORY_ACTIVITY'
    | 'CATEGORY_FOOD_DRINK';
  iconsOnly: boolean;
  modalOpen: boolean;
  handleCloseModal: () => void;
}> = ({ dayId, category, iconsOnly, modalOpen, handleCloseModal }) => {
  const dispatch = useDispatch();
  const [isManual, setIsManual] = useState(false);

  const {
    stepId,
    stepDescriptionAdded,
    stepAddStepModalOpen,
    stepDayId,
    stepType,
  } = useSelector(stepsSelector);

  const { accommodationId, accommodationDescriptionAdded } = useSelector(
    accommodationsSelector,
  );

  // Handle side effects when modal is opened or closed
  useEffect(() => {
    if (!stepAddStepModalOpen) {
      dispatch(resetAccommodation());
      dispatch(resetStep());
    }
  }, [stepAddStepModalOpen, dispatch]);

  const handleOpenModal = () => {
    gtag('event', 'add-new-step-button');
    setIsManual(false);
    dispatch(setStepDayId(dayId));
    dispatch(setAddStepModalOpen(true));
  };

  const closeModal = () => {
    gtag('event', 'close-add-new-step-button');
    setIsManual(false);
    dispatch(setAddStepModalOpen(false));
    dispatch(setStepDayId(''));
    handleCloseModal();
  };

  return (
    <>
      {category === 'CATEGORY_PLACE' && (
        <Stack
          direction="row"
          spacing={iconsOnly ? 0.5 : 1}
          width="100%"
          onClick={() => {
            handleOpenModal();
          }}
          key={0}
        >
          <AddLocationTwoToneIcon />
          {iconsOnly ? <Box>{'Place'}</Box> : <Box>{'Add place'}</Box>}
        </Stack>
      )}
      {category === 'CATEGORY_ACCOMMODATION' && (
        <Stack
          direction="row"
          spacing={iconsOnly ? 0.5 : 1}
          width="100%"
          onClick={() => {
            handleOpenModal();
          }}
          key={1}
        >
          <HotelTwoToneIcon />
          {iconsOnly ? <Box>{'Stay'}</Box> : <Box>{'Add stay'}</Box>}
        </Stack>
      )}
      {category === 'CATEGORY_FOOD_DRINK' && (
        <Stack
          direction="row"
          spacing={iconsOnly ? 0.5 : 1}
          width="100%"
          onClick={() => {
            handleOpenModal();
          }}
          key={2}
        >
          <FastfoodTwoToneIcon />
          {iconsOnly ? (
            <Box>{'Eat & drink'}</Box>
          ) : (
            <Box>{'Add food or drink'}</Box>
          )}
        </Stack>
      )}
      {category === 'CATEGORY_TRANSPORT' && (
        <Stack
          direction="row"
          spacing={iconsOnly ? 0.5 : 1}
          width="100%"
          onClick={() => {
            handleOpenModal();
          }}
          key={3}
        >
          <DirectionsSubwayFilledTwoToneIcon />
          {iconsOnly ? <Box>{'Transport'}</Box> : <Box>{'Add transport'}</Box>}
        </Stack>
      )}
      {category === 'CATEGORY_ACTIVITY' && (
        <Stack
          direction="row"
          spacing={iconsOnly ? 0.5 : 1}
          width="100%"
          onClick={() => {
            handleOpenModal();
          }}
          key={4}
        >
          <LocalActivityTwoToneIcon />
          {iconsOnly ? <Box>{'Activity'}</Box> : <Box>{'Add activity'}</Box>}
        </Stack>
      )}

      {/* <ModalBox> */}
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={modalOpen && dayId === stepDayId}
        onClose={closeModal}
        // closeAfterTransition
      >
        <MediumModalPaper>
          {stepId === '' && (
            <Box>
              {category === 'CATEGORY_PLACE' && (
                <>
                  <ModalTitle>Add place</ModalTitle>

                  <ModalDescription>
                    {isManual
                      ? 'Enter the name of place you are visiting'
                      : 'Easily copy Google Maps place sharable URL and paste bellow to add place to your itinerary.'}
                  </ModalDescription>
                </>
              )}
              {category === 'CATEGORY_ACCOMMODATION' && (
                <>
                  <ModalTitle>Where are you staying?</ModalTitle>
                  <ModalDescription>
                    {isManual
                      ? 'Enter the name of the place you are going to stay'
                      : 'Easily copy your hotel sharable URL from Google Maps and paste bellow to add to your itinerary.'}
                  </ModalDescription>
                </>
              )}
              {category === 'CATEGORY_FOOD_DRINK' && (
                <>
                  <ModalTitle>Add food or drink</ModalTitle>
                  <ModalDescription>
                    {isManual
                      ? 'Enter the name of place you are visiting'
                      : 'Easily copy any place URL from Google Maps and paste bellow to add to your itinerary.'}
                  </ModalDescription>
                </>
              )}
              {category === 'CATEGORY_TRANSPORT' && (
                <>
                  <ModalTitle>Add transport</ModalTitle>
                  <ModalDescription>
                    {isManual
                      ? 'Enter the title like e.g. Drive 45 minutes'
                      : 'Easily copy any place URL from Google Maps and paste bellow to add to your itinerary.'}
                  </ModalDescription>
                </>
              )}
              {category === 'CATEGORY_ACTIVITY' && (
                <>
                  <ModalTitle>Add activity</ModalTitle>
                  <ModalDescription>
                    {isManual
                      ? 'Enter the activity name'
                      : 'Easily copy any place URL of your Activity from Google Maps and paste bellow to add to your itinerary.'}
                  </ModalDescription>
                </>
              )}
              <div style={{ position: 'relative' }}>
                {/* Ensure this container has position: relative */}
                <AddStep
                  dayId={dayId}
                  isManual={isManual}
                  handleCloseModal={closeModal}
                />
                <TextButton
                  onClick={() => {
                    gtag('event', 'edit-trip-images-button');
                    setIsManual(!isManual);
                  }}
                  sx={{
                    position: 'absolute',
                    bottom: 10,
                    zIndex: 1,
                  }}
                >
                  {isManual ? 'Quick add' : 'Add manually'}
                </TextButton>
              </div>
            </Box>
          )}

          {stepId !== '' && (
            <Box>
              {stepType !== 'CATEGORY_ACCOMMODATION' &&
                stepType !== 'CATEGORY_TRANSPORT' && (
                  <Box>
                    {!stepDescriptionAdded && (
                      <Box>
                        <ModalTitle>Add more details</ModalTitle>
                        <ModalDescription>
                          Briefly describe what will you see or do here.
                        </ModalDescription>
                        <AddStepInfo handleCloseModal={closeModal} />
                      </Box>
                    )}
                    {stepDescriptionAdded && (
                      <Box>
                        <ModalTitle>Add photos</ModalTitle>
                        <ModalDescription>
                          Add photo to this activity so it is easier to
                          visualise what is it about.
                        </ModalDescription>
                        <AddPhotosAndAdditionalInfoToStep
                          handleCloseModal={closeModal}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              {stepType === 'CATEGORY_TRANSPORT' && (
                <Box>
                  {!stepDescriptionAdded && (
                    <Box>
                      <ModalTitle>Add description</ModalTitle>
                      <ModalDescription>
                        Describe steps you need to take to get to destination.
                        This is useful if you need to use public transport or
                        add specific details about transport e.g. which taxi
                        company to use, what bus number to take ...
                      </ModalDescription>
                      <AddStepInfo handleCloseModal={closeModal} />
                    </Box>
                  )}
                  {stepDescriptionAdded && (
                    <Box>
                      <ModalTitle>Add photos</ModalTitle>
                      <ModalDescription>
                        Add photo so it is easier to navigate.
                      </ModalDescription>
                      <AddPhotosAndAdditionalInfoToStep
                        handleCloseModal={closeModal}
                      />
                    </Box>
                  )}
                </Box>
              )}
              {accommodationId && stepType === 'CATEGORY_ACCOMMODATION' && (
                <Box>
                  {!accommodationDescriptionAdded && (
                    <Box>
                      <ModalTitle>More info</ModalTitle>
                      <ModalDescription>
                        Add URL link to access it easily later on and any more
                        info about this accommodation you need.
                      </ModalDescription>
                      <AddAccommodationInfo handleCloseModal={closeModal} />
                    </Box>
                  )}
                  {/* {accommodationDescriptionAdded &&
                      !accommodationLocationAdded && (
                        <Box>
                          <ModalTitle>Add location</ModalTitle>
                          <ModalDescription>
                            Add exact location of your accommodation so you can
                            find it easily when on the go.
                          </ModalDescription>
                          <AddAccommodationLocation />
                        </Box>
                      )} */}
                  {accommodationDescriptionAdded && (
                    <Box>
                      <ModalTitle>Add photos</ModalTitle>
                      <ModalDescription>
                        Add photo to this accommodation so it is easier to see
                        where you stay.
                      </ModalDescription>
                      <AddPhotosToAccommodation handleCloseModal={closeModal} />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </MediumModalPaper>
      </CenteredModal>
    </>
  );
};
