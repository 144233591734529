// import {
//   ApolloClient,
//   ApolloProvider,
//   HttpLink,
//   InMemoryCache,
// } from '@apollo/client';
// import { ReactNode } from 'react';

// interface Props {
//   readonly children?: ReactNode;
// }

// const UnauthorizedApolloProvider = ({ children }: Props) => {
//   const httpLink = new HttpLink({
//     uri: process.env.REACT_APP_CONFIG_URL,
//   });

//   const apolloClient = new ApolloClient({
//     link: httpLink,
//     cache: new InMemoryCache(),
//   });

//   return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
// };

// export default UnauthorizedApolloProvider;
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { ReactNode } from 'react';

interface Props {
  readonly children?: ReactNode;
}

// Export the unauthorized Apollo Client instance
const unauthorizedClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_CONFIG_URL, // Unauthorized API URL
  }),
  cache: new InMemoryCache(),
});

const UnauthorizedApolloProvider = ({ children }: Props) => {
  return (
    <ApolloProvider client={unauthorizedClient}>{children}</ApolloProvider>
  );
};

export { unauthorizedClient }; // Export the client to use it manually
export default UnauthorizedApolloProvider;
