import { useMutation, useQuery } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import {
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Button,
  Avatar,
  IconButton,
  Box,
  Tooltip,
  Divider,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { User } from '../../generated/user_graphql';
import { setUser, userSelector } from '../../store/UserSlice';
import { PrimaryButton } from '../../theme-components/Buttons';
import { Loader } from '../../theme-components/Loader';
import {
  isPublisher,
  returnFormatedDate,
  useUserRoles,
} from '../../utils/helpers';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import {
  MUTATION_UPDATE_USER,
  MUTATION_UPSERT_INSURANCE,
  QUERY_GET_USER,
} from '../gql-user/user';
import { CenteredModal, MediumModalPaper, ModalTitle } from '../styling/modal';

import LogoutButton from './LogoutButton';

export function Profile() {
  const roles = useUserRoles();
  const dispatch = useDispatch();

  const { data } = useQuery<{ getUser: User }>(QUERY_GET_USER);

  useEffect(() => {
    if (data && data.getUser) {
      dispatch(setUser(data.getUser));
    }
  }, [data, dispatch]);

  return (
    <Box>
      <UserProfileCard />
      {isPublisher(roles) && (
        <Stack direction="row" alignItems="center" spacing={0.5} mt={2}>
          <Typography>Thanks for adding all the trips, you are a </Typography>
          <StarIcon
            sx={theme => ({
              width: 20,
              height: 20,
              marginRight: 10,
              color: '#E6B800',
              [theme.breakpoints.down('sm')]: {
                width: 20,
                height: 20,
              },
            })}
          />
        </Stack>
      )}
    </Box>
  );
}

export default Profile;

type ModalContentType = 'passportExpiry' | 'insuranceExpiry' | null;

const UserProfileCard = () => {
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [nickName, setNickName] = useState('');
  const [tikTokUrl, setTikTokUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [youTubeUrl, setYouTubeUrl] = useState('');
  const [modalContent, setModalContent] = useState<ModalContentType>(null);
  const [pasportExpiry, setPasportExpiry] = useState<Date | null>(null);
  const [insuranceExpiry, setInsuranceExpiry] = useState<Date | null>(null);
  const [insuranceContactNo, setInsuranceContactNo] = useState('');
  const [insurancePolicyNo, setInsurancePolicyNo] = useState('');
  useState(false);
  const [isEditingPolicyNumber, setIsEditingPolicyNumber] = useState(false);
  const [isEditingInsuranceContact, setIsEditingInsuranceContact] =
    useState(false);

  const [isEditingTikTok, setIsEditingTikTok] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [isEditingYouTube, setIsEditingYouTube] = useState(false);
  const { myUser } = useSelector(userSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [updateUser, { loading: updateUserLoading, data: updateUserData }] =
    useMutation<{ updateUser: User }>(MUTATION_UPDATE_USER, {
      variables: {
        ...(nickName.trim() && { nickname: nickName.trim() }),
        ...(pasportExpiry && { pasport_expiry_date: pasportExpiry }),
        ...(instagramUrl && { instagram_url: instagramUrl }),
        ...(tikTokUrl && { tik_tok_url: tikTokUrl }),
        ...(youTubeUrl && { you_tube_url: youTubeUrl }),
      },
    });

  const [
    updateInsurance,
    { loading: updateInsuranceLoading, data: updateInsuranceData },
  ] = useMutation<{ updateInsurance: User }>(MUTATION_UPSERT_INSURANCE, {
    variables: {
      ...(insuranceExpiry && { insurance_expiry_date: insuranceExpiry }),
      ...(insuranceContactNo.trim() && {
        contact_number: insuranceContactNo.trim(),
      }),
      ...(insurancePolicyNo.trim() && {
        policy_number: insurancePolicyNo.trim(),
      }),
    },
  });

  useEffect(() => {
    if (updateUserData && updateUserData.updateUser) {
      // setUserData(updateUserData.updateUser);
      dispatch(setUser(updateUserData.updateUser));
    }

    if (updateInsuranceData && updateInsuranceData.updateInsurance) {
      // setUserData(updateUserData.updateUser);
      dispatch(setUser(updateInsuranceData.updateInsurance));
    }
  }, [updateUserData, updateInsuranceData, dispatch]);

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          // p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2} // Add some spacing between Avatar and the text content
            sx={{
              width: '100%',
              maxWidth: '100%',
              p: 2, // Padding around the container for breathing room
            }}
          >
            {/* Profile Picture */}
            <Tooltip title="Upload coming soon!">
              <Avatar
                alt="Profile Picture"
                sx={{
                  width: 80,
                  height: 80,
                  bgcolor: 'primary.main',
                }}
              >
                <PersonIcon sx={{ fontSize: 40 }} />
              </Avatar>
            </Tooltip>

            {/* User Info */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1, // Takes up remaining space
                overflow: 'hidden', // Prevent overflow when content exceeds box
              }}
            >
              {/* Email */}
              {myUser?.email && (
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: 'text.primary',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', // Prevent wrapping to next line
                  }}
                >
                  {myUser.email}
                </Typography>
              )}

              {/* Nickname Editing */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1, // Margin for spacing between email and nickname
                }}
              >
                {isEditingNickname ? (
                  <TextField
                    variant="standard"
                    defaultValue={myUser?.nickName || ''}
                    label="Nickname"
                    onChange={e => setNickName(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            disabled={updateUserLoading}
                            onClick={async () => {
                              await updateUser();
                              setIsEditingNickname(false);
                            }}
                          >
                            {updateUserLoading ? <Loader /> : <CheckIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      color: 'text.secondary', // Use secondary color for less important text
                      maxWidth: '180px', // Control the size of the input field
                    }}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ mr: 1, color: 'text.primary' }}
                  >
                    {myUser?.nickName ? (
                      `@${myUser.nickName}`
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setIsEditingNickname(true)}
                        sx={{
                          padding: 0, // Reduce button padding to align with native styling
                          minWidth: 0, // Remove button min width
                          textTransform: 'none', // Remove uppercase transformation
                          fontWeight: 500, // Native look with slight bold
                        }}
                      >
                        Add Nickname
                      </Button>
                    )}
                  </Typography>
                )}

                {myUser?.nickName && !isEditingNickname && (
                  <Typography
                    onClick={() => setIsEditingNickname(true)}
                    sx={{
                      fontSize: '0.875rem', // Slightly smaller font size for the edit link
                      color: 'primary.main',
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Edit
                  </Typography>
                )}
              </Box>

              {/* Followers and Following */}
              <Stack
                direction="row"
                spacing={2} // Add space between followers and following
                mt={1} // Margin top for spacing
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: 'text.primary' }}
                >
                  Followers: {myUser?.followers?.length || 0}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: 'text.primary' }}
                >
                  Following: {myUser?.following?.length || 0}
                </Typography>
              </Stack>
            </Box>
          </Stack>
          <Divider sx={{ marginBottom: 2 }} />

          {/* Details Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: 'text.secondary',
                fontSize: '1.25rem', // Slightly larger font size for the header
              }}
            >
              Important info on hand
            </Typography>
            {/* Passport Expiry Date */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Passport expiry:
              </Typography>
              <Typography
                variant="body2"
                sx={{ mr: 1, color: 'text.secondary' }}
              >
                {myUser?.pasportExpiry ? (
                  returnFormatedDate(myUser.pasportExpiry, 0)
                ) : (
                  <Button
                    color="primary"
                    variant="outlined" // Use outlined variant for contrast
                    onClick={() => {
                      handleOpen();
                      setModalContent('passportExpiry');
                    }}
                    sx={{
                      textTransform: 'none', // Prevent uppercase transformation
                      fontWeight: 600, // Make button text slightly bolder
                      padding: '4px 8px', // Adjust padding for button
                    }}
                  >
                    Add
                  </Button>
                )}
              </Typography>
              {myUser?.pasportExpiry && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => {
                    handleOpen();
                    setModalContent('passportExpiry');
                  }}
                >
                  Edit
                </Box>
              )}
            </Box>
            <ComingSoonBox
              heading="Coming soon:"
              body="Passport expiry reminder 6, 3, and 1 month before expiry!"
            />
            {/* Insurance Expiry Date */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Insurance expiry:
              </Typography>
              <Typography
                variant="body2"
                sx={{ mr: 1, color: 'text.secondary' }}
              >
                {myUser?.insurance?.expiry ? (
                  <Box
                    onClick={() => {
                      handleOpen();
                      setModalContent('insuranceExpiry');
                    }}
                    sx={{ cursor: 'pointer' }} // Make the text clickable
                  >
                    {returnFormatedDate(myUser.insurance.expiry, 0)}
                  </Box>
                ) : (
                  <Button
                    color="primary"
                    variant="outlined" // Use outlined variant for contrast
                    onClick={() => {
                      handleOpen();
                      setModalContent('insuranceExpiry');
                    }}
                    sx={{
                      textTransform: 'none',
                      fontWeight: 600,
                      padding: '4px 8px',
                    }}
                  >
                    Add
                  </Button>
                )}
              </Typography>
              {myUser && myUser.insurance && myUser.insurance.expiry && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => {
                    handleOpen();
                    setModalContent('insuranceExpiry');
                  }}
                >
                  Edit
                </Box>
              )}
            </Box>
            <ComingSoonBox
              heading="Coming soon:"
              body="Insurance expiry reminder 30, 15, and 1 day before expiry!"
            />
            {/* Insurance Contact No */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Insurance contact No:
              </Typography>
              {isEditingInsuranceContact ? (
                <TextField
                  variant="standard"
                  defaultValue={myUser?.insurance?.contactNumber || ''}
                  label="Contact No."
                  onChange={e => setInsuranceContactNo(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          disabled={updateInsuranceLoading}
                          onClick={async () => {
                            await updateInsurance();
                            setIsEditingInsuranceContact(false);
                          }}
                        >
                          {updateInsuranceLoading ? <Loader /> : <CheckIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: 'text.secondary' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.insurance?.contactNumber || (
                    <Button
                      color="primary"
                      variant="outlined" // Use outlined variant for contrast
                      onClick={() => setIsEditingInsuranceContact(true)}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        padding: '4px 8px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
              )}
              {myUser?.insurance?.contactNumber &&
                !isEditingInsuranceContact && (
                  <Box
                    sx={theme => ({
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      cursor: 'pointer',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    })}
                    onClick={() => setIsEditingInsuranceContact(true)}
                  >
                    Edit
                  </Box>
                )}
            </Box>
            {/* Insurance Policy Number */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Insurance policy No:
              </Typography>
              {isEditingPolicyNumber ? (
                <TextField
                  variant="standard"
                  defaultValue={myUser?.insurance?.policyNumber || ''}
                  label="Policy No."
                  onChange={e => setInsurancePolicyNo(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          disabled={updateInsuranceLoading}
                          onClick={async () => {
                            await updateInsurance();
                            setIsEditingPolicyNumber(false);
                          }}
                        >
                          {updateInsuranceLoading ? <Loader /> : <CheckIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: 'text.secondary' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.insurance?.policyNumber || (
                    <Button
                      color="primary"
                      variant="outlined" // Use outlined variant for contrast
                      onClick={() => setIsEditingPolicyNumber(true)}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        padding: '4px 8px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
              )}
              {myUser?.insurance?.policyNumber && !isEditingPolicyNumber && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => setIsEditingPolicyNumber(true)}
                >
                  Edit
                </Box>
              )}
            </Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                fontWeight: 600,
                color: 'text.secondary',
                fontSize: '1.25rem', // Slightly larger font size for the header
              }}
            >
              Social info
            </Typography>
            {/* Insurance Contact No */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                Instagram profile:
              </Typography>
              {isEditingInstagram ? (
                <TextField
                  variant="standard"
                  defaultValue={myUser?.instagramUrl || ''}
                  label="Instagram profile URL"
                  onChange={e => setInstagramUrl(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          disabled={updateInsuranceLoading}
                          onClick={async () => {
                            await updateUser();
                            setIsEditingInstagram(false);
                          }}
                        >
                          {updateUserLoading ? <Loader /> : <CheckIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: 'text.secondary' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.instagramUrl || (
                    <Button
                      color="primary"
                      variant="outlined" // Use outlined variant for contrast
                      onClick={() => setIsEditingInstagram(true)}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        padding: '4px 8px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
              )}
              {myUser?.instagramUrl && !isEditingInstagram && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => setIsEditingInstagram(true)}
                >
                  Edit
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                YouTube profile:
              </Typography>
              {isEditingYouTube ? (
                <TextField
                  variant="standard"
                  defaultValue={myUser?.youTubeUrl || ''}
                  label="YouTube profile URL"
                  onChange={e => setYouTubeUrl(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          disabled={updateInsuranceLoading}
                          onClick={async () => {
                            await updateUser();
                            setIsEditingYouTube(false);
                          }}
                        >
                          {updateUserLoading ? <Loader /> : <CheckIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: 'text.secondary' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.youTubeUrl || (
                    <Button
                      color="primary"
                      variant="outlined" // Use outlined variant for contrast
                      onClick={() => setIsEditingYouTube(true)}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        padding: '4px 8px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
              )}
              {myUser?.youTubeUrl && !isEditingYouTube && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => setIsEditingYouTube(true)}
                >
                  Edit
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography
                variant="body2"
                sx={{ mr: 1, fontWeight: 600, color: 'text.primary' }}
              >
                TikTok profile:
              </Typography>
              {isEditingTikTok ? (
                <TextField
                  variant="standard"
                  defaultValue={myUser?.tikTokUrl || ''}
                  label="TikTok profile URL"
                  onChange={e => setTikTokUrl(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          disabled={updateInsuranceLoading}
                          onClick={async () => {
                            await updateUser();
                            setIsEditingTikTok(false);
                          }}
                        >
                          {updateUserLoading ? <Loader /> : <CheckIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ color: 'text.secondary' }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ mr: 1, color: 'text.secondary' }}
                >
                  {myUser?.tikTokUrl || (
                    <Button
                      color="primary"
                      variant="outlined" // Use outlined variant for contrast
                      onClick={() => setIsEditingTikTok(true)}
                      sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        padding: '4px 8px',
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Typography>
              )}
              {myUser?.tikTokUrl && !isEditingTikTok && (
                <Box
                  sx={theme => ({
                    fontSize: 12,
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  })}
                  onClick={() => setIsEditingTikTok(true)}
                >
                  Edit
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <LogoutButton />
        </Box>
      </Box>
      <CenteredModal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <MediumModalPaper>
          {modalContent === 'passportExpiry' && (
            <ModalTitle>When your pasport expires?</ModalTitle>
          )}
          {modalContent === 'insuranceExpiry' && (
            <ModalTitle>When your insurance expires?</ModalTitle>
          )}

          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {modalContent === 'passportExpiry' && (
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pasportExpiry}
                  onChange={(newValue: Date | null) => {
                    setPasportExpiry(newValue);
                  }}
                />
              )}
              {modalContent === 'insuranceExpiry' && (
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  openTo="day"
                  value={pasportExpiry}
                  onChange={(newValue: Date | null) => {
                    setInsuranceExpiry(newValue);
                  }}
                />
              )}
            </LocalizationProvider>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <PrimaryButton
                disabled={updateUserLoading}
                onClick={async () => {
                  setNickName('');
                  try {
                    if (modalContent === 'passportExpiry') {
                      await updateUser();
                    }
                    if (modalContent === 'insuranceExpiry') {
                      await updateInsurance();
                    }
                    handleClose();
                    setModalContent(null);
                  } catch (e) {
                    //TODO handle error
                  }
                }}
              >
                {updateUserLoading ? <Loader /> : 'Save expiry date'}
              </PrimaryButton>
            </Box>
          </Box>
        </MediumModalPaper>
      </CenteredModal>
    </>
  );
};

interface ComingSoonBoxProps {
  heading: string;
  body: string;
}

const ComingSoonBox: React.FC<ComingSoonBoxProps> = ({ heading, body }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 165, 0, 0.1)', // Very light orange background
        borderRadius: '8px',
        padding: '16px',
        textAlign: 'left',
        width: 'fit-content',
        // maxWidth: '400px', // Adjust the width as needed
        // margin: 'auto', // Center horizontally
        // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Slight shadow for depth
      }}
    >
      <Stack direction="row">
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 600,
            marginRight: 0.5,
          }}
        >
          {heading}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {body}
        </Typography>
      </Stack>
    </Box>
  );
};
