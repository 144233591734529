import { Box, useMediaQuery, useTheme } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // Toggle styles
    [{ color: [] }, { background: [] }], // Text color and background color
    [{ script: 'sub' }, { script: 'super' }], // Subscript/Superscript
    [{ list: 'ordered' }, { list: 'bullet' }], // Ordered/Unordered lists
    [{ indent: '-1' }, { indent: '+1' }], // Indent/Outdent
    [{ align: [] }], // Text alignments
    ['blockquote', 'code-block'], // Blockquote and code block
    ['link'], // Insert link
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'background',
  'align',
  'direction',
  'code-block',
  'script',
];

// The reusable Quill Editor component
const MyEditor = ({
  value,
  onChange,
  placeholder = 'Start typing here...',
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={theme => ({
        height: '200px',
        [theme.breakpoints.down('sm')]: { height: '170px' }, // Adjust height for smaller screens
      })}
    >
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        style={{
          height: isSmallScreen ? '65%' : '80%',
        }}
      />
    </Box>
  );
};

export default MyEditor;
