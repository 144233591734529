import { useMutation } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Box,
  Typography,
  Divider,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  styled,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AuthorizedApolloProvider from '../../AuthorizedApolloProvider';
import { Trip } from '../../generated/public_graphql';
import { publicTripsSelector } from '../../store/PublicTripSlice';
import { tripsSelector } from '../../store/TripSlice';
import { PrimaryButton, SecondaryButton } from '../../theme-components/Buttons';
import { Loader, LoadingDots } from '../../theme-components/Loader';
import { TitleH3 } from '../../theme-components/Typography';
import { useAuth } from '../auth/firebase';
import SignUpModal from '../auth/SignUpModal';
import { MUTATION_COPY_DAY_TO_ITINERARY } from '../gql-user/copyDayMutation';
import { MUTATION_COPY_STEP_TO_DAY } from '../gql-user/copyStepMutation';
import { MUTATION_CREATE_USER_TRIP_WITH_DAY } from '../gql-user/createUserTripMutation';
import SuccessIllustration from '../illustrations/Success';
import { CenteredModal, MediumModalPaper } from '../styling/modal';

interface AddToTripButtonProps {
  stepId: string;
  dayId: string;
}

interface AnchorPosition {
  top: number;
  left: number;
}

export const AddToTripButton: React.FC<AddToTripButtonProps> = ({
  stepId,
  dayId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuthenticated } = useAuth();
  const { publicTrip } = useSelector(publicTripsSelector);
  const { userTrips } = useSelector(tripsSelector);
  const [isModalOpen, setModalOpen] = useState(false);
  const [placeName, setPlaceName] = useState('');
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);
  const [added, setAdded] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [fixedAnchorPosition, setFixedAnchorPosition] =
    useState<AnchorPosition | null>(null);
  const [isSignUpModalOpen, setSignUpModalOpen] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const handleOpenSignUp = () => {
    setSignUpModalOpen(true);
  };

  const handleCloseSignUp = () => {
    setSignUpModalOpen(false);
  };

  const [addTrip, { data }] = useMutation(MUTATION_CREATE_USER_TRIP_WITH_DAY);

  const handleAddTrip = async () => {
    try {
      await addTrip({
        variables: {
          name: placeName,
          step_id: stepId,
        },
      });

      setAdded(true);
      setOpen(true);
    } catch (e) {
      // TODO
    }
  };

  const handleClick = event => {
    document.body.style.top = `-${scrollY}px`;

    setAnchorEl(event.currentTarget);
    const rect = event.currentTarget.getBoundingClientRect();
    setFixedAnchorPosition({ top: rect.top, left: rect.left });
    setPlaceName(
      publicTrip &&
        publicTrip.itineraryCountries &&
        publicTrip.itineraryCountries.length > 0 &&
        publicTrip.itineraryCountries[0] &&
        publicTrip.itineraryCountries[0].countryName
        ? `Trip to ${publicTrip.itineraryCountries[0].countryName}`
        : 'Placeholder name (change me)',
    );
  };

  const disableScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable scroll
    document.body.style.position = 'fixed'; // Prevent page jump
    document.body.style.top = `-${scrollY}px`; // Move body to the saved scroll position
  };

  const enableScroll = () => {
    document.body.style.overflow = ''; // Restore scroll
    document.body.style.position = ''; // Reset positioning
    window.scrollTo(0, scrollY); // Restore previous scroll position
  };

  useEffect(() => {
    if (isModalDisplayed) {
      disableScroll(); // Lock scrolling when modal is displayed
    }

    return () => {
      enableScroll(); // Clean up scrolling state when modal is closed
    };
  }, [isModalDisplayed]);

  const handleClose = () => {
    setIsModalDisplayed(false);
    setAnchorEl(null);
    enableScroll();
  };

  const handleOpenModal = (trip: Trip) => {
    disableScroll();
    setSelectedTrip(trip);
    setModalOpen(true);
    setIsModalDisplayed(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setIsModalDisplayed(false);
    setAnchorEl(null);
    setTimeout(() => {
      enableScroll();
    }, 200);
  };

  const openMenu = Boolean(anchorEl);
  useEffect(() => {
    if (openMenu && !isModalDisplayed) {
      const handleScroll = e => {
        handleClose(); // Close modal on scroll
      };

      const handleTouchMove = e => {
        handleClose(); // Close modal on touchmove
      };

      // Listen to scroll and touchmove events
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('touchmove', handleTouchMove);

      // Cleanup the event listeners when the menu is closed
      return () => {
        console.log('Cleaning up scroll and touchmove listeners');
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [openMenu, isModalDisplayed]);

  const handleMenuItemClick = (eventName: string, trip: Trip) => {
    gtag('event', eventName);
    handleOpenModal(trip);
  };
  // Step 1: Check if publicTrip.itineraryCountries exists and is not null or undefined
  const matchingUserTrips: Trip[] = [];
  let nonMatchingUserTrips: Trip[] = [];
  if (
    publicTrip &&
    publicTrip.itineraryCountries &&
    publicTrip.itineraryCountries.length > 0
  ) {
    // Step 2: Create a Set of country names from publicTrip for easier lookup
    const publicTripCountryNames = new Set(
      publicTrip.itineraryCountries.map(country => country!.countryName),
    );

    // Step 3: Initialize arrays to store matching and non-matching user trips
    if (userTrips && userTrips.length > 0) {
      userTrips.forEach(userTrip => {
        // Create a Set of the user's itinerary country names for easy comparison
        if (
          userTrip &&
          userTrip.itineraryCountries &&
          userTrip.itineraryCountries.length > 0
        ) {
          const userTripCountryNames = new Set(
            userTrip.itineraryCountries.map(country => country!.countryName),
          );
          const hasMatchingCountry = Array.from(userTripCountryNames).some(
            countryName => publicTripCountryNames.has(countryName),
          );

          if (hasMatchingCountry) {
            // If there's a match, add to matchingUserTrips
            matchingUserTrips.push(userTrip);
          } else {
            // If no match, add to nonMatchingUserTrips
            nonMatchingUserTrips.push(userTrip);
          }
        } else {
          // If user trip is new without any counry also add it to no matching
          nonMatchingUserTrips.push(userTrip);
        }
        // Check for at least one common country in both sets
      });
    }
    // Step 4: Iterate over each user trip to determine if it matches the criteria
  } else if (userTrips !== null) {
    nonMatchingUserTrips = userTrips;
  }

  return (
    <>
      <Box
        sx={theme => ({
          flexShrink: 0, // Prevent the button from shrinking
        })}
      >
        <Button
          variant="contained"
          startIcon={<BookmarkBorderIcon />}
          endIcon={<ArrowDropDownIcon />}
          onClick={e => {
            const scrollPosition = window.scrollY;
            setScrollY(scrollPosition);
            handleClick(e);
          }}
          sx={theme => ({
            fontSize: 12,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            paddingLeft: 1.75,
            paddingRight: 1.75,
            backgroundColor: '#36454F',
            borderRadius: 20,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            boxShadow: 'none', // Disable shadow
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: '#4A5D6F',
            },
          })}
        >
          Add to trip
        </Button>

        {isAuthenticated ? (
          <>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              disableScrollLock={true}
              anchorReference="anchorPosition"
              anchorPosition={
                fixedAnchorPosition
                  ? {
                      top: fixedAnchorPosition.top,
                      left: fixedAnchorPosition.left,
                    }
                  : undefined
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {matchingUserTrips && matchingUserTrips.length > 0 && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ padding: '8px 16px', fontWeight: 'medium' }}
                  >
                    Related trips
                  </Typography>
                  {/* <Divider sx={{ marginLeft: 1.5, marginRight: 1.5 }} /> */}
                  {matchingUserTrips.map((trip, i) => (
                    <MenuItem
                      key={i}
                      // onClick={() => setItineraryId(country!.itineraryId!)}
                      onClick={() => {
                        if (!isModalDisplayed) {
                          const scrollPosition = window.scrollY;
                          setScrollY(scrollPosition);
                          setIsModalDisplayed(true);
                          console.log('isModalDisplayed 1', isModalDisplayed);
                        }
                        handleMenuItemClick('trip-add-to-related-trip', trip);
                      }}
                    >
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      Add to {trip.name}
                    </MenuItem>
                  ))}
                </Box>
              )}
              {nonMatchingUserTrips && nonMatchingUserTrips.length > 0 && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ padding: '8px 16px', fontWeight: 'medium' }}
                  >
                    Other trips
                  </Typography>
                  {/* <Divider sx={{ marginLeft: 1.5, marginRight: 1.5 }} /> */}
                  {nonMatchingUserTrips.map((trip, i) => (
                    <MenuItem
                      key={i}
                      // onClick={() => setItineraryId(country!.itineraryId!)}
                      onClick={() => {
                        if (!isModalDisplayed) {
                          const scrollPosition = window.scrollY;
                          setScrollY(scrollPosition);
                          setIsModalDisplayed(true);
                        }
                        handleMenuItemClick('trip-add-to-another-trip', trip);
                      }}
                    >
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      Add to {trip.name}
                    </MenuItem>
                  ))}
                </Box>
              )}
              {(matchingUserTrips.length > 0 ||
                nonMatchingUserTrips.length > 0) && (
                <Divider
                  sx={{
                    marginLeft: 1.5,
                    marginRight: 1.5,
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                />
              )}
              {/* <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AddCircleOutlineIcon />
                </ListItemIcon>
                Add whole day
              </MenuItem> */}
              {/* Repeat for other dates or items */}
              <MenuItem
                onClick={() => {
                  if (!isModalDisplayed) {
                    const scrollPosition = window.scrollY;
                    setScrollY(scrollPosition);
                    setIsModalDisplayed(true);
                  }
                  gtag('event', 'trip-page-login-clicked');
                  // TODO create trip and show user it has been added
                  handleAddTrip();
                }}
              >
                <ListItemIcon>
                  <AddCircleOutlineIcon />
                </ListItemIcon>
                Add to new trip
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            disableScrollLock={true}
            anchorReference="anchorPosition"
            anchorPosition={
              fixedAnchorPosition
                ? {
                    top: fixedAnchorPosition.top,
                    left: fixedAnchorPosition.left,
                  }
                : undefined
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-add-to-trip-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <AddCircleOutlineIcon />
              </ListItemIcon>
              Create new trip
            </MenuItem>
            <MenuItem
              onClick={() => {
                gtag('event', 'trip-page-login-clicked');
                if (!isModalDisplayed) {
                  const scrollPosition = window.scrollY;
                  setScrollY(scrollPosition);
                  setIsModalDisplayed(true);
                }
                handleOpenSignUp();
              }}
            >
              <ListItemIcon>
                <LockOpenIcon />
              </ListItemIcon>
              Login
            </MenuItem>
          </Menu>
        )}
        {selectedTrip && (
          <AuthorizedApolloProvider>
            <TripModal
              open={isModalOpen}
              onClose={handleCloseModal}
              trip={selectedTrip} // pass the selected trip data
              dayId={dayId}
              stepId={stepId}
            />
          </AuthorizedApolloProvider>
        )}
      </Box>
      {added && (
        <CenteredModal
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          open={open}
          onClose={handleCloseModal}
        >
          <MediumModalPaper>
            <SuccessModalContent
              trip={data.createUserTripWithDay}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </MediumModalPaper>
        </CenteredModal>
      )}
      <SignUpModal open={isSignUpModalOpen} onClose={handleCloseSignUp} />
    </>
  );
};

interface TripModalProps {
  open: boolean;
  onClose: () => void;
  trip: Trip;
  dayId: string;
  stepId: string;
}

const TripModal: React.FC<TripModalProps> = ({
  open,
  onClose,
  trip,
  dayId,
  stepId,
}) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('addStep');
  const [selectedDayId, setSelectedDayId] = useState('');
  const [selectedDayValue, setSelectedDayValue] = useState('');
  const [newPosition, setNewPosition] = useState(0);
  const [copyStepToItinerary, { loading: loadingStep }] = useMutation(
    MUTATION_COPY_STEP_TO_DAY,
  );
  const [copyDayToItinerary, { loading: loadingDay }] = useMutation(
    MUTATION_COPY_DAY_TO_ITINERARY,
  );
  const [added, setAdded] = useState(false);
  const [addingError, setAddingError] = useState(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
    setSelectedDayId(''); // Update the state with the parsed dayId
    setNewPosition(0);
    setSelectedDayValue('');
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDayValue(event.target.value);
    const [dayId, position] = event.target.value.split('|'); // Split the value by the delimiter
    setSelectedDayId(dayId); // Update the state with the parsed dayId
    setNewPosition(parseInt(position, 10));
  };

  const handleCopyStep = async () => {
    try {
      await copyStepToItinerary({
        variables: {
          original_step_id: stepId, // Replace with actual data
          new_day_id: selectedDayId, // Ensure this is set correctly
          new_step_number: newPosition, // Replace with actual step number
        },
      });

      setAdded(true);
      setAddingError(false);
      setSelectedDayId(''); // Update the state with the parsed dayId
      setNewPosition(0);
      setSelectedDayValue('');
      setSelectedOption('addStep');
    } catch (e) {
      setAdded(false);
      setAddingError(true);
    }
  };

  const handleCopyDay = async () => {
    try {
      await copyDayToItinerary({
        variables: {
          original_day_id: dayId,
          new_itinerary_id: trip.itinerary!.id,
          new_day_number: newPosition,
        },
      });

      setAdded(true);
      setAddingError(false);
      setSelectedDayId(''); // Update the state with the parsed dayId
      setNewPosition(0);
      setSelectedDayValue('');
      setSelectedOption('addStep');
    } catch (e) {
      setAdded(false);
      setAddingError(true);
    }
  };

  const handleCloseModal = () => {
    document.body.style.overflow = 'unset';
    setAdded(false);
    setAddingError(false);
    setSelectedDayId(''); // Update the state with the parsed dayId
    setNewPosition(0);
    setSelectedDayValue('');
    setSelectedOption('addStep');
    onClose();
  };

  return (
    <CenteredModal
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      open={open}
      onClose={handleCloseModal}
    >
      <MediumModalPaper>
        {added ? (
          <>
            <SuccessModalContent
              trip={trip}
              handleCloseModal={handleCloseModal}
              navigate={navigate}
            />
          </>
        ) : (
          <>
            {dayId !== '' && (
              <>
                <TitleH3>Add whole day or place/activity?</TitleH3>
                <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                  <RadioGroup
                    row
                    aria-label="add-option"
                    name="add-option"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <StyledFormControlLabel
                      value="addStep"
                      control={
                        <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                      }
                      label="Add this place"
                    />
                    <StyledFormControlLabel
                      value="addDay"
                      control={
                        <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                      }
                      label="Add whole day"
                    />
                  </RadioGroup>
                </FormControl>
                <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
              </>
            )}

            <Stack direction="column">
              {selectedOption === 'addDay' && (
                <TitleH3>Add below the day:</TitleH3>
              )}
              {selectedOption === 'addStep' && <TitleH3>Add to day:</TitleH3>}

              <FormControl component="fieldset" sx={{ marginTop: 1 }}>
                <RadioGroup
                  aria-label="add-option"
                  name="add-option"
                  value={selectedDayValue}
                  onChange={handleDayChange}
                >
                  {trip &&
                    trip.itinerary &&
                    trip.itinerary.days &&
                    trip.itinerary.days.length > 0 &&
                    [...trip.itinerary.days]
                      .sort((a, b) => a!.dayNumber - b!.dayNumber) // Order by dayNumber
                      .map((day, index) => (
                        <Stack
                          direction="row"
                          key={index}
                          flexWrap="wrap"
                          sx={{
                            paddingTop: 0,
                            paddingBottom: 1,
                          }}
                        >
                          <StyledFormControlLabel
                            key={index}
                            sx={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              alignItems: 'flex-start',
                            }}
                            value={`${day!.id}|${selectedOption === 'addDay' ? day!.dayNumber + 1 : 0}`}
                            control={
                              <Radio sx={{ paddingTop: 0, paddingBottom: 0 }} />
                            }
                            label={
                              selectedOption === 'addStep' ? (
                                <Stack
                                  direction="row"
                                  key={index}
                                  flexWrap="wrap"
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 700,
                                      marginRight: 1,
                                      color: '#2F4F4F',
                                    }}
                                  >
                                    Day {day!.dayNumber}
                                  </Box>

                                  {day &&
                                    day.cities &&
                                    day.cities.length > 0 &&
                                    day.cities.map((city, index) => (
                                      <Box
                                        key={index}
                                        sx={{ color: '#555555' }}
                                      >
                                        {city!.cityName}
                                        {index < day.cities!.length - 1 && (
                                          <Box
                                            component="span"
                                            sx={{ marginRight: 0.5 }}
                                          >
                                            ,
                                          </Box>
                                        )}
                                      </Box>
                                    ))}
                                </Stack>
                              ) : (
                                <Stack
                                  direction="row"
                                  key={index}
                                  flexWrap="wrap"
                                  alignItems="center"
                                >
                                  <Box
                                    sx={{
                                      fontWeight: 700,
                                      marginRight: 1,
                                      color: '#2F4F4F',
                                    }}
                                  >
                                    Day {day!.dayNumber}
                                  </Box>

                                  {day &&
                                    day.cities &&
                                    day.cities.length > 0 &&
                                    day.cities.map((city, index) => (
                                      <Box
                                        key={index}
                                        sx={{ color: '#555555' }}
                                      >
                                        {city!.cityName}
                                        {index < day.cities!.length - 1 && (
                                          <Box
                                            component="span"
                                            sx={{ marginRight: 0.5 }}
                                          >
                                            ,
                                          </Box>
                                        )}
                                      </Box>
                                    ))}
                                </Stack>
                              )
                            }
                          />
                        </Stack>
                      ))}
                </RadioGroup>
              </FormControl>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>

              <PrimaryButton
                disabled={
                  loadingStep ||
                  loadingDay ||
                  selectedDayId === '' ||
                  (selectedOption !== 'addDay' && selectedOption !== 'addStep')
                }
                onClick={() => {
                  if (selectedDayId !== '' && selectedOption === 'addDay') {
                    handleCopyDay();
                  }
                  if (selectedDayId !== '' && selectedOption === 'addStep') {
                    handleCopyStep();
                  }
                }}
              >
                {loadingStep || loadingDay ? <Loader /> : 'Add'}
              </PrimaryButton>
            </Stack>
          </>
        )}
      </MediumModalPaper>
    </CenteredModal>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start', // Align radio button to the top
  marginBottom: 1,
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  '& .MuiRadio-root': {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the content horizontally
  alignItems: 'center', // Center the content vertically
  width: '100%',
  //   marginTop: theme.spacing(-3),
  //   marginBottom: theme.spacing(-3),
  //   [theme.breakpoints.down('md')]: {
  //     marginTop: theme.spacing(-3),
  //     marginBottom: theme.spacing(-3),
  //   },
}));

const SuccessModalContent: React.FC<{
  trip: any;
  handleCloseModal: () => void;
  navigate: (path: string) => void;
}> = ({ trip, handleCloseModal, navigate }) => {
  const url = window.location.pathname;
  const tripId = url.split('/').pop();

  return (
    <>
      <IllustrationContainer>
        <SuccessIllustration style={{ maxWidth: '200px', height: 'auto' }} />
      </IllustrationContainer>
      <Stack spacing={3} alignItems="center" sx={{ p: 3, textAlign: 'center' }}>
        <TitleH3>Well done!</TitleH3>
        <Typography variant="h6" color="textSecondary">
          You have successfully added to your trip <strong>{trip.name}</strong>
        </Typography>

        {/* Success Message and Buttons */}
        <Stack
          // direction={isSm ? 'column' : 'row'}
          direction={'column'}
          spacing={2}
          justifyContent="center"
          width="100%"
        >
          <SecondaryButton
            onClick={() => {
              handleCloseModal();
              navigate('/create-route/' + trip.id);
            }}
            color="primary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Open my trip
          </SecondaryButton>
          <SecondaryButton
            onClick={handleCloseModal}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: 16,
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
              },
            }}
          >
            Continue exploring
          </SecondaryButton>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
              },
            }}
          >
            <PrimaryButton
              onClick={() => {
                handleCloseModal();
                navigate(
                  '/create-route/' + trip.id + '?selected-trip=' + tripId,
                );
              }}
              color="secondary"
              variant="contained"
              sx={{
                fontSize: 16,
                boxShadow: 2,
                '&:hover': {
                  boxShadow: 4,
                },
              }}
            >
              Open drag and drop
            </PrimaryButton>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
