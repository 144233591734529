import CardTravelTwoToneIcon from '@mui/icons-material/CardTravelTwoTone';
import HealingTwoToneIcon from '@mui/icons-material/HealingTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import TipsAndUpdatesTwoToneIcon from '@mui/icons-material/TipsAndUpdatesTwoTone';
import WbSunnyTwoToneIcon from '@mui/icons-material/WbSunnyTwoTone';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import { Country } from '../../generated/user_graphql';
import { LightButton } from '../../theme-components/Buttons';
import { TripSidebar } from '../trip-details/TripSidebar';

export function CountryInfoLinks(props: { country: Country }) {
  const navigate = useNavigate();

  function onShowSeasons() {
    gtag('event', 'public-country-seasons');
    navigate('?modal=seasons');
  }

  function onShowTips() {
    gtag('event', 'public-country-tips');
    navigate('?modal=tips');
  }

  function onShowVaccinations() {
    gtag('event', 'public-country-health');
    navigate('?modal=vaccinations');
  }

  function onShowCountriesDetails() {
    gtag('event', 'public-country-details');
    navigate('?modal=countries_details');
  }
  function onShowVisas() {
    gtag('event', 'public-country-visas');
    navigate('?modal=visas');
  }

  let showAboutCountry = false;
  let showCountryVaccination = false;
  let showCountryVisa = false;
  let showCountryTips = false;
  let showCountrySeasons = false;
  let country: Country | null = null;

  if (props.country) {
    country = props.country;

    if (country.name && country.description) {
      showAboutCountry = true;
    }

    if (country.seasons && country.seasons.length > 0) {
      showCountrySeasons = true;
    }

    if (country.recommendations && country.recommendations.length > 0) {
      country.recommendations.forEach(recommendation => {
        if (
          recommendation!.type === 'RECOMMENDATION_VACCINATION' ||
          recommendation!.type === 'RECOMMENDATION_HEALTH'
        ) {
          showCountryVaccination = true;
        }

        if (recommendation!.type === 'RECOMMENDATION_VISAS') {
          showCountryVisa = true;
        }

        if (
          recommendation!.type !== 'RECOMMENDATION_VISAS' &&
          recommendation!.type !== 'RECOMMENDATION_VACCINATION' &&
          recommendation!.type !== 'RECOMMENDATION_HEALTH'
        ) {
          showCountryTips = true;
        }
      });
    }
  }

  return (
    <>
      <Box
        sx={theme => ({
          // padding: 1,
          // // minWidth: 500,
          // display: 'flex',
          // justifyContent: 'center',
          // //justifyContent: 'space-between',
          // flexDirection: 'row',
          // flexWrap: 'wrap',
        })}
      >
        {showAboutCountry && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton onClick={onShowCountriesDetails}>
              <LanguageTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: '#4D4B66',
                  [theme.breakpoints.down('sm')]: {
                    width: 25,
                    height: 25,
                  },
                })}
              />
              {'About ' + country!.name}
            </LightButton>
          </Box>
        )}
        {showCountryTips && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton onClick={onShowTips}>
              <TipsAndUpdatesTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: '#4D4B66',
                  [theme.breakpoints.down('sm')]: {
                    width: 25,
                    height: 25,
                  },
                })}
              />
              Tips before you go
            </LightButton>
          </Box>
        )}
        {showCountryVisa && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton onClick={onShowVisas}>
              <CardTravelTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: '#4D4B66',
                  [theme.breakpoints.down('sm')]: {
                    width: 25,
                    height: 25,
                  },
                })}
              />
              Visas
            </LightButton>
          </Box>
        )}
        {showCountryVaccination && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton onClick={onShowVaccinations}>
              <HealingTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: '#4D4B66',
                  [theme.breakpoints.down('sm')]: {
                    width: 25,
                    height: 25,
                  },
                })}
              />
              Health
            </LightButton>
          </Box>
        )}
        {showCountrySeasons && (
          <Box
            sx={theme => ({
              marginRight: theme.spacing(1),
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
            })}
          >
            <LightButton onClick={onShowSeasons}>
              <WbSunnyTwoToneIcon
                sx={theme => ({
                  width: 30,
                  height: 30,
                  marginRight: 1,
                  color: '#4D4B66',
                  [theme.breakpoints.down('sm')]: {
                    width: 25,
                    height: 25,
                  },
                })}
              />
              When to go
            </LightButton>
          </Box>
        )}
      </Box>
      {country && <TripSidebar countries={[country]} />}
    </>
  );
}
