import { useLazyQuery, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { User } from '../../generated/user_graphql';
import { setLoading, setDays } from '../../store/DaySlice';
import { errorsSelector } from '../../store/ErrorSlice';
import {
  tripsSelector,
  setTrip,
  setTripDescription,
  setTripName,
} from '../../store/TripSlice';
import { setUser } from '../../store/UserSlice';
import { FullScreenBusAnimation } from '../animations/BusAnimation';
import { Header } from '../AppWrapper';
import { useAuth } from '../auth/firebase';
import LoginButton from '../auth/LoginButton';
import ErrorModal from '../Error';
import { QUERY_GET_USER } from '../gql-user/user';
import { QUERY_USER_TRIP } from '../gql-user/userTrip';
import { VerticalFlex } from '../helpers/flex';
import { RoutePage, RouteTitle, RouteContent } from '../route';

import { DragAndDropUserItinerarySingleTrip } from './drag-and-drop/DragAndDropUserItinerarySingleTrip';
import { DragAndDropUserItineraryTwoTrips } from './drag-and-drop/DragAndDropUserItineraryTwoTrips';
import { TripsResults } from './TripsResults';

export function CreateUserTrip() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { userTripId } = useParams<{ userTripId: string }>();

  const params = new URLSearchParams(window.location.search);
  const selectedTripIdOnLoad: string | null = params.get('selected-trip');

  const { data, loading, error } = useQuery(QUERY_USER_TRIP, {
    variables: { trip_id: userTripId },
  });
  const { errorModalOpen } = useSelector(errorsSelector);
  const { trip } = useSelector(tripsSelector);

  const [getUser, { data: userData }] = useLazyQuery<{ getUser: User }>(
    QUERY_GET_USER,
  );

  useEffect(() => {
    if (isAuthenticated) {
      const fetchUserTrips = async () => {
        await getUser();
      };

      fetchUserTrips();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData && userData.getUser) {
      dispatch(setUser(userData.getUser));
    }
  }, [userData]); // dependencies for useEffect

  useEffect(() => {
    if (data && data.userTrip) {
      dispatch(setTrip(data.userTrip));
      dispatch(setTripName(data.userTrip.name));
      dispatch(setTripDescription(data.userTrip.description));
      dispatch(setDays(data.userTrip.itinerary.days));
    }
  }, [data, dispatch]); // dependencies for useEffect

  if (loading) {
    return <FullScreenBusAnimation copy="Almost there!" />;
  }

  if (!isAuthenticated) {
    return <LoginButton />;
  }

  if (!trip && !loading) {
    return (
      <RoutePage>
        <RouteTitle>Trip</RouteTitle>
        <RouteContent>Trip not found</RouteContent>
      </RoutePage>
    );
  }

  return (
    <Box width="100%">
      {errorModalOpen && <ErrorModal />}
      <VerticalFlex>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt={1}
        >
          <Box flexGrow={1}>
            <Header>
              {trip && (!trip.tripType || trip.tripType !== 'guide') ? (
                <>Create trip</>
              ) : (
                <>Create guide</>
              )}
            </Header>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            {trip && <TripsResults userTripId={trip.id} />}
          </Box>
        </Box>
        {selectedTripIdOnLoad ? (
          <DragAndDropUserItineraryTwoTrips />
        ) : (
          <DragAndDropUserItinerarySingleTrip />
        )}
      </VerticalFlex>
    </Box>
  );
}
