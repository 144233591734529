import { gql } from 'graphql-tag';

export const QUERY_GET_COUNTRIES = gql`
  query countriesList {
    countriesList {
      id
      name
    }
  }
`;
